import React, { FC, useState } from 'react'

import logo from 'assets/images/Audit_Logo.png'
import LoadingButton from 'components/LoadingButton'
import styles from './styles.module.scss'

import {
  ConsolidatedXmlReportQuery, useConsolidatedXmlReportLazyQuery
} from 'generated/graphql'
import useData from 'hooks/useData'
import notify from 'utils/notify'
import {
 // AuditStatuses,
 // Categories,
  Notification,
} from 'constants/typeConstants'

type DashboardHeaderProps = {
  labels: { id: string; name: string }[]
  activeLabels: (string | null)[]
}

const ExecDashboardHeader: FC<DashboardHeaderProps> = ({
  labels,
  activeLabels,
}) => {

  const [, setUriData] = useState<string | null | undefined>(null);
  const handleDownloadStatusReport = () => {
    // TODO: implement download status report
  }




  const [handleDownloadConsolidatedBalances ,{ data, previousData, loading }]=  useConsolidatedXmlReportLazyQuery({
    variables: { input: {  labels: activeLabels } },
    onCompleted() {
      setUriData( newData?.consolidatedXMLReport?.uri)
      const uri= newData?.consolidatedXMLReport?.uri
      console.log('uri-data', newData?.consolidatedXMLReport?.uri);
      if (uri) {
      fetch(uri)
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.download =  'report.xlsx';
            document.body.appendChild(link);

            link.click();

            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
          })
          .catch((error) => {
            console.error('Error fetching the file:', error);
          });
    }},
    onError() {
      notify(
          Notification.ERROR,
          'There is some error while loading count by audit type data'
      )
    },
  })
  const newData =  useData<ConsolidatedXmlReportQuery>({ loading, previousData, data })

  const getActiveLabels = () => {
    const activeLabelsNames = []
    for (let label of labels) {
      if (activeLabels.includes(label.id)) activeLabelsNames.push(label.name)
    }
    return activeLabelsNames.length
      ? activeLabelsNames.slice(0, activeLabelsNames.length - 1).join(', ') +
          (activeLabelsNames.length > 1 ? ' and ' : '') +
          activeLabelsNames.slice(-1)
      : ' all label'
  }

  return (
    <div className={styles.container}>
      <div>
        <img width="200px" src={logo} alt="Audit TraX" />
      </div>
      <div>
        <h1 className={styles.title}>Welcome to the Executive Dashboard!</h1>
        <p>
          <i>You are currently viewing {getActiveLabels()} data.</i>
        </p>
      </div>
      <div>
        <strong>Available Reports</strong>
        <div>
          <LoadingButton
            className={styles.button}
            onClick={handleDownloadStatusReport}
            prefix="fas"
            containerStyle={'outline'}
            colorType={'success'}
            icon="download"
          >
            Status Report
          </LoadingButton>
          <LoadingButton
            className={styles.button}
            onClick={() => handleDownloadConsolidatedBalances()}
            prefix="fas"
            containerStyle={'outline'}
            colorType={'success'}
            icon="download"
          >
            Consolidated Balances
          </LoadingButton>

        </div>
      </div>
    </div>
  )
}

export default ExecDashboardHeader
