import { ButtonFilterLabel } from 'components/ButtonFilter/ButtonFilter'

const makeButtonFilterLabels = (items: (null | undefined | { id?: string | null | undefined, name?: string | null | undefined })[]) =>
  items.reduce<ButtonFilterLabel[]>((acc, item) => {
    if (item) {
      acc.push({
        id: item.id ?? '',
        name: item.name ?? ''
      })
    }
    return acc
  }, [])

export default makeButtonFilterLabels
