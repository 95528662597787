import dayjs from 'dayjs'
//import { FilterPeriod } from 'generated/graphql'

type CalculateBalancePeriodResult = {
  fromDate: string
  toDate: string
}

const calculateBalancePeriod = (balanceSummaryPeriod: any): CalculateBalancePeriodResult => {
  const isQtr = balanceSummaryPeriod.fromMonth === 1 && balanceSummaryPeriod.toMonth === 3
  const year = isQtr ? balanceSummaryPeriod.year - 1 : balanceSummaryPeriod.year
  const fromMonth = (isQtr ? 10 : balanceSummaryPeriod.fromMonth) - 1
  const toMonth = isQtr ? 12 : balanceSummaryPeriod.toMonth

  const dateFormat = 'MMM DD, YYYY'
  const fromDate = dayjs(new Date(year, fromMonth)).format(dateFormat)
  const toDate = dayjs(new Date(year, toMonth, 0)).format(dateFormat)

  return { fromDate, toDate }
}

export default calculateBalancePeriod
