import { makeVar, useReactiveVar } from '@apollo/client'
import { useEffect } from 'react'

const loadingVar = makeVar(false)

const useGlobalProgress = (loading?: boolean) => {
  useEffect(() => {
    if (loading !== undefined) {
      loadingVar(loading)
    }

    return () => {
      loadingVar(false)
    }
  }, [loading])

  return useReactiveVar(loadingVar)
}

export default useGlobalProgress
