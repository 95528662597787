import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, ColumnsType } from '@wmgtech/legato'
import classNames from 'classnames'

import Table from 'components/Table'
import AddRevisionModal from './AddRevisionModal'
import useData from 'hooks/useData'
import notify from 'utils/notify'
import currencyFormat from 'utils/currencyFormat'
import convertDateToString from 'utils/convertDateToString'
import { Notification } from 'constants/typeConstants'
import {
  AuditClaimsTableQuery,
  ClaimsRevisionsDocument,
  useAuditClaimsTableQuery,
  useCreateAuditClaimsRevisionMutation,
} from 'generated/graphql'
import styles from './styles.module.scss'

interface IActivitiesProps {
  auditData: any
}

const CLAIM_TITLE_LENGTH = 27

const Claims: FC<IActivitiesProps> = ({ auditData }) => {
  const tableColumns: ColumnsType<any>[] = [
    {
      title: '',
      dataIndex: 'auditType',
      key: 'auditType',
      render: (value: string, row: any) => <small>{row.auditType?.name}</small>,
    },
    {
      title: '#',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (value: string, row: any) => (
        <span title={row.name}>{truncate(row.name, CLAIM_TITLE_LENGTH)}</span>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (value: string, row: any) => row.type?.name || 'N/A',
    },
    {
      title: 'Subcategory',
      dataIndex: 'subcategory',
      key: 'subcategory',
      render: (value: string, row: any) => row.subcategory?.name || 'N/A',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (value: string, row: any) => row.action?.name || 'N/A',
    },
    {
      title: 'Original Claim',
      dataIndex: 'originalClaimAmount',
      key: 'originalClaimAmount',
      render: (value: string, row: any) =>
        row.originalClaimAmount !== ''
          ? currencyFormat(row.originalClaimAmount)
          : '-',
    },
    {
      title: 'Current Claim',
      dataIndex: 'currentClaimAmount',
      key: 'currentClaimAmount',
      render: (value: string, row: any) =>
        row.currentClaimAmount !== ''
          ? currencyFormat(row.currentClaimAmount)
          : '-',
    },
    {
      title: 'Allocated',
      dataIndex: 'totalAllocation',
      key: 'totalAllocation',
      render: (value: string, row: any) =>
        row.totalAllocation !== '' ? currencyFormat(row.totalAllocation) : '-',
    },
    {
      title: 'Current Offer',
      dataIndex: 'offeredAmount',
      key: 'offeredAmount',
      render: (value: string, row: any) =>
        row.offeredAmount !== '' ? currencyFormat(row.offeredAmount) : '-',
    },
  ]

  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  })

  const history = useHistory()
  const [showModal, setShowModal] = useState(false)
  const [createAuditRevision] = useCreateAuditClaimsRevisionMutation()

  const truncate = (str: string, max: number) => {
    return str?.length > max ? str.substring(0, max) + '…' : str
  }
  const toggleModal = useCallback(() => {
    setShowModal((show) => !show)
  }, [])

  const createRevision = (data: any) => {
    const input = {
      ...data,
      revisionDate: convertDateToString(data.revisionDate),
      auditId: auditData.id,
    }
    createAuditRevision({
      variables: { input },
      refetchQueries: [
        {
          query: ClaimsRevisionsDocument,
          variables: { id: auditData.id },
        },
      ],
      onCompleted() {
        mounted.current && toggleModal()
        history.push(`/audits/${auditData.id}/revisions`)
        notify(Notification.SUCCESS, 'Your revision was created!')
      },
      onError() {
        notify(
          Notification.ERROR,
          'There is some error while creating revision'
        )
      },
    })
  }

  const { loading, previousData, data } = useAuditClaimsTableQuery({
    variables: { auditId: auditData.id },
    fetchPolicy: 'no-cache',
  })

  const claimsTable = useData<AuditClaimsTableQuery>({
    loading,
    previousData,
    data,
  })

  const calculateTotal = (arr: any, field: any) => {
    return arr
      ?.map((l: any) => l?.[field])
      .reduce((acc: number, cur: any) => {
        cur = cur ? cur : 0
        return acc + parseFloat(cur)
      }, 0)
  }

  const claimsData = claimsTable?.auditClaimsTable?.items || []

  claimsData.sort((a: any, b: any) => {
    return a.number.localeCompare(b.number, undefined, {
      numeric: true,
      sensitivity: 'base',
    })
  })

  const claimsList = [...(claimsData as any[])]
  claimsList.push({
    id: 'totals',
    action: { name: 'Totals:' },
    type: { name: ' ' },
    subcategory: { name: ' ' },
    currentClaimAmount: calculateTotal(claimsData, 'currentClaimAmount'),
    totalAllocation: calculateTotal(claimsData, 'totalAllocation'),
    offeredAmount: calculateTotal(claimsData, 'offeredAmount'),
    originalClaimAmount: calculateTotal(claimsData, 'originalClaimAmount'),
  })

  const goToAllRevisions = () => {
    history.push(`/audits/${auditData.id}/revisions`)
  }

  return (
    <div className={classNames(styles.container, 'no-pagination')}>
      <AddRevisionModal
        onSubmit={createRevision}
        onClose={toggleModal}
        show={showModal}
      />
      <header className={styles.header}>
        <div>
          <h4>Quick View of Claims</h4>
          <p>Below are all the claims added to this audit</p>
        </div>
        <div className="button-wrapper">
          <Button
            onClick={toggleModal}
            secondaryIcon="plus"
            label="Add Revision"
          />
          <Button
            label="All Revisions"
            colorType="success"
            onClick={goToAllRevisions}
          />
        </div>
      </header>
      <Table
        columns={tableColumns}
        data={claimsList ?? []}
        totals
        pagination={{
          pageSize: claimsList.length,
        }}
      />
    </div>
  )
}

export default Claims
