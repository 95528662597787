import { FC, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Input, InputGroup, Textarea } from '@wmgtech/legato'
import classNames from 'classnames'

import FormDatepicker from 'components/Form/FormDatepicker'
import FormFooter from 'components/Form/FormFooter/FormFooter'
import styles from './styles.module.scss'

interface IProps {
  onClose?: () => void
  onSubmit: (data: any) => void
  defaultValues: any
}

const OfferDetailsForm: FC<IProps> = ({ onClose, onSubmit, defaultValues }) => {
  const methods = useForm({
    defaultValues: defaultValues,
  })
  const { register, watch } = methods

  const [offerAmts, setOfferAmts] = useState(defaultValues)

  useEffect(() => {
    const subscription = watch((data) => {
      const { flatAmount, advancedAmount, otherAmount } = data
      setOfferAmts({ flatAmount, advancedAmount, otherAmount })
    })
    return () => subscription.unsubscribe()
  })

  const getTotalOfferAmt = () => {
    return (+offerAmts.flatAmount + +offerAmts.advancedAmount + +offerAmts.otherAmount).toFixed(2)
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
        <div className={styles.body}>
          <p>
            Enter the TOTAL Offer - you will have the ability to spread across
            the claims and labels in the next screen.
          </p>
          <div className={classNames('form-group', 'form-group-four')}>
            <InputGroup
              className={styles['input-group']}
              label="Total Flat Amount"
            >
              <Input {...register('flatAmount')} type="number" />
            </InputGroup>
            <InputGroup
              className={styles['input-group']}
              label="Total Advance Amount"
            >
              <Input {...register('advancedAmount')} type="number" />
            </InputGroup>
            <InputGroup
              className={styles['input-group']}
              label="Total Other Amount"
            >
              <Input {...register('otherAmount')} type="number" />
            </InputGroup>
            <InputGroup
              className={styles['input-group']}
              label="Total Offer Amount"
            >
              <p>${getTotalOfferAmt()}</p>
            </InputGroup>
          </div>

          <div className="row">
            <div className="col-md-4">
              <FormDatepicker
                placeholder="Select a Date"
                name="offerDate"
                label="Offer Date"
                initialDate={defaultValues?.offerDate || null}
                rules={{ required: true }}
                message="Offer date can't be blank"
              />
            </div>
            <div className="col-md-8">
              <InputGroup label="Offer Notes">
                <Textarea
                  {...register('notes')}
                  name="notes"
                  placeholder="Please leave a note regarding this offer"
                />
              </InputGroup>
            </div>
          </div>
        </div>
        <FormFooter 
          submit={`${defaultValues?.statusId ? 'Update offer': 'Create Offer'}`} 
          cancel="Cancel" className="modal" />
      </form>
    </FormProvider>
  )
}

export default OfferDetailsForm
