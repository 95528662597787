import { FC, useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router'
import { Button, Icon } from '@wmgtech/legato'

import { getCalculationDetails } from 'utils/reserveCalculations'
import currencyFormat from 'utils/currencyFormat'
import LabelSelectionModal from './LabelSelectionModal/LabelSelectionModal'
import Table from 'components/Table'
import styles from './styles.module.scss'

interface ITableItem {
  id: string
  auditId: string
  label: {
    name: string
    id: string
  }
  auditSubcategory: {
    name: string
    id: string
  }
  reason: {
    name: string
  }
  earnings: number
  rate: string | number
  fpp: number
  otherAmount: number
  additionalAmount: number
  previousAmount: number
  amount: number
}

const Reserves: FC<any> = ({ auditData }) => {
  const columns = [
    {
      title: 'Label',
      dataIndex: 'auditLabel',
      key: 'auditLabel',
      render: (value: string, row: ITableItem) => (
        <small>{row.label.name}</small>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'auditSubcategory',
      key: 'auditSubcategory',
      render: (value: string, row: ITableItem) => (
        <small>{row.auditSubcategory.name}</small>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'reservedDate',
      key: 'reservedDate',
    },
    {
      title: 'Change Reason',
      dataIndex: 'reason',
      key: 'reason',
      render: (value: string, row: ITableItem) => row.reason.name,
    },
    {
      title: 'Earnings',
      dataIndex: 'earnings',
      key: 'earnings',
      render: (value: string, row: ITableItem) =>
        row.earnings ? currencyFormat(row.earnings) : '-',
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate',
      render: (value: string, row: ITableItem) =>
        row.rate ? (+row.rate).toFixed(2) + '%' : '-',
    },
    {
      title: 'FPP Amt',
      dataIndex: 'fpp',
      key: 'fpp',
      render: (value: string, row: ITableItem) =>
        row.fpp ? currencyFormat(row.fpp) : '-',
    },
    {
      title: 'Other Amt',
      dataIndex: 'otherAmount',
      key: 'otherAmount',
      render: (value: string, row: ITableItem) =>
        row.otherAmount ? currencyFormat(row.otherAmount) : '-',
    },
    {
      title: 'Add. Amt',
      dataIndex: 'addAmount',
      key: 'addAmount',
      render: (value: string, row: ITableItem) =>
        row.additionalAmount ? currencyFormat(row.additionalAmount) : '-',
    },
    {
      title: 'Prev Res',
      dataIndex: 'prevReserve',
      key: 'prevReserve',
      render: (value: string, row: ITableItem) =>
        row.previousAmount ? currencyFormat(row.previousAmount) : '-',
    },
    {
      title: 'Reserve Amt',
      dataIndex: 'resAmount',
      key: 'resAmount',
      render: (value: string, row: ITableItem) => currencyFormat(row.amount),
    },
    {
      title: 'Historical',
      dataIndex: 'history',
      key: 'history',
      render: (value: string, row: ITableItem) => {
        return (
          <Link
            to={`/audits/${row.auditId}/types/${row.auditSubcategory.id}/labels/${row.label.id}/ramounts`}
          >
            View History
          </Link>
        )
      },
    },
    {
      title: '',
      dataIndex: '',
      key: 'editable',
      render: (value: string, row: ITableItem) => {
        return (
          <Link
            to={`/audits/${row.auditId}/types/${row.auditSubcategory.id}/labels/${row.label.id}/ramounts/${row.id}/edit`}
          >
            <Icon name="edit" prefix="fas" />
          </Link>
        )
      },
    },
  ]

  const history = useHistory()
  const labelsCount = auditData.labels.length
  const auditTypesCount = auditData.types.length
  const defaultLabel = labelsCount === 1 ? auditData.labels[0].labelId : ''
  const defaultAudit =
    auditTypesCount === 1 ? auditData.types[0].subcategoryId : ''

  const defaultValues = {
    labelId: defaultLabel,
    auditTypeId: defaultAudit,
  }

  const [showLabelSelectionModal, setShowLabelSelectionModal] = useState(false)

  const toggleModal = useCallback(() => {
    setShowLabelSelectionModal((show) => !show)
  }, [])

  const onAddReserveChange = () => {
    if (labelsCount > 1 || auditTypesCount > 1) {
      toggleModal()
    } else if (labelsCount === 1) {
      history.push(
        `/audits/${auditData.id}/types/${auditData.types[0].subcategoryId}/labels/${auditData.labels[0].labelId}/ramounts/new`
      )
    }
  }

  const reservedAmountsList = getCalculationDetails(auditData?.reservedAmounts)

  return (
    <div className={styles.container}>
      <LabelSelectionModal
        defaultValues={defaultValues}
        auditData={auditData}
        onClose={toggleModal}
        show={showLabelSelectionModal}
      />
      <header className={styles.header}>
        <div>
          <h4>Reserve Calculations</h4>
          <p>Below are all the current reserves you have added to this audit</p>
        </div>
        <div className={styles['button-wrapper']}>
          {!labelsCount && (
            <Button
              disabled
              secondaryIcon="exclamation"
              label="Add Labels to Audit"
            />
          )}
          {!!labelsCount && (
            <Button
              onClick={onAddReserveChange}
              secondaryIcon="plus"
              label="Add Reserve Change"
            />
          )}
        </div>
      </header>
      <Table columns={columns} data={reservedAmountsList || []}></Table>
    </div>
  )
}

export default Reserves
