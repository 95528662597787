import { IAllocation } from 'interfaces/ClaimsInterfaces'

export const calculateAllocations = (labels: any[], reservesTotal: any, claim: any): IAllocation[] => {
  const allocations: IAllocation[] = []

  const reserve = reservesTotal
    .find((res: any) => claim.auditTypeId === res.auditTypeId)

  for (let label of reserve.labels) {
    const amount = label ? (label.part / 100) * claim.amount : 0
    const auditLabel = labels.find((l: any) => l.labelId === label.labelId)
    const allocation = {
      label: {
        id: auditLabel.labelId,
        abbreviation: auditLabel.abbreviation

      },
      amount: +amount.toFixed(2),
      notes: '',
      part: label ? label.part : '0.00',
    }
    allocations.push(allocation)
  }
  return allocations
}

export const calculateUnionAllocations = (labels: any[], initialAllocations?: IAllocation[] | null): IAllocation[] => {
  const allocations: IAllocation[] = []
  for (let label of labels) {
    const initialAllocation = initialAllocations?.find((l) => l.label?.id === label.labelId)
    const allocation = {
      label: {
        id: label.labelId,
        abbreviation: label.abbreviation
      },
      amount: initialAllocation?.amount || (0).toFixed(2),
      notes: initialAllocation?.notes || '',
      part: label ? label.part : '0.00',
    }
    allocations.push(allocation)
  }
  return allocations
}
