import { FC, Suspense } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Accordion } from '@wmgtech/legato'

import Spinner from 'components/Spinner'
import LoadingButton from 'components/LoadingButton'
import OfferAllocations from '../OfferAllocations'
import OfferDetailsForm from '../OfferDetailsForm'
import styles from './styles.module.scss'

const OfferForm: FC<any> = ({
  defaultValues,
  onSubmit,
  offersData,
  claims,
}) => {
  const methods = useForm({ defaultValues: defaultValues })

  return (
    <>
      <Accordion
        className={styles.accordion}
        list={[
          {
            title: 'Total Offer Details',
            content: (
              <Suspense fallback={<Spinner name="circle-notch" />}>
                <OfferDetailsForm
                  defaultValues={defaultValues}
                  onSubmit={onSubmit}
                />
              </Suspense>
            ),
            id: 0,
            expand: false,
          },
        ]}
      />
      <div className={styles.allocations}>
        <h3>Add New Offer and Allocations</h3>
        <p>Add new amounts here, which need to total to -$176,163.02.</p>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
            <OfferAllocations data={offersData ?? []} claims={claims ?? []} />
            <footer className={styles.footer}>
              <LoadingButton
                type="submit"
                containerStyle={'outline'}
                colorType={'primary'}
              >
                Save Offer Allocation
              </LoadingButton>
            </footer>
          </form>
        </FormProvider>
      </div>
    </>
  )
}

export default OfferForm
