import React, { FC } from 'react'

import LoadingButton from 'components/LoadingButton'
import styles from './styles.module.scss'

export type ButtonFilterLabel = {
  id: string
  name: string
}

type ButtonFilterProps = {
  activeLabels: (number | null)[]
  labels: ButtonFilterLabel[]
  onClear: () => void
  title: string
  onSelect: (id: string) => void
}

const ButtonFilter: FC<ButtonFilterProps> = ({
  labels,
  activeLabels,
  onClear,
  title,
  onSelect,
}) => {
  return (
    <>
      <h4>{title}</h4>
      {labels.map((label) => {
        const isActive = activeLabels.includes(+label.id)

        return isActive ? (
          <LoadingButton
            className={styles.button}
            key={label.id}
            onClick={() => onSelect(label.id)}
            icon="check"
            colorType={'success'}
            size="sm"
          >
            {label.name}
          </LoadingButton>
        ) : (
          <LoadingButton
            className={styles.button}
            key={label.id}
            onClick={() => onSelect(label.id)}
            containerStyle={'outline'}
            colorType={'secondary-black'}
            size="sm"
          >
            {label.name}
          </LoadingButton>
        )
      })}

      <LoadingButton
        onClick={onClear}
        containerStyle={'outline'}
        size="sm"
        icon="times"
      >
        Clear
      </LoadingButton>
    </>
  )
}

export default ButtonFilter
