import { FC, useState } from 'react'
import { useController } from 'react-hook-form'
import { Datepicker, InputGroup } from '@wmgtech/legato'
import { TValidationType } from '@wmgtech/legato/build/components/FormControls/shared/hooks'

interface IFormDatepicker {
  name: string
  label: string
  initialDate: Date | null | string
  rules?: Object
  message?: string
  placeholder?: string
  disabled?: boolean
  minDate?: string
}

const FormDatepicker: FC<IFormDatepicker> = ({
  name,
  label,
  rules,
  message,
  initialDate,
  placeholder,
  ...props
}) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name: name, rules: rules, defaultValue: initialDate })
  const { onChange, value } = field

  const handleChahge = (date: Date) => {
    setDate(date)
    onChange(date)
  }

  const checkForErrors = (): TValidationType | undefined => {
    if (error) {
      return 'error'
    }
  }

  const validation = {
    required: rules?.hasOwnProperty('required'),
    message,
    type: checkForErrors(),
  }

  const [startDate, setDate] = useState(initialDate)
  return (
    <InputGroup
      label={label}
      validation={validation}
      validationIconPlacement="input"
    >
      <Datepicker
        value={value}
        dateFormat="yyyy-MM-dd"
        selected={startDate}
        placeholder={placeholder}
        onChange={handleChahge}
        {...props}
      />
    </InputGroup>
  )
}

export default FormDatepicker
