import { FC } from 'react'
import { Button, Modal } from '@wmgtech/legato'

interface IStatusPayload {
  id: string
  statusId: string
}

interface IProps {
  show: boolean
  onClose: () => void
  onSubmit: (data: any) => void
  statusPayload?: IStatusPayload
}

const StatusChangeModal: FC<IProps> = ({
  show,
  onClose,
  onSubmit,
  statusPayload,
}) => {
  return (
    <Modal
      footer={
        <div style={{ display: 'flex', gap: 10, justifyContent: 'flex-end' }}>
          <Button
            colorType="secondary-black"
            containerStyle="outline"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button colorType="warning" onClick={onSubmit}>
            Change status
          </Button>
        </div>
      }
      isOpen={show}
      onClose={onClose}
      shouldCloseOnOverlayClick
      title={
        statusPayload?.statusId === '1'
          ? 'By changing status you might delete reserved amounts. Are you sure? '
          : 'By changing status you might create new reserved amounts for this audit. Are you sure?'
      }
    ></Modal>
  )
}

export default StatusChangeModal
