import React, { FC } from 'react'
import { IIconProps, Icon, Tooltip } from '@wmgtech/legato'
import { TLegatoColorType } from '@wmgtech/legato/build/shared/types'
import { TBadgeColorType } from '@wmgtech/legato/build/components/Badge'

import styles from './styles.module.scss'
import classNames from 'classnames'

type IIconTooltip = {
  text: IIconProps['name']
  message: string
  type: TLegatoColorType | TBadgeColorType
}

const IconWithTooltip: FC<IIconTooltip> = ({ text, message, type }) => {
  return (
    <Tooltip
      colorType={type}
      containerStyle="solid"
      message={message}
      triggers={{ onHover: true }}
      placement="topRight"
    >
      <Icon
        name={text}
        prefix="fas"
        className={classNames(styles[`${type}`], styles.icon)}
      />
    </Tooltip>
  )
}

export default IconWithTooltip
