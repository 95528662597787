import { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { InputGroup, Modal } from '@wmgtech/legato'

import FormSelect from 'components/Form/FormSelect/FormSelect'
import FormFooter from 'components/Form/FormFooter'
import convertToList from 'utils/convertToList'

interface IModalProps {
  show: boolean
  auditData: { labels: any[]; types: any[]; id: string }
  onClose: () => void
  defaultValues: any
}

interface IModalValues {
  auditTypeId: string
  labelId: string
}

const LabelSelectionModal: FC<IModalProps> = ({
  show,
  onClose,
  auditData,
  defaultValues,
}) => {
  const auditTypesList = convertToList(auditData?.types)
  const labelsList = convertToList(auditData?.labels)

  const history = useHistory()

  const methods = useForm({
    defaultValues: defaultValues,
  })

  const onSubmit = (data: IModalValues) => {
    history.push(
      `/audits/${auditData.id}/types/${data.auditTypeId}/labels/${data.labelId}/ramounts/new`
    )
  }

  return (
    <Modal
      isOpen={show}
      onClose={onClose}
      shouldCloseOnOverlayClick
      title="Select a Label and Audit Type"
    >
      <p className="mb-2">
        Which label and audit type would you like to make a reserve change on?
      </p>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
          <div className="form-group">
            <InputGroup label="Label">
              <FormSelect
                value={defaultValues?.labelId}
                name="labelId"
                list={labelsList}
                placeholder="Select a label"
                disabled={auditData?.labels?.length === 1}
              />
            </InputGroup>
            <InputGroup label="Audit Type">
              <FormSelect
                value={defaultValues?.auditTypeId}
                name="auditTypeId"
                list={auditTypesList}
                placeholder="Select a type"
                disabled={auditData?.types?.length === 1}
              />
            </InputGroup>
          </div>
          <FormFooter
            className="modal"
            cancel="Cancel"
            onClick={onClose}
            submit="Proceed with Reserve"
          />
        </form>
      </FormProvider>
    </Modal>
  )
}

export default LabelSelectionModal
