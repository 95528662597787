import { FC } from 'react'
import { Button, Modal } from '@wmgtech/legato'

const SubmissionModule: FC<any> = ({ show, onClose, onSubmit, name }) => {
  return (
    <Modal
      footer={
        <div style={{ display: 'flex', gap: 10, justifyContent: 'flex-end' }}>
          <Button
            colorType="secondary-black"
            containerStyle="outline"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button colorType="danger" onClick={onSubmit}>
            OK
          </Button>
        </div>
      }
      isOpen={show}
      onClose={onClose}
      shouldCloseOnOverlayClick
      title={`Are you sure you want to delete this ${name}?`}
    ></Modal>
  )
}

export default SubmissionModule
