import { FC } from 'react'
import { Button, Modal } from '@wmgtech/legato'

import OfferLabelsTable from '../OfferLabelsTable'
import styles from './styles.module.scss'

interface IProps {
  show: boolean
  offer: any
  onClose: () => void
  onSubmit: (data: any) => void
}

const OfferDetailsModal: FC<IProps> = ({ show, onClose, onSubmit, offer }) => {
  return (
    <Modal
      className={styles.modal}
      isOpen={show}
      title={
        <div className={styles['modal-title']}>
          <h2>Settlement Details</h2>
        </div>
      }
      onClose={onClose}
      shouldCloseOnOverlayClick
    >
      <div className={styles.body}>
        <p>
          <b>Settlement Date: {offer.settDate || 'N/A'}</b>
        </p>
        <p>
          <b>Settlement Note: {offer.settNote || 'N/A'}</b>
        </p>
        <div className={styles.table}>
          <OfferLabelsTable offer={offer} />
        </div>
      </div>

      <div className={styles.footer}>
        <Button
          containerStyle={'outline'}
          colorType={'secondary-black'}
          onClick={onClose}
        >
          Close
        </Button>
        <Button
          containerStyle={'outline'}
          colorType={'danger'}
          onClick={onSubmit}
          secondaryIcon="trash"
        >
          Delete this Settlement
        </Button>
      </div>
    </Modal>
  )
}

export default OfferDetailsModal
