import { FC } from 'react'

import Table from 'components/Table'
import currencyFormat from 'utils/currencyFormat'


interface OfferAmount {
  flat: string;
  advanced: string;
  other: string;
  claimAllocation: string;
}

interface Label {
  id: string;
  name: string;
  abbreviation: string;
}

interface Allocation {
  amount: OfferAmount;
  label: Label;
}

interface ProcessedAllocation {
  name: string;
  flat: string;
  advance: string;
  other: string;
  total: string;
}

const OfferLabelsTable: FC<any> = ({ offer }) => {
  const columns = [
    {
      title: 'Label',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Flat Amt',
      dataIndex: 'flat',
      key: 'flat',
      render: (value: string, row: any) => currencyFormat(row.flat),
    },
    {
      title: 'Advance Amt',
      dataIndex: 'advance',
      key: 'advance',
      render: (value: string, row: any) => currencyFormat(row.advance),
    },
    {
      title: 'Other Amt',
      dataIndex: 'other',
      key: 'other',
      render: (value: string, row: any) => currencyFormat(row.other),
    },
    {
      title: 'Total Offer',
      dataIndex: 'total',
      key: 'total',
      render: (value: string, row: any) => currencyFormat(row.total),
    },
  ]

  const calculateAmt = (type: string) => {
    return offer?.allocations.reduce(
      (acc: number, cur: any) => acc + +cur.amount[type],
      0
    )
  }

  const calculateTotalAmt = () => {
    return (
      calculateAmt('flat') + calculateAmt('advanced') + calculateAmt('other')
    )
  }

  const processAllocations = (allocations: Allocation[]) => {
    return allocations.reduce((acc: Record<string, ProcessedAllocation>, allocation: Allocation) => {
      const labelName = allocation.label.name;
      const amount = allocation.amount;

      if (!acc[labelName]) {
        acc[labelName] = {
          name: labelName,
          flat: '0.00',
          advance: '0.00',
          other: '0.00',
          total: '0.00'
        };
      }

      acc[labelName].flat = (parseFloat(acc[labelName].flat) + parseFloat(amount.flat)).toFixed(2);
      acc[labelName].advance = (parseFloat(acc[labelName].advance) + parseFloat(amount.advanced)).toFixed(2);
      acc[labelName].other = (parseFloat(acc[labelName].other) + parseFloat(amount.other)).toFixed(2);

      const total = parseFloat(acc[labelName].flat) +
          parseFloat(acc[labelName].advance) +
          parseFloat(acc[labelName].other);
      acc[labelName].total = total.toFixed(2);

      return acc;
    }, {});
  }

  const labels = Object.values(processAllocations(offer.allocations))


  labels.push({
    name: 'Grand Total',
    flat: calculateAmt('flat')?.toString(),
    advance: calculateAmt('advanced')?.toString(),
    other: calculateAmt('other')?.toString(),
    total: calculateTotalAmt()?.toString(),
  })

  return <>{offer ? <Table columns={columns} data={labels || []} /> : ''}</>
}

export default OfferLabelsTable
