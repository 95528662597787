import { FC } from 'react'
import { Icon } from '@wmgtech/legato'

import styles from './styles.module.scss'

const Footer: FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div>
          <strong>Copyright</strong> Warner Music Group © {new Date().getFullYear()}
        </div>
        <div>
          Powered by <Icon prefix="fas" name="coffee" /> coffee and <Icon prefix="fas" name="headphones" /> music.
        </div>
      </div>
    </div>
  )
}

export default Footer
