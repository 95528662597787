import React, { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useOktaAuth } from '@okta/okta-react'
import { UserClaims } from '@okta/okta-auth-js/lib/types'

import Footer from './components/Footer'
import Sidebar from './components/Sidebar'
import Header from './components/Header'
import styles from './styles.module.scss'

const MainLayout: FC = ({ children }) => {
  const [sidebarExpanded, setSidebarExpanded] = useState(true)
  const { oktaAuth, authState } = useOktaAuth()
  const [userInfo, setUserInfo] = useState<UserClaims | null>(null)
  const history = useHistory()
  //const showProgress = useGlobalProgress()

  const handleLogOut = () => oktaAuth.signOut()

  const handleEditProfile = () => history.push('/users/edit')

  useEffect(() => {
    if (authState && authState.isAuthenticated) {
      oktaAuth.getUser().then(setUserInfo)
    }
  }, [authState, oktaAuth])

  const handleSidebarToggle = () => {
    setSidebarExpanded((expanded) => !expanded)
  }

  return (
    <div className={styles.container}>
      <aside className={styles.sidebar}>
        <Sidebar
          userInfo={userInfo}
          onLogOut={handleLogOut}
          sidebarExpanded={sidebarExpanded}
        />
      </aside>
      <header className={styles.header}>
        <Header
          userInfo={userInfo}
          onLogout={handleLogOut}
          onEditProfile={handleEditProfile}
          onSidebarToggle={handleSidebarToggle}
        />
        {/* {showProgress && <Progress animated now={100} variant='success' />} */}
      </header>
      <main className={styles.body}>{children}</main>
      <footer className={styles.footer}>
        <Footer />
      </footer>
    </div>
  )
}

export default MainLayout
