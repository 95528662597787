import { FC } from 'react'
import { Link } from 'react-router-dom'
import { IIconProps, Icon } from '@wmgtech/legato'

import Spinner from 'components/Spinner/Spinner'
import styles from './styles.module.scss'

export type IAdminCardProps = {
  title: string
  link: string
  loading?: boolean
  total?: number | string | null
  icon: IIconProps['name']
}

const AdminControlCard: FC<IAdminCardProps> = ({
  title,
  link,
  loading,
  total,
  icon,
}) => {
  return (
    <div className={styles.card}>
      <Link to={link} className={styles.link}>
        <Icon name={icon} className="fa-2x mb-05" prefix="fas" />
        <h3>
          <span className={styles.header}>{title}</span>
          {total ? (
            loading ? (
              <Spinner name="circle-notch" />
            ) : (
              `(${total})`
            )
          ) : null}
        </h3>
      </Link>
    </div>
  )
}

export default AdminControlCard
