import React, { FC } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js'
import { Line } from 'react-chartjs-2'

import styles from './styles.module.scss'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
)

const d1 = [
  6, 3057, 20434, 31982, 26602, 27826, 24302, 24237, 21004, 12144, 10577, 10295,
]

const d2 = [
  5, 200, 1605, 6129, 11643, 19055, 30062, 39197, 37000, 27000, 21000, 17000,
]

const FlotChart: FC = () => {
  return (
    <div className={styles.container}>
      <Line
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
            filler: {
              propagate: false,
            },
          },
          interaction: {
            intersect: false,
          },
          scales: {
            x: {
              display: false,
            },
            y: {
              display: false,
            },
          },
        }}
        data={{
          labels: [
            1262304000000, 1264982400000, 1267401600000, 1270080000000,
            1272672000000, 1275350400000, 1277942400000, 1280620800000,
            1283299200000, 1285891200000, 1288569600000, 1291161600000,
          ],
          datasets: [
            {
              label: 'Data 1',
              data: d1,
              borderColor: '#8CD790',
              backgroundColor: '#8CD790',
              fill: true,
              tension: 0.4,
            },
            {
              label: 'Data 2',
              data: d2,
              borderColor: '#77AF9C',
              backgroundColor: '#77AF9C',
              fill: true,
              tension: 0.4,
            },
          ],
        }}
      />
    </div>
  )
}

export default FlotChart
