import { FC, useCallback, useEffect, useRef, useState } from 'react'

import { Link, useHistory, useParams } from 'react-router-dom'
import { Button, Icon } from '@wmgtech/legato'

import ReservesHeader from '../ReservesHeader/ReservesHeader'
import { IBreadcrumbs } from '../../../../interfaces/BreadCrumbsInterface'
import currencyFormat from 'utils/currencyFormat'
import {
  ReservedAmountsHistoryQuery,
  useRemoveAuditReserveMutation,
  useReservedAmountsHistoryQuery,
} from 'generated/graphql'
import notify from 'utils/notify'
import { Notification } from 'constants/typeConstants'
import useData from 'hooks/useData'
import { ReservedAmountsHistoryDocument } from 'generated/graphql'
import SubmissionModal from 'components/SubmissionModal'
import { AuditDocument } from 'generated/graphql'
import { getCalculationDetails } from 'utils/reserveCalculations'
import Table from 'components/Table'
import IconWithTooltip from 'components/IconWithTooltip'
import styles from './styles.module.scss'

const ReservesForLabel: FC = () => {
  const columns = [
    {
      title: 'Reserve Date',
      dataIndex: 'reservedDate',
      key: 'reservedDate',
    },
    {
      title: 'Change Reason',
      dataIndex: 'reason',
      key: 'reason',
      render: (value: string, row: any) => row.reason.name,
    },
    {
      title: 'Earnings',
      dataIndex: 'earnings',
      key: 'earnings',
      render: (value: string, row: any) =>
        row.earnings ? currencyFormat(row.earnings) : '-',
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate',
      render: (value: string, row: any) => (row.rate ? row.rate + '%' : '-'),
    },
    {
      title: 'FPP Amt',
      dataIndex: 'fpp',
      key: 'fpp',
      render: (value: string, row: any) =>
        row.fpp ? currencyFormat(row.fpp) : '-',
    },
    {
      title: 'Other Amt',
      dataIndex: 'otherAmount',
      key: 'otherAmount',
      render: (value: string, row: any) =>
        row.otherAmount ? currencyFormat(row.otherAmount) : '-',
    },
    {
      title: 'Add Amt',
      dataIndex: 'additionalAmount',
      key: 'additionalAmount',
      render: (value: string, row: any) =>
        row.additionalAmount ? currencyFormat(row.additionalAmount) : '-',
    },
    {
      title: 'Prev Res',
      dataIndex: 'prevReserve',
      key: 'prevReserve',
      render: (value: string, row: any) => {
        if (row.mismatched) {
          return (
            <div>
              {currencyFormat(row.previousAmount)}
              &nbsp;
              <IconWithTooltip
                text="circle-exclamation"
                message="Previous amount is not equal to reserve amount of previous reserve"
                type="danger"
              />
            </div>
          )
        }
        return row.previousAmount ? currencyFormat(row.previousAmount) : '-'
      },
    },
    {
      title: 'Reserve Amt',
      dataIndex: 'amount',
      key: 'amount',
      render: (value: string, row: any) => currencyFormat(row.amount),
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: 'editable',
      render: (value: string, row: any) => {
        return (
          <div>
            <Link
              to={`/audits/${auditId}/types/${subcategoryId}/labels/${labelId}/ramounts/${row.id}/edit`}
            >
              <Icon name="edit" prefix="fas" />
            </Link>
            <span className="separator">|</span>
            <Button
              onClick={() => {
                setReserve(row.id)
                toggleSubmissionModal()
              }}
              containerStyle={'link'}
              colorType={'danger'}
              size="sm"
              className={styles.button}
            >
              <Icon name="trash" prefix="fas" />
            </Button>
          </div>
        )
      },
    },
  ]
  const { auditId, labelId, subcategoryId } = useParams<{
    auditId: string
    labelId: string
    subcategoryId: string
  }>()
  const [showSubmissionModal, setShowSubmissionModal] = useState(false)
  const [reserveId, setReserve] = useState('')
  const [removeAuditReserve] = useRemoveAuditReserveMutation()
  const mounted = useRef(false)
  const history = useHistory()

  const breadcrumbs: IBreadcrumbs[] = [
    { name: 'Home', to: '/' },
    { name: 'My Audits', to: '/audits' },
    { name: 'Audit', to: `/audits/${auditId}` },
    { name: 'Reserves' },
  ]

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  })

  const { data, loading, previousData } = useReservedAmountsHistoryQuery({
    variables: { auditId },
    onError() {
      notify(
        Notification.ERROR,
        'There is some error while loading the Reserves data'
      )
    },
    fetchPolicy: 'cache-and-network',
  })

  const reservesData = useData<ReservedAmountsHistoryQuery>({
    loading,
    previousData,
    data,
  })

  const reservedAmountsList = getCalculationDetails(
    reservesData?.reservedAmountsHistory?.filter(
      (r) =>
        r?.label.id === labelId && r?.auditSubcategory?.id === subcategoryId
    ),
    true
  )

  const toggleSubmissionModal = useCallback(() => {
    setShowSubmissionModal((show) => !show)
  }, [])

  const onAddReserveChange = () => {
    history.push(
      `/audits/${auditId}/types/${subcategoryId}/labels/${labelId}/ramounts/new`
    )
  }

  const removeReserve = () => {
    removeAuditReserve({
      variables: { id: reserveId },
      refetchQueries: [
        {
          query: ReservedAmountsHistoryDocument,
          variables: { subcategoryId, auditLabelId: labelId },
        },
        {
          query: AuditDocument,
          variables: { input: auditId },
        },
      ],
    })
      .then(() => {
        mounted.current && toggleSubmissionModal()
        notify(Notification.SUCCESS, 'You have deleted a reserve')
      })
      .catch(() => {
        notify(
          Notification.ERROR,
          'There is some error while deleting audit reserve'
        )
      })
  }

  return (
    <>
      <SubmissionModal
        name="reserve"
        show={showSubmissionModal}
        onClose={toggleSubmissionModal}
        onSubmit={removeReserve}
      ></SubmissionModal>
      <ReservesHeader breadcrumbs={breadcrumbs} />
      <div className="page-container">
        <div className="row">
          <h3 className="col-md-9">
            All Reserves for {reservedAmountsList?.[0]?.label.name} /
            subcategory {reservedAmountsList?.[0]?.auditSubcategory.name}
          </h3>
          <div className="col-md-3">
            <Button
              onClick={onAddReserveChange}
              secondaryIcon="plus"
              label="Add Reserve Change"
            />
          </div>
        </div>

        <hr className="divider" />
        <Table columns={columns} data={reservedAmountsList || []}></Table>
      </div>
    </>
  )
}

export default ReservesForLabel
