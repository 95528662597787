const theme = {
  colors: {
    common: {
      white: '#fff',
      black: '#000'
    },
    primary: {
      light: '#1ab394',
      main: '#1ab394',
      dark: '#1ab394',
      contrastText: '#fff'
    },
    success: {
      light: '#1c84c6',
      main: '#1c84c6',
      dark: '#1c84c6',
      contrastText: '#fff'
    }
  }
}

export default theme
