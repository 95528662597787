import { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { Icon, InputGroup, Textarea } from '@wmgtech/legato'
import classNames from 'classnames'

import {
  MutationCreateAuditActivityArgs,
  MutationUpdateAuditActivityArgs,
} from 'generated/graphql'
import FormFooter from 'components/Form/FormFooter'
import FormInputValidated from 'components/Form/FormInputValidated'
import FormSelect from 'components/Form/FormSelect'
import { IListItem } from 'components/Form/FormSelect/FormSelect'
import styles from './styles.module.scss'

interface IActivityTypeForm {
  defaultValues: any
  action: string
  onSubmit: (
    data: MutationCreateAuditActivityArgs | MutationUpdateAuditActivityArgs
  ) => void
  loading: boolean
}

const ActivityTypeForm: FC<IActivityTypeForm> = ({
  action,
  defaultValues,
  onSubmit,
  loading,
}) => {
  const history = useHistory()
  const methods = useForm({
    defaultValues: defaultValues,
  })

  const beforeSubmit = (data: any) => {
    onSubmit({ ...data, order: Number(data.order) })
  }

  const responsibilities: IListItem[] = [
    { title: 'RMRA', key: 'RMRA' },
    { title: 'Auditor', key: 'Auditor' },
  ]

  const categories: IListItem[] = [
    { title: 'NOI', key: 'NOI' },
    { title: 'Fieldwork', key: 'Fieldwork' },
  ]

  const subcategories: IListItem[] = [
    { title: 'Sub 1', key: 'Sub 1' },
    { title: 'Sub 2', key: 'Sub 2' },
  ]

  const reportCategories: IListItem[] = [
    { title: 'Report 1', key: 'Report 1' },
    { title: 'Report 2', key: 'Report 2' },
  ]

  const { register } = methods

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(beforeSubmit)} autoComplete="off">
        <div className={classNames('form-group', styles['form-group-five'])}>
          <FormInputValidated
            defaultValue={defaultValues?.name}
            name="name"
            label="Activity Type Name"
            placeholder="Name must be unique"
            message="Activity Type Name can't be blank"
            rules={{ required: true }}
          ></FormInputValidated>
          <FormInputValidated
            defaultValue={defaultValues?.name}
            name="activityNumber"
            label="Activity Number"
            placeholder="Activity #"
            message="Activity Number can't be blank"
            rules={{ required: true }}
          ></FormInputValidated>
          <FormInputValidated
            defaultValue={defaultValues?.name}
            name="statusNumber"
            label="Status Number"
            placeholder="Status #"
            message="Status Number can't be blank"
            rules={{ required: true }}
          ></FormInputValidated>
          <FormInputValidated
            defaultValue={defaultValues?.name}
            name="order"
            label="Activity Order"
            placeholder="Activity Order"
            message="Activity Order can't be blank"
            rules={{ required: true }}
          ></FormInputValidated>
          <InputGroup className={styles['input-group']} label="Responsibility">
            <FormSelect
              name="responsibility"
              placeholder="Responsibility"
              list={responsibilities}
            ></FormSelect>
          </InputGroup>
        </div>
        <div className="form-group">
          <InputGroup label="Description of Activity">
            <Textarea
              {...register('description')}
              defaultValue={defaultValues?.description}
              name="description"
              placeholder="Brief Description"
            />
          </InputGroup>
        </div>
        <div className="form-group form-group-three-equal">
          <InputGroup className={styles['input-group']} label="Category">
            <FormSelect
              name="category"
              placeholder="Select Category"
              list={categories}
            ></FormSelect>
          </InputGroup>
          <InputGroup className={styles['input-group']} label="Subcategory">
            <FormSelect
              name="auditStatus"
              placeholder="Select Subcategory"
              list={subcategories}
            ></FormSelect>
          </InputGroup>
          <InputGroup
            className={styles['input-group']}
            label="Optional Report Category"
          >
            <FormSelect
              name="reportCategory"
              placeholder="Select Report Category"
              list={reportCategories}
            ></FormSelect>
          </InputGroup>
        </div>
        <footer>
          <FormFooter
            cancel={
              <>
                <Icon name="less-than" /> Back
              </>
            }
            onClick={() => history.push('/activity-types')}
            submit={action}
            loading={loading}
          />
        </footer>
      </form>
    </FormProvider>
  )
}

export default ActivityTypeForm
