import { FC } from 'react'

import CenteredLogo from 'components/CenteredLogo'
import styles from './styles.module.scss'

const HomePage: FC = () => {
  return (
    <>
      <CenteredLogo />
      <br />
      <br />
      <div className={styles.title}>
        <h4>Welcome to the Audit Trax application!</h4>
        <p>Please use the navigation to the left</p>
      </div>
    </>
  )
}

export default HomePage
