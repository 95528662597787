const convertToNestedList = (dataArray: any[] | undefined | null) => {
  if (!dataArray) return []
  const categories: any[] = []
  for (let el of dataArray) {
    categories.push(el.statusNumber)
  }

  const nestedList = []
  for (let un of new Set(categories)) {
    const filteredArray = dataArray.filter(el => el.statusNumber === un)

    const newArray = filteredArray.map(el => ({ label: el.name, value: el.id }))
    nestedList.push({
      label: `${filteredArray[0].category} | Status Num: ${filteredArray[0].statusNumber}`,
      options: newArray, key: filteredArray[0].statusNumber
    })
  }
  return nestedList.sort((a, b) => a.key - b.key)
}

export default convertToNestedList
