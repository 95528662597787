import { FC } from 'react'
import { Icon, Modal } from '@wmgtech/legato'

import ActivityCommentForm from '../ActivityCommentForm'

interface ICommentProps {
  show: boolean
  auditId: string | undefined | null
  activity: any
  onClose: () => void
  onSubmit: (data: any) => void
}

const AddActivityCommentModal: FC<ICommentProps> = ({
  show,
  activity,
  onClose,
  onSubmit,
}) => {
  const defaultValues = {
    comment: '',
  }
  return (
    <Modal
      className="modal"
      isOpen={show}
      title={
        <div className="modal-title">
          <Icon className="modal-title-icon" name="comment" />
          <h2>Add Comment to Activity</h2>
          <hr className="divider" />
        </div>
      }
      onClose={onClose}
      shouldCloseOnOverlayClick
    >
      <ActivityCommentForm
        defaultValues={defaultValues}
        activity={activity}
        onClose={onClose}
        onSubmit={onSubmit}
      ></ActivityCommentForm>
    </Modal>
  )
}

export default AddActivityCommentModal
