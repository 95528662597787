import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { Table } from '@wmgtech/legato'

import FormDatepicker from 'components/Form/FormDatepicker/FormDatepicker'
import currencyFormat from 'utils/currencyFormat'
import {
  getCalculationDetails,
  reserveCalculations,
} from 'utils/reserveCalculations'
import { Notification, ReserveReason } from 'constants/typeConstants'
import { useBulkCreateReservedAmountMutation } from 'generated/graphql'
import notify from 'utils/notify'
import LoadingButton from 'components/LoadingButton/LoadingButton'
import convertDateToString from 'utils/convertDateToString'
import SuccessCreationModal from '../SuccessCreationModal/SuccessCreationModal'
import { CSVStringify } from 'utils/CsvStringify'
import downloadCsv from 'utils/downloadCsv'
import styles from './styles.module.scss'

interface IReserveTableProps {
  rateRowSelected: any
  list: IReserveAmount[]
  minDate: any
}

interface IReserveAmount {
  id: string
  amount: number
  auditId: string
  auditSubcategory: {
    name: string
  }
  label: {
    name: string
  }
  reservedDate: string
  earnings: number
  fpp: number
  otherAmount: number
  rate: number
}

const RateReservesTable: FC<IReserveTableProps> = ({
  rateRowSelected,
  list,
  minDate,
}) => {
  const [bulkCreateResAmounts] = useBulkCreateReservedAmountMutation()

  const columns = [
    {
      title: 'Audit Name',
      dataIndex: 'auditName',
      key: 'auditName',
      quoted: true,
      render: (value: string, row: IReserveAmount) => {
        return <Link to={`/audits/${row.auditId}`}>{row.auditId}</Link>
      },
      transform: (col: Record<string, any>): string => col.audit.name,
    },
    {
      title: 'Audit Type',
      dataIndex: 'auditType',
      key: 'auditType',
      render: (value: string, row: IReserveAmount) => row.auditSubcategory.name,
      transform: (col: Record<string, any>): string => col.audit.auditType.name,
    },
    {
      title: 'Labels',
      dataIndex: 'auditLabel',
      key: 'auditLabel',
      render: (value: string, row: IReserveAmount) => row.label.name,
      transform: (col: Record<string, any>): string =>
        col.audit.auditLabel.name,
    },
    {
      title: 'Reserved Date',
      dataIndex: 'reservedDate',
      key: 'reservedDate',
      transform: (col: Record<string, any>): string => col.reservedDate,
    },
    {
      title: 'Earnings',
      dataIndex: 'earnings',
      key: 'earnings',
      render: (value: string, row: IReserveAmount) =>
        row.earnings ? currencyFormat(row.earnings) : '-',
      transform: (col: Record<string, any>): string =>
        col.earnings ? col.earnings : '-',
    },
    {
      title: 'FPP Amt',
      dataIndex: 'fpp',
      key: 'fpp',
      render: (value: string, row: IReserveAmount) =>
        row.fpp ? currencyFormat(row.fpp) : '-',
      transform: (col: Record<string, any>): string =>
        col.fpp ? col.fpp : '-',
    },
    {
      title: 'Other Amt',
      dataIndex: 'otherAmount',
      key: 'otherAmount',
      render: (value: string, row: IReserveAmount) =>
        row.otherAmount ? currencyFormat(row.otherAmount) : '-',
      transform: (col: Record<string, any>): string =>
        col.otherAmount ? col.otherAmount : '-',
    },
    {
      title: 'Current Rate',
      dataIndex: 'rate',
      key: 'rate',
      render: (value: string, row: IReserveAmount) =>
        row.rate ? row.rate + '%' : '-',
      transform: (col: Record<string, any>): string =>
        col.rate ? col.rate + '%' : '-',
    },
    {
      title: 'Cur Reserve Amt',
      dataIndex: 'prevReserve',
      key: 'prevReserve',
      render: (value: string, row: IReserveAmount) =>
        currencyFormat(row.amount),
      transform: (col: Record<string, any>): string => col.amount,
    },
    {
      title: 'New Reserve Amt',
      dataIndex: 'resAmount',
      key: 'resAmount',
      render: (value: string, row: IReserveAmount) =>
        '$' + calculateNewReserveAmt(row),
      transform: (col: Record<string, any>): string => col.rate,
      notForCSV: true,
    },
    {
      title: 'Difference',
      dataIndex: 'dif',
      key: 'dif',
      render: (value: string, row: IReserveAmount) => {
        if (!!calculateNewReserveAmt(row)) {
          return (+calculateNewReserveAmt(row)! - row.amount).toFixed(2)
        }
      },
      transform: (col: Record<string, any>): string => '',
      notForCSV: true,
    },
  ]

  const [selectedReservesRows, setSelectedRows] = useState<React.Key[]>([])
  const [showModal, setShowModal] = useState(false)
  const [createdReservesCount, setCreatedReservesCount] = useState(0)
  const [createdReserves, setCreatedReserves] = useState<any>()
  const methods = useForm()
  const mounted = useRef(false)

  const newList = getCalculationDetails(list)

  const reservedIds: string[] = []

  for (let i = 0; i < list?.length; i++) {
    if (selectedReservesRows.includes(i)) {
      reservedIds.push(list[i].id)
    }
  }

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  })

  const calculateNewReserveAmt = (row: any): string | undefined => {
    return reserveCalculations(
      ReserveReason.DEFAULT_RATE,
      {
        earnings: row.earnings,
        fpp: row.fpp,
        otherAmount: row.otherAmount,
        rate: rateRowSelected.rate,
      },
      '0'
    )
  }

  const toggleModal = useCallback(() => {
    setShowModal((show) => !show)
  }, [])

  const onSubmit = (data: any) => {
    const payload = {
      reasonId: ReserveReason.DEFAULT_RATE,
      config: {
        defaultRate: rateRowSelected.rate,
        reservedDate: convertDateToString(data.reservedDate),
        reservedIds,
      },
    }

    bulkCreateResAmounts({
      variables: { input: payload },
    })
      .then((response) => {
        const reservesCount = response.data?.bulkCreateReservedAmount?.length
        if (!reservesCount) {
          notify(Notification.ERROR, 'No reserves were created')
          return
        }
        const reserves = getCalculationDetails(
          response?.data?.bulkCreateReservedAmount
        )
        setCreatedReserves(reserves)
        setCreatedReservesCount(reservesCount)
        mounted.current && toggleModal()
      })
      .catch(() => {
        notify(
          Notification.ERROR,
          'There is some error while creating reserves'
        )
      })
  }

  const downloadCsvFile = async () => {
    const newColumns = columns.filter((r: any) => !r.notForCSV)
    const csv = await CSVStringify(createdReserves, {
      header: true,
      columns: newColumns,
    })

    downloadCsv(csv, 'New_reserves.csv')
  }

  return (
    <>
      <SuccessCreationModal
        show={showModal}
        onClose={toggleModal}
        onSubmit={downloadCsvFile}
        count={createdReservesCount}
      ></SuccessCreationModal>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
          <h3>Reserve Amounts</h3>
          <div className="row mb-2">
            <div className="col-md-3">
              Audit Subcategory: {rateRowSelected.subcategory}
            </div>
            <div className="col-md-3">Rate: {rateRowSelected.rate}</div>
            <div className="col-md-3"></div>
            <div className="col-md-3">
              <FormDatepicker
                name="reservedDate"
                label="Reserve Date"
                initialDate={minDate}
                rules={{ required: true }}
                message="Reserve date can't be blank"
                minDate={minDate}
              ></FormDatepicker>
            </div>
          </div>
          <Table
            columns={columns}
            data={newList || []}
            getSelectedRowsKeys={(selected) => {
              setSelectedRows(selected)
            }}
          ></Table>
          {newList?.length && (
            <LoadingButton
              type="submit"
              disabled={!reservedIds.length}
              className={styles.submit}
            >
              Apply {reservedIds.length ? `to ${reservedIds.length}` : ''}
            </LoadingButton>
          )}
        </form>
      </FormProvider>
    </>
  )
}

export default RateReservesTable
