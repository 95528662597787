const convertToList = (data?: any[] | null | undefined) => {
  return data?.map((st: any) => {
    return {
      title: st.name,
      key: st.subcategoryId || st.labelId || st.id,
    }
  }) ?? []
}

export default convertToList
