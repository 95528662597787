import { FC, useState } from 'react'
import {
  IMultiSelectProps,
  InputGroup,
  MultiSelect,
  Tag,
} from '@wmgtech/legato'
import { useController } from 'react-hook-form'

import styles from './styles.module.scss'

interface IListItem {
  title: string
  key: string
}

const getKeyFromItem = (item: IListItem) => {
  return item.key
}

const getTitleFromItem = (item: IListItem) => {
  return item.title
}

type IProps = Omit<IMultiSelectProps<IListItem>, 'getParentId'> & {
  selectLabel: string
}

const MultiSelectWithTag: FC<IProps> = ({
  getTitle = getTitleFromItem,
  getKey = getKeyFromItem,
  name,
  list,
  selectLabel,
  placeholder,
  ...props
}) => {
  const [value, setValue] = useState<ReadonlyArray<IListItem>>(
    props.value ? props.value : []
  )

  const {
    field,
    fieldState: { error },
  } = useController({
    name: name,
    rules: { required: true },
    defaultValue: props.value,
  })

  const { onChange } = field

  const checkForErrors = () => {
    if (error) {
      return 'error'
    }
  }

  return (
    <InputGroup
      label={selectLabel}
      validationIconPlacement="input"
      validation={{ ...props.validation, type: checkForErrors() }}
    >
      <MultiSelect
        name={name}
        list={list}
        placeholder={placeholder}
        getTitle={getTitleFromItem}
        getKey={getKeyFromItem}
        validation={props.validation}
        {...props}
        value={value}
        onSelect={(v) => {
          onChange(v)
          setValue(v)
        }}
      />
      {value.map((e: any) => (
        <Tag
          key={getKey(e)}
          text={getTitle(e)}
          onClickIcon={() => {
            setValue((s) => s.filter((f) => getKey(f) !== getKey(e)))
          }}
          className={styles.tag}
        />
      ))}
    </InputGroup>
  )
}

export default MultiSelectWithTag
