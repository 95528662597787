import React, { FC } from 'react'

import { Alert } from '@wmgtech/legato'

const ErrorAlert: FC = ({
  children = 'Unknown error. Please reload the page.',
}) => {
  return (
    <Alert colorType="danger" title="" text={children} dissmisable={false} />
  )
}

export default ErrorAlert
