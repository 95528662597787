import React, { FC } from 'react'
import { Button, Icon } from '@wmgtech/legato'

import styles from './styles.module.scss'
import classNames from 'classnames'
import convertDateToString from 'utils/convertDateToString'
import getIconForActivity from 'utils/getIconForActivity'

export type ActivityRowProps = {
  activity: any
  last: boolean
  updateActivity?: () => void
  deleteActivity?: () => void
  goToCommentsPage?: () => void
  createNewComment?: () => void
  expanded?: boolean
}

const ActivityRow: FC<ActivityRowProps> = ({
  activity,
  last,
  updateActivity,
  deleteActivity,
  goToCommentsPage,
  createNewComment,
  expanded,
}) => {
  let iconStyle
  iconStyle = last
    ? classNames(styles['stream-badge-icon'], styles['current-activity'])
    : styles['stream-badge-icon']

  return (
    <div className={styles.stream}>
      <div className={styles['stream-badge']}>
        <Icon
          className={iconStyle}
          name={getIconForActivity(activity.type.category)}
          prefix="fas"
        ></Icon>
      </div>
      <div className={styles['stream-body']}>
        <div className="row">
          <div
            className={classNames(
              styles['stream-info'],
              expanded ? 'col-md-5' : ''
            )}
          >
            <span>
              {activity.updatedBy?.firstName} {activity.updatedBy?.lastName}
            </span>
            <small>
              {activity.actualDate
                ? convertDateToString(activity.actualDate)
                : convertDateToString(activity.manuallyProjectedDate)}
            </small>
            <small>
              <Icon name="calendar"></Icon>{' '}
              <i>
                {activity.actualDate ? 'Actual Date' : 'Manually Projected'}
              </i>
            </small>
          </div>
          {expanded && (
            <div className={classNames(styles.btns, 'col-md-5')}>
              <Button
                label="Quick Comment"
                containerStyle={'outline'}
                colorType={'info'}
                secondaryIcon="plus"
                size="sm"
                prefix="fas"
                onClick={createNewComment}
              ></Button>
              <Button
                label={`Comments (${activity.comments?.length ?? 0})`}
                containerStyle={'outline'}
                colorType={'info'}
                secondaryIcon="comments"
                size="sm"
                prefix="fas"
                onClick={goToCommentsPage}
              ></Button>
              <Button
                containerStyle={'outline'}
                colorType={'info'}
                icon={<Icon name={'edit'} prefix="fas" />}
                size="sm"
                prefix="fas"
                onClick={updateActivity}
              ></Button>
              <Button
                containerStyle={'outline'}
                colorType={'danger'}
                icon={<Icon name={'trash'} prefix="fas" />}
                size="sm"
                prefix="fas"
                onClick={deleteActivity}
              ></Button>
            </div>
          )}
        </div>
        <div className={expanded ? 'col-md-5' : ''}>
          {activity.type?.activityNumber} | {activity.type.name}
        </div>
      </div>
    </div>
  )
}

export default ActivityRow
