import React, { FC } from 'react'
import { Global, css, useTheme } from '@emotion/react'

const GlobalStyles: FC = () => {
  const theme = useTheme()

  return (
    <Global
      styles={css`
        body {
          font-family: 'open sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
          background-color: #2f4050;
          font-size: 13px;
          color: #676a6c;
        }

        .h1,
        .h2,
        .h3,
        h1,
        h2,
        h3 {
          margin-top: 20px;
          margin-bottom: 10px;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-weight: 100;
        }

        h3,
        h4,
        h5 {
          margin-top: 5px;
          font-weight: 600;
        }

        h1 {
          font-size: 30px;
        }

        h2 {
          font-size: 24px;
        }

        h3 {
          font-size: 16px;
        }

        h4 {
          font-size: 14px;
        }

        h5 {
          font-size: 12px;
        }

        h6 {
          font-size: 10px;
        }

        small,
        .small {
          font-size: 80%;
          font-weight: 400;
        }

        .accordion-button:not(.collapsed) {
          color: rgb(103, 106, 108);
          background-color: #fff;
        }

        a {
          color: #337ab7;
          text-decoration: none;
          cursor: pointer;
          background-color: transparent;
          -webkit-text-decoration-skip: objects;
        }

        a:hover,
        a:focus {
          text-decoration: none;
        }

        a:not([href]):not([tabindex]) {
          color: inherit;
          text-decoration: none;
        }

        a:not([href]):not([tabindex]):hover,
        a:not([href]):not([tabindex]):focus {
          color: inherit;
          text-decoration: none;
        }

        a:not([href]):not([tabindex]):focus {
          outline: 0;
        }

        .accordion-header {
          margin-top: 0;
        }

        .card-header {
          background-color: #fff;
          font-size: 14px;
          font-weight: 600;
          padding: 15px 90px 8px 15px;
        }

        .badge {
          background: #d1dade;
          color: #fff;
          font-size: 11px;
          font-weight: 600;
          padding-bottom: 4px;
          padding-left: 6px;
          padding-right: 6px;
          text-shadow: none;
          white-space: nowrap;

          &.bg-success {
            background: #1c84c6 !important;
          }

          &.bg-primary {
            background: #1ab394 !important;
          }

          &.bg-warning {
            background: #f8ac59 !important;
          }

          &.bg-info {
            background: #23c6c8 !important;
          }

          &.bg-danger {
            background: #ed5565 !important;
          }
        }

        .btn-default {
          color: inherit;
          background: ${theme.colors.common.white};
          border: 1px solid #e7eaec;

          &:active,
          &:focus,
          &:hover {
            color: inherit;
            background: ${theme.colors.common.white};
            border: 1px solid #d2d2d2;
          }
        }

        .btn-outline-success {
          color: ${theme.colors.success.main};
          background-color: transparent;
          border-color: ${theme.colors.success.main};

          &:active,
          &:focus,
          &:hover {
            color: ${theme.colors.success.contrastText};
            background-color: ${theme.colors.success.main};
            border-color: ${theme.colors.success.main};
          }
        }

        .btn-success {
          color: ${theme.colors.success.contrastText};
          background-color: ${theme.colors.success.main};
          border-color: ${theme.colors.success.main};

          &:active,
          &:focus,
          &:hover {
            color: ${theme.colors.success.contrastText};
            background-color: ${theme.colors.success.main};
            border-color: ${theme.colors.success.main};
          }
        }

        .btn-primary {
          color: ${theme.colors.primary.contrastText};
          background-color: ${theme.colors.primary.main};
          border-color: ${theme.colors.primary.contrastText};

          &:active,
          &:focus,
          &:hover {
            color: ${theme.colors.primary.contrastText};
            background-color: ${theme.colors.primary.main};
            border-color: ${theme.colors.primary.contrastText};
          }
        }

        .btn-outline-primary {
          color: ${theme.colors.primary.main};
          border-color: ${theme.colors.primary.main};

          &:active,
          &:focus,
          &:hover {
            color: ${theme.colors.primary.contrastText};
            background: ${theme.colors.primary.main};
            border-color: ${theme.colors.primary.main};
          }
        }
      `}
    />
  )
}

export default GlobalStyles
