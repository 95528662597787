import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { isEmpty } from 'lodash'
import dayjs from 'dayjs'
import { Accordion } from '@wmgtech/legato'



import Table from 'components/Table'
//import  {//ConsolidatedReportByAuditIdsDocument,
 //FilterPeriod,
 // ConsolidatedReportByAuditIdsQuery,
 // OrderDirection,
 // useConsolidatedReportByAuditIdsQuery}
 //from 'generated/graphql'

import { ConsolidatedReportByAuditIdsQuery,
  useConsolidatedReportByAuditIdsQuery
} from 'generated/graphql'
import currencyFormat from 'utils/currencyFormat'
 import useData from 'hooks/useData'
 //import useGlobalProgress from 'hooks/useGlobalProgress'
 import notify from 'utils/notify'
 import { Notification } from 'constants/typeConstants'
import styles from './styles.module.scss'

interface AuditType {
  id: string;
  name: string;
  subcategoryId: string;
  // Add any other properties that exist on the type object
}
const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: '150px',
    sortable: true,
    className: `${styles.table}`,
    render: (value: string, row: any) => {
      return <Link to={`/audits/${row.id}`}>{value}</Link>
    },
  },
  {
    title: 'Subcategory',
    dataIndex: 'subCategories',
    key: 'subcategory',
    sortable: true,
    ellipsis: true,
    className: `${styles.table}`,
    render: (value: string, row: any) => {
      return row.types?.map((type: AuditType) => type.name).join(', ') || '';
    },
  },
  {
    title: 'Audit Period',
    dataIndex: 'auditPeriod',
    key: 'auditPeriod',
    sortable: true,
    render: (value: string, row: any) => {
      const format = 'MM/YY'
      return `${dayjs(row.startDate).format(format)} - ${dayjs(
        row.endDate
      ).format(format)}`
    },
  },
  {
    title: 'Beg Bal',
    dataIndex: 'begBal',
    key: 'begBal',
    sortable: true,
    render: (value: string, row: any) => currencyFormat(row.openingBalance),
  },
  {
    title: 'PL Impact',
    dataIndex: 'plImpact',
    key: 'plImpact',
    sortable: true,
    render: (value: string, row: any) => currencyFormat(row.plImpact),
  },
  {
    title: 'Payments',
    dataIndex: 'payments',
    key: 'payments',
    sortable: true,
    render: (value: string, row: any) => currencyFormat(row.payments),
  },
  {
    title: 'Ending Bal',
    dataIndex: 'endingBal',
    key: 'endingBal',
    sortable: true,
    render: (value: string, row: any) => currencyFormat(row.closingBalance),
  },
]

type AuditsTableProps = {
  categories: (string | null)[]
  labelMasters: (string | null)[]
  labelMastersNames: string[]
  startDate: (string | null)
  endDate:(string | null)
}

const AuditsTable: FC<AuditsTableProps> = ({
  categories,
  labelMasters,
  labelMastersNames,
  startDate, endDate
}) => {
  // const search = ''
   //const sortOrder = {
    // field: AuditDashboardFieldName.Name,
     //direction: OrderDirection.Asc,
  // }
   const limit = 1000
   const offset = 0
  const header = isEmpty(labelMastersNames)
    ? 'all label'
    : labelMastersNames.join(', ')

   const getAuditDashboardListQuery = useConsolidatedReportByAuditIdsQuery({
     variables: {
       input: {
         categories,
        // labels,
        // search,
         limit,
         offset,
         startDate,

         endDate,
       //  sortOrder,
       },
     },
     onError() {
       notify(
         Notification.ERROR,
         'There is some error while loading the audits data'
       )
     },
   })
   const auditDashboardListData = useData<ConsolidatedReportByAuditIdsQuery>({
     loading: getAuditDashboardListQuery.loading,
     previousData: getAuditDashboardListQuery.previousData,
     data: getAuditDashboardListQuery.data,
   })

  // useGlobalProgress(auditDashboardListQuery.loading)

  return (
    <Accordion
      className={styles.accordion}
      list={[
        {
          title: `All Audits for ${header}`,
          content: (
            <Table
              responsive
              columns={columns}
              //data={[]}
              data={auditDashboardListData?.consolidatedReportByAuditIDS?.items ?? []}
              pagination={{
                pageSize: 10,
                position: ['bottomCenter'],
              }}
            />
          ),
          id: 0,
          expand: false,
        },
      ]}
    ></Accordion>
  )
}

export default AuditsTable
