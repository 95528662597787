import { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { InputGroup, Textarea } from '@wmgtech/legato'

import FormDatepicker from 'components/Form/FormDatepicker'
import FormFooter from 'components/Form/FormFooter'

interface IFormProps {
  onClose?: () => void
  onSubmit: (data: any) => void
  revisionId?: string
  defaultValues?: any
}

const RevisionForm: FC<IFormProps> = ({
  onClose,
  onSubmit,
  revisionId,
  defaultValues,
}) => {
  const methods = useForm({ defaultValues: defaultValues })
  const { register } = methods

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
          <div className="modal-body">
            <input hidden name="id"></input>
            <div className="col-md-6">
              <FormDatepicker
                placeholder="Select a Date"
                name="revisionDate"
                label="Revision Date"
                initialDate={defaultValues?.revisionDate || null}
                rules={{ required: true }}
                message="Revision date can't be blank"
              />
            </div>
            <InputGroup label="Revision Note">
              <Textarea
                {...register('notes')}
                name="notes"
                placeholder="Please leave a note regarding this revision"
              />
            </InputGroup>
          </div>
          <FormFooter
            className="modal"
            cancel={!revisionId ? 'Cancel' : ''}
            onClick={onClose}
            submit={revisionId ? 'Update Revision' : 'Create Revision'}
          />
        </form>
      </FormProvider>
    </>
  )
}

export default RevisionForm
