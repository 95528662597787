import { FC } from 'react'
import { ApolloProvider, ApolloClient, HttpLink, concat } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { useOktaAuth } from '@okta/okta-react'

import { cache } from './cache'

const AuthApolloProvider: FC = ({ children }) => {
  const { authState } = useOktaAuth()

  const authMiddleware = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      authorization: authState?.idToken?.idToken || ''
    }
  }))

  const httpLink = new HttpLink({ uri: process.env.REACT_APP_API_URL })

  const client = new ApolloClient({
    cache,
    link: concat(authMiddleware, httpLink),
    connectToDevTools: true
  })

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default AuthApolloProvider
