import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, ColumnsType } from '@wmgtech/legato'
import dayjs from 'dayjs'
import classNames from 'classnames'

import AddActivityModal from './AddActivityModal'
import {
  AuditDocument,
  useCreateAuditActivityMutation,
} from 'generated/graphql'
import notify from 'utils/notify'
import { Notification } from 'constants/typeConstants'
import convertDateToString from 'utils/convertDateToString'
import TableComponent from 'components/Table'
import styles from './styles.module.scss'

interface IActivitiesProps {
  auditData: any
}

interface ITableItem {
  id: string
  name: string
  updatedAt: string
  statusNumber: string
  activityNumber: string
  actualDate: string | null
  manuallyProjectedDate: string | null
  projectedDate: string | null
  type: any
  comments: any[] | null
}

const getDate = (date: any) => {
  if (date) return dayjs(date).format('MMM DD, YYYY')
  return ''
}

const AuditActivities: FC<IActivitiesProps> = ({ auditData }) => {
  const [showCreationModal, setShowCreationModal] = useState(false)
  const [addActivity] = useCreateAuditActivityMutation()
  const toggleCreationModal = useCallback(() => {
    setShowCreationModal((show) => !show)
  }, [])

  const tableColumns: ColumnsType<any>[] = [
    {
      title: 'Date',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (value: string, row: ITableItem) =>
        row.actualDate
          ? convertDateToString(row.actualDate)
          : convertDateToString(row.manuallyProjectedDate),
    },
    {
      title: 'Activity',
      dataIndex: 'activity',
      key: 'activity',
      render: (value: string, row: ITableItem) => {
        return (
          <span>
            {row.type?.activityNumber} | {row.type?.name}
          </span>
        )
      },
    },
    {
      title: 'Comments',
      dataIndex: 'comments',
      key: 'comments',
      render: (value: string, row: ITableItem) => {
        return (
          <Link
            to={`/audits/${auditData.id}/activities/${row.id}/activity_comments`}
          >
            View ({row.comments?.length ?? 0})
          </Link>
        )
      },
    },
    {
      title: 'Actual Date',
      dataIndex: 'actualDate',
      key: 'actualDate',
      render: (value: string, row: ITableItem) => getDate(row.actualDate),
    },
    {
      title: 'Manually Proj Date',
      dataIndex: 'manuallyProjectedDate',
      key: 'manuallyProjectedDate',
      render: (value: string, row: ITableItem) =>
        getDate(row.manuallyProjectedDate),
    },
    {
      title: 'Calculated Date',
      dataIndex: 'projectedDate',
      key: 'projectedDate',
    },
  ]

  const history = useHistory()
  const activitiesSorted = [...auditData?.activities].sort((a, b) =>
    (b.actualDate ?? b.manuallyProjectedDate).localeCompare(
      a.actualDate ?? a.manuallyProjectedDate
    )
  )
  const [activities, setActivities] = useState(activitiesSorted)

  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  })

  const goToHistoryPage = () => {
    history.push(`/audits/${auditData.id}/activities`)
  }

  const AddAuditActivity = (data: any) => {
    const input = {
      ...data,
      actualDate: convertDateToString(data.actualDate),
      manuallyProjectedDate: convertDateToString(data.manuallyProjectedDate),
      auditId: auditData.id,
    }
    addActivity({
      variables: { input },
      refetchQueries: [
        {
          query: AuditDocument,
          variables: { input: auditData.id },
        },
      ],
    })
      .then((responce) => {
        mounted.current && toggleCreationModal()
        const newActivities = [
          ...activities,
          responce?.data?.createAuditActivity,
        ]
        setActivities(
          newActivities.sort((a, b) =>
            (b.actualDate ?? b.manuallyProjectedDate).localeCompare(
              a.actualDate ?? a.manuallyProjectedDate
            )
          )
        )
        notify(Notification.SUCCESS, 'Your activity was saved!')
      })
      .catch(() => {
        notify(
          Notification.ERROR,
          'There is some error while creating audit activity'
        )
      })
  }

  return (
    <>
      <AddActivityModal
        show={showCreationModal}
        onClose={toggleCreationModal}
        onSubmit={AddAuditActivity}
        auditId={auditData.id}
      ></AddActivityModal>
      <div className={classNames(styles.container, 'no-pagination')}>
        <header className={styles.header}>
          <div>
            <h4>Activity Quick View</h4>
            <p>
              Below are all the activities related to the audit. Add more
              activities as they happen!
            </p>
          </div>
          <div className="button-wrapper">
            <Button
              onClick={toggleCreationModal}
              secondaryIcon="plus"
              label="Quick Add Activity"
            />
            <Button
              onClick={goToHistoryPage}
              secondaryIcon="calendar"
              colorType="success"
              label="View Activity Home"
            />
          </div>
        </header>
        <TableComponent
          columns={tableColumns}
          data={activities ?? []}
          pagination={{
            pageSize: activities.length,
          }}
        />
      </div>
    </>
  )
}

export default AuditActivities
