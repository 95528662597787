import { FC, FocusEvent } from 'react'
import { useFormContext } from 'react-hook-form'
import { Input } from '@wmgtech/legato'
import classNames from 'classnames'

import {
  IAllocation,
  IClaim,
  IReserveForClaims,
} from 'interfaces/ClaimsInterfaces'
import styles from './styles.module.scss'

type fieldType = 'amount' | 'notes'

interface IAllocationFormProps {
  claim: IClaim
  index: number
  reserves: IReserveForClaims[] | null
  onChangeAllocation: (claim: IClaim) => void
}

const NestedForm: FC<IAllocationFormProps> = ({
  claim,
  index,
  reserves,
  onChangeAllocation,
}) => {
  const { register } = useFormContext()

  const findLabelPart = (allocation: IAllocation) => {
    return reserves
      ?.find((r) => {
        return (
          r.auditTypeId === claim.subcategory?.id ||
          r.auditTypeId === claim?.auditType?.id //TODO replace id with subcategoryId when audit type is fixed
        )
      })
      ?.labels.find((l) => l.labelId === allocation?.label?.id)?.part
  }

  const changeAllocation = (
    event: FocusEvent<HTMLInputElement>,
    claim: IClaim,
    allocation: IAllocation,
    field: fieldType
  ) => {
    let newAllocation
    if (field === 'amount') {
      if (allocation.amount === event.target.value) return
      newAllocation = { ...allocation, amount: event.target.value }
    } else {
      if (allocation.notes === event.target.value) return
      newAllocation = { ...allocation, notes: event.target.value }
    }

    const allocationIndex = claim.allocations.findIndex(
      (a: IAllocation) => a?.label?.id === allocation?.label?.id
    )
    const updatedAllocations = [...claim.allocations]
    updatedAllocations.splice(allocationIndex, 1, newAllocation)

    const newClaim = { ...claim, allocations: updatedAllocations }
    onChangeAllocation(newClaim)
  }

  return (
    <>
      {claim.allocations.map((allocation, i) => {
        return (
          <div
            key={i}
            className={classNames(
              styles.grid,
              reserves?.length ? null : styles['grid-short']
            )}
          >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span>
              {reserves
                ? allocation.part || findLabelPart(allocation) + '%'
                : null}
            </span>
            <span className={styles.label}>
              <small>{allocation?.label?.abbreviation}</small>
            </span>
            <input
              hidden
              {...register(`claims[${index}].allocations[${i}].auditLabelId`)}
              defaultValue={allocation.label?.id}
            />
            <span>
              <Input
                {...register(`claims[${index}].allocations[${i}].amount`)}
                type="number"
                step="any"
                onBlur={(e) => changeAllocation(e, claim, allocation, 'amount')}
              />
            </span>
            <span>
              <Input
                {...register(`claims[${index}].allocations[${i}].notes`)}
                onBlur={(e) => changeAllocation(e, claim, allocation, 'notes')}
              />
            </span>
          </div>
        )
      })}
    </>
  )
}

export default NestedForm
