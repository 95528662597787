import React, { FC, useMemo } from 'react'
import { Line } from 'react-chartjs-2'
import { ArcElement, Chart as ChartJS, Filler, Legend, Tooltip } from 'chart.js'

import dayjs from 'dayjs'
//import { ActivityCompletionStatus, GetAuditCountByMonthQuery, useGetAuditCountByMonthQuery } from 'generated/graphql'
// import ErrorAlert from 'components/ErrorAlert'
// import useGlobalProgress from 'hooks/useGlobalProgress'
// import useData from 'hooks/useData'
// import notify from 'utils/notify'
// import { Notification } from 'constants/typeConstants'

ChartJS.register(ArcElement, Tooltip, Legend, Filler)

type ActivityCompletionProps = {
  categories: (string | null)[]
  labelMasters: (string | null)[]
}

const ActivityCompletion: FC<ActivityCompletionProps> = ({
  categories,
  labelMasters,
}) => {
  const toDate = dayjs()
  const fromDate = toDate.subtract(12, 'month')

  const labels = useMemo(() => {
    let date = fromDate
    let months = []

    while (date.add(1, 'month').valueOf() <= toDate.add(1, 'month').valueOf()) {
      months.push(date.format("MMM 'YY"))
      date = date.add(1, 'month')
    }

    return months
  }, [fromDate, toDate])

  // const period = useMemo(
  //   () => ({
  //     fromMonth: fromDate.month() + 1,
  //     toMonth: toDate.month() + 1,
  //     fromYear: fromDate.year(),
  //     toYear: toDate.year(),
  //   }),
  //   [fromDate, toDate]
  // )

  // const actualAuditCountQuery = useGetAuditCountByMonthQuery({
  //   variables: {
  //     input: {
  //       period,
  //       activityCompletionStatus: ActivityCompletionStatus.Actual,
  //       categories,
  //       labelMasters
  //     }
  //   },
  //   onError() {
  //     notify(Notification.ERROR, 'There is some error while loading actual completion data')
  //   }
  // })

  // const expectedAuditCountQuery = useGetAuditCountByMonthQuery({
  //   variables: {
  //     input: {
  //       period,
  //       activityCompletionStatus: ActivityCompletionStatus.Expected,
  //       categories,
  //       labelMasters
  //     }
  //   },
  //   onError() {
  //     notify(Notification.ERROR,'There is some error while loading expected to complete data')
  //   }
  // })
  // const actualAuditCountData = useData<GetAuditCountByMonthQuery>({
  //   loading: actualAuditCountQuery.loading,
  //   previousData: actualAuditCountQuery.previousData,
  //   data: actualAuditCountQuery.data
  // })
  // const expectedAuditCountData = useData<GetAuditCountByMonthQuery>({
  //   loading: expectedAuditCountQuery.loading,
  //   previousData: expectedAuditCountQuery.previousData,
  //   data: expectedAuditCountQuery.data
  // })

  // useGlobalProgress(actualAuditCountQuery.loading || expectedAuditCountQuery.loading)

  return (
    <div className="card">
      <div className="card-header">
        Activity Completion <small>by month</small>
      </div>
      <div className="card-body">
        {/* {(actualAuditCountQuery.error || expectedAuditCountQuery.error) && (
          <ErrorAlert>There is no data for the chart</ErrorAlert>
        )}
        {actualAuditCountData && expectedAuditCountData && ( */}
        <Line
          data={{
            labels,
            datasets: [
              {
                label: 'Actual Completion',
                data: [],
                //data: actualAuditCountData.getAuditCountByMonth?.countByMonth,
                backgroundColor: 'rgba(26,179,148,0.5)',
                borderColor: 'rgba(26,179,148,0.7)',
                pointBackgroundColor: 'rgba(26,179,148,1)',
                pointBorderColor: '#fff',
                fill: true,
                tension: 0.4,
              },
              {
                label: 'Expected to Complete',
                data: [],
                //data: expectedAuditCountData.getAuditCountByMonth?.countByMonth,
                backgroundColor: 'rgba(220, 220, 220, 0.5)',
                pointBorderColor: '#fff',
                fill: true,
                tension: 0.4,
              },
            ],
          }}
          options={{
            responsive: true,
          }}
        />
        {/* )} */}
      </div>
    </div>
  )
}

export default ActivityCompletion
