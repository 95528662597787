import { Table } from '@wmgtech/legato'

import styles from './styles.module.scss'

const TableComponent = ({ ...props }: any) => {
  return (
    <Table
      striped="odd"
      rowClassName={props.totals ? `${styles['has-totals']}` : ''}
      columns={[...props.columns]}
      data={[...props.data]}
      rowKey={({ id, name }) => id || name}
      pagination={props.pagination}
    />
  )
}

export default TableComponent
