import { FC, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm, Controller } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { Icon, Input, InputGroup, Slider, Textarea } from '@wmgtech/legato'
import FormSelect from 'components/Form/FormSelect/FormSelect'
import FormDatepicker from 'components/Form/FormDatepicker/FormDatepicker'
import LoadingButton from 'components/LoadingButton/LoadingButton'
import {
  ReservedAmountReasonsQuery,
  useDeafultRateQuery,
  useReservedAmountReasonsQuery,
  ReservedAmountsHistoryQuery,
  AuditQuery, useAuditSubcategoriesListQuery, AuditSubcategoriesListQuery
} from 'generated/graphql'
import useData from 'hooks/useData'
import convertToList from 'utils/convertToList'
import FormCondition from 'components/Form/FormCondition'
import {
  ReserveReason,
  OTHER_REASON,
  RATE_CHANGE_REASON, Notification,
} from 'constants/typeConstants'
import { reserveCalculations } from 'utils/reserveCalculations'
import { IReserveForm } from 'interfaces/ReserveFormInterface'
import convertDateToString from 'utils/convertDateToString'
import currencyFormat from 'utils/currencyFormat'
import styles from './styles.module.scss'
import NestedSelectWithTag from 'components/Form/NestedSelectWithTag';
import getAuditSubcategories from 'utils/getAuditSubcategories';
import notify from 'utils/notify';

interface IReserveFormProps {
  onSubmit: (data: IReserveForm) => void
  defaultValues?: any
  ramount?: string | null
  reservesData: ReservedAmountsHistoryQuery | undefined
  auditData: AuditQuery | undefined
}

interface AuditType {
  id: string;
  name: string;
  subcategoryId: string;
}

const INITIAL_AMOUNT = '0.00'

const ReserveForm: FC<IReserveFormProps> = ({
  onSubmit,
  defaultValues,
  reservesData,
                                              auditData,
}) => {
  const [rateCalc, setRateCalc] = useState({ ...defaultValues })
  const { labelId, subcategoryId, ramountId } = useParams<{
    labelId: string
    subcategoryId: string
    ramountId: string
  }>()
  const [prevAmount, setPrevAmount] = useState(INITIAL_AMOUNT)
  const [defaultRate, setDefaultRate] = useState('0')
  const [calculatedSum, setCalculatedSum] = useState(0);

  const getReserveReasons = useReservedAmountReasonsQuery({
    onCompleted(response) {
      if (!ramountId) {
        const reasonId = response?.reservedAmountReasons![0]!.id || '1'
        setValue('reasonId', reasonId)
      }
    },
  })
  const ReserveReasonsData = useData<ReservedAmountReasonsQuery>({
    loading: getReserveReasons.loading,
    previousData: getReserveReasons.previousData,
    data: getReserveReasons.data,
  })

  const reasonsList = convertToList(ReserveReasonsData?.reservedAmountReasons)

  useDeafultRateQuery({
    variables: {
      auditSubcategoryId: subcategoryId || '',
      labelId: labelId || '',
    },
    onCompleted(response) {
      const defaultRate = response?.defaultRate?.rate || '0'
      setValue('rate', defaultRate)
      setDefaultRate(defaultRate)
    },
      onError() {
    notify(
        Notification.ERROR,
        'There is no default rate for this reserve'
    )
  },
    skip: !!ramountId && defaultValues.reasonId !== '1',
  })

  const reservedAmountsList = useMemo(() => {
    const reserves = reservesData?.reservedAmountsHistory?.filter(
      (r) => r?.label.id === labelId && r?.auditSubcategory.id === subcategoryId
    )
    const newList: any[] = []
    if (reserves?.length) {
      for (let i = 0; i < reserves.length; i++) {
        newList.push({ ...reserves[i] })
      }
    }
    newList?.sort((a, b) => (b?.reservedDate).localeCompare(a?.reservedDate))
    return newList
  }, [reservesData?.reservedAmountsHistory, labelId, subcategoryId])

  const methods = useForm({
    defaultValues: defaultValues
  })

  const { register, watch, getValues, setValue, control } = methods;
  const watchReason = watch('reasonId')
  const watchDate = watch('reservedDate')

  const priorSettlement = watch('priorSettlement');
  const priorQuarters = watch('priorQuarters');
  const currentQuarters = watch('currentQuarters');

  const isSimpleCalc = watch('simple_calc');


  const getNum = (value: string | number): number => {
    const num = Number(value);
    return isNaN(num) ? 0 : num;
  };

  // Calculate the results
  const { perQtr, total } = useMemo(() => {
    const sett_amt = getNum(priorSettlement);
    const prev_qtrs = getNum(priorQuarters);
    const curr_qtrs = getNum(currentQuarters);

    if (prev_qtrs === 0) {
      return { perQtr: '0.00', total: '0.00' };
    }

    const per_qtr = getNum((getNum(sett_amt) / getNum(prev_qtrs)).toFixed(2));
    const total = getNum((per_qtr * getNum(curr_qtrs)).toFixed(2));

    return { perQtr: per_qtr, total };
  }, [priorSettlement, priorQuarters, currentQuarters]);


  useEffect(() => {
    const subscription = watch((data) => {
      const { earnings, rate, fpp, otherAmount, additionalAmount } = data
      setRateCalc({ earnings, rate, fpp, otherAmount, additionalAmount })
      const sum = (Number(earnings) * Number(rate) / 100) + Number(fpp) + Number(otherAmount);
      setCalculatedSum(sum);
    })
    return () => subscription.unsubscribe()
  }, [watch]);

  const calculateResAmount = () => {
    return (
      reserveCalculations(watchReason, rateCalc, getValues('previousAmount')) ||
      0
    )
  }

  const calculateType = () => {
    if (ramountId)
    { const reserve = reservesData?.reservedAmountsHistory?.find(
        (r) => r?.id === ramountId
    );
    return reserve?.auditSubcategory?.name || '';}
    else{

      if (!auditData?.audit?.types || !Array.isArray(auditData?.audit.types) ||auditData?.audit.types.length === 0) {
        return '';
      }

      return auditData.audit.types
          .filter((type): type is AuditType => type !== undefined && type !== null )
          .map(type => type?.name || '')
          .join(', ');
    }
  };

  const calculateTypeForSelect = (): any[] => {
    if (ramountId) {
      const reserve = reservesData?.reservedAmountsHistory?.find(
          (r) => r?.id === ramountId
      );
      if (reserve?.auditSubcategory) {

        return [{
          key: reserve.auditSubcategory.name || '',
          id: reserve.auditSubcategory.id || '',
          title: reserve.auditSubcategory.name || ''
        }];
      }
    } else

    // This part will run for both 'new' and when no reserve is found for edit

    if (auditData?.audit?.types && auditData.audit.types.length > 0) {

      return auditData.audit.types.map((subcategory: any) => {

        return {
          id: subcategory.subcategoryId || '',
          key: subcategory.name || '',
          title: subcategory.name || ''
        };
      });


  }
    return []; // Return empty array if no data is available
  };

  const getAuditSubcategoriesListQuery = useAuditSubcategoriesListQuery()
  const AuditSubcategoriesData = useData<AuditSubcategoriesListQuery>({
    loading: getAuditSubcategoriesListQuery.loading,
    previousData: getAuditSubcategoriesListQuery.previousData,
    data: getAuditSubcategoriesListQuery.data,
  })

  const auditSubcategories = getAuditSubcategories(
      AuditSubcategoriesData?.auditCategories
  )



  useEffect(() => {
    if (reservedAmountsList?.length) {
      for (const reserve of reservedAmountsList) {
        const reserveDate = convertDateToString(getValues('reservedDate'))
        if (reserveDate && reserve?.reservedDate < reserveDate) {
          const previousReserveAmount = reserve?.amount ?? INITIAL_AMOUNT
          setPrevAmount(previousReserveAmount)
          if (!ramountId) {
            setValue('previousAmount', previousReserveAmount)
          }
          return
        } else {
          setPrevAmount(INITIAL_AMOUNT)
          if (!ramountId) {
            setValue('previousAmount', INITIAL_AMOUNT)
          }
        }
      }
    }
  }, [watchDate, getValues, setValue, reservedAmountsList, ramountId])

  const onReasonChange = (reason: string) => {
    methods.reset({
      ...defaultValues,
      reasonId: reason,
      reservedDate: watchDate,
    })
    if (ReserveReason.DEFAULT_RATE === reason) {
      setValue('rate', defaultRate)
    }
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
        <header className={styles['form-header']}>
          <Icon name="info-circle" className="fa-2x"></Icon>
          <h4>General Information</h4>
        </header>
        <div className={styles.body}>
          <div className="form-group form-group-four">
            <FormDatepicker
              placeholder="Select a Date"
              name="reservedDate"
              label="Date"
              initialDate={defaultValues?.reservedDate}
              rules={{ required: true }}
              message="Reserve date can't be blank"
              disabled={!!ramountId}
            />
            <InputGroup label="Change Reason">
              <FormSelect
                name="reasonId"
                list={reasonsList ?? []}
                placeholder="Select a reason"
                onChange={onReasonChange}
                disabled={!!ramountId}
              />
            </InputGroup>
            <label><b>Audit Type:</b> <br /> {calculateType()}</label>
            <NestedSelectWithTag
                isClearable
                selectLabel="Audit Subcategory"
                name="subcategoryIds"
                list={auditSubcategories}
                value={calculateTypeForSelect()}

                placeholder="Select one or more types"
                validation={{
                  required: true,
                  message: "Subcategory array can't be blank",
                }}
            />

            <input type="hidden" {...register('auditType')  } value={calculateType()} />
            <FormCondition fieldName="reasonId" condition={OTHER_REASON}>
            <InputGroup label="Type of Calculation">
              <Controller
                  name="simple_calc"
                  control={control}
                  render={({ field }) => (
                      <Slider
                          colorTypeOff="secondary-grey"
                          colorTypeOn="success"
                          label={isSimpleCalc ? 'Simple Calc On' : 'Simple Calc Off'}
                          size="lg"
                          checked={field.value}
                          onChange={(newValue) => {
                            field.onChange(newValue);
                          }}
                      />
                  )}
              />
            </InputGroup>
              </FormCondition>

            <div></div>
          </div>
          <div className="dashes"></div>
          <div className={styles.calculation}>
            <header className={styles['form-header']}>
              <Icon name="calculator" className="fa-2x"></Icon>
              <h4>Reserve Calculation</h4>
            </header>
          </div>

          { isSimpleCalc   && (  <FormCondition fieldName="reasonId" condition={OTHER_REASON || RATE_CHANGE_REASON}>
            <div>
              <div className="form-group form-group-five">
                <InputGroup label="Previous Reserve">
                  <Input
                    {...register('previousAmount')}
                    name="previousAmount"
                    className={
                      (+getValues('previousAmount')).toFixed(2) === prevAmount
                        ? ''
                        : styles.mismatch
                    }
                    type="number"
                    disabled={!ramountId}
                  />
                  <p>${prevAmount}</p>
                </InputGroup>
                <InputGroup label="Amount">
                  <Input
                    {...register('additionalAmount')}
                    name="additionalAmount"
                    type="number"
                    defaultValue={defaultValues?.additionalAmount}
                    step="any"
                  />
                </InputGroup>
                <InputGroup label="Reserve Amount">
                  <div>{currencyFormat(+calculateResAmount())}</div>
                </InputGroup>
                <div></div>
                <div></div>
              </div>
            </div>
          </FormCondition>)}

              <FormCondition fieldName="reasonId" condition={ RATE_CHANGE_REASON}>
            <div className="form-group form-group-five">
              <InputGroup label="Earnings">
                <Input
                  {...register('earnings')}
                  name="earnings"
                  type="number"
                  step="any"
                />
              </InputGroup>
              <InputGroup
                label={
                  watchReason === ReserveReason.DEFAULT_RATE
                    ? 'Default Rate'
                    : 'Rate'
                }
              >
                <Input
                  {...register('rate')}
                  name="rate"
                  type="number"
                  disabled={watchReason === ReserveReason.DEFAULT_RATE}
                  step="any"
                />
              </InputGroup>
              <InputGroup label="FPP Amount">
                <Input
                  {...register('fpp')}
                  name="fpp"
                  type="number"
                  step="any"
                />
              </InputGroup>

              <InputGroup label="Other Amounts">
                <Input
                  {...register('otherAmount')}
                  name="otherAmount"
                  type="number"
                  step="any"
                />
              </InputGroup>
              <InputGroup label="Reserve Amount">
                <div>{currencyFormat(calculatedSum)}</div>
              </InputGroup>
            </div>
          </FormCondition>

          { (calculateType().toLowerCase().includes('union') && !isSimpleCalc) && (
            <FormCondition fieldName="reasonId" condition={OTHER_REASON || RATE_CHANGE_REASON}>
              <div className="form-group form-group-five">
                <InputGroup label="Prior Settlement">
                  <Input {...register('priorSettlement')} type="number" step="any" />
                </InputGroup>
                <InputGroup label="Prior Quarters">
                  <Input {...register('priorQuarters')} type="number" />
                </InputGroup>
                <InputGroup label="Prior Per Qtr">
                  <Input
                      {...register('priorPerQtr')}
                      type="number"
                      disabled
                      value={perQtr.toString()}
                  />
                </InputGroup>
                <InputGroup label="Current Quarters">
                  <Input {...register('currentQuarters')} type="number" />
                </InputGroup>
                <InputGroup label="Reserve Amount">
                  <div>{currencyFormat(getNum(total))}</div>
                </InputGroup>
              </div>
            </FormCondition> )}

          <div className="col-md-8">
            <label>Full Reserve Calculation:</label>
            {!calculateResAmount() && (
              <p>
                <b>Please enter values to calculateResAmount</b>
              </p>
            )}

            <FormCondition fieldName="reasonId" condition={OTHER_REASON}>
              <p>

                  <strong>
                    {getValues('previousAmount')
                      ? currencyFormat(getValues('previousAmount'))
                      : '$' + INITIAL_AMOUNT}{' '}
                    + {currencyFormat(getValues('additionalAmount'))}=
                    {currencyFormat(+calculateResAmount())}
                  </strong>
              </p>
              </FormCondition>


            <FormCondition fieldName="reasonId" condition={RATE_CHANGE_REASON}>
                    <p><strong>
                    ({currencyFormat(getValues('earnings'))} *{' '}
                    {getValues('rate')}%) + {currencyFormat(getValues('fpp'))} +
                    {currencyFormat(getValues('otherAmount'))} =
                    {currencyFormat(calculatedSum)}
                  </strong>
                  </p>
              </FormCondition>




          </div>

          {( calculateType().toLowerCase().includes('union') && !isSimpleCalc)  &&( <p>

                 <strong>
            ({currencyFormat(getValues('priorSettlement'))} /{' '}
          {getValues('priorQuarters')}) * {currencyFormat(getValues('currentQuarters'))}
           =
          {currencyFormat(getNum(total))}
        </strong> </p>
        )}

          <div className="dashes"></div>
          <header className={styles['form-header']}>
            <Icon name="comment" className="fa-2x"></Icon>
            <h4> Explanation</h4>
          </header>
          <div className="form-group">
            <InputGroup label="Explanation">
              <Textarea
                {...register('notes')}
                name="notes"
                placeholder="Please provide an explanation for the change."
              />
            </InputGroup>
          </div>
        </div>
        <div className={styles.footer}>
          <LoadingButton type="submit" colorType={'primary'}>
            {ramountId ? 'Update Reserve' : 'Create Reserve'}
          </LoadingButton>
        </div>
      </form>
    </FormProvider>
  )
}

export default ReserveForm
