import { FC } from 'react'
import { Modal } from '@wmgtech/legato'

import RevisionForm from '../RevisionForm'
import styles from './styles.module.scss'

interface IProps {
  show: boolean
  onClose: () => void
  onSubmit: (data: any) => void
}

const AddRevisionModal: FC<IProps> = ({ show, onClose, onSubmit }) => {
  return (
    <Modal
      className={styles.modal}
      isOpen={show}
      title={
        <div className={styles['modal-title']}>
          <h2>Add New Claim Revision</h2>
        </div>
      }
      onClose={onClose}
      shouldCloseOnOverlayClick
    >
      <RevisionForm onClose={onClose} onSubmit={onSubmit} />
    </Modal>
  )
}

export default AddRevisionModal
