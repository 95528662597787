import { FC } from 'react'
import { Modal } from '@wmgtech/legato'

import OfferDetailsForm from '../OfferDetailsForm'
import styles from './styles.module.scss'

interface IProps {
  show: boolean
  onClose: () => void
  onSubmit: (data: any) => void
}

const OfferModal: FC<IProps> = ({ show, onClose, onSubmit }) => {
  const defaultValues = {
    flatAmount: '0',
    advancedAmount: '0',
    otherAmount: '0',
  }

  return (
    <Modal
      className={styles.modal}
      isOpen={show}
      title={
        <div className={styles['modal-title']}>
          <h2>Add New Offer</h2>
        </div>
      }
      onClose={onClose}
      shouldCloseOnOverlayClick
    >
      <OfferDetailsForm
        defaultValues={defaultValues}
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </Modal>
  )
}

export default OfferModal
