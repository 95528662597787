import { FC } from 'react'

import currencyFormat from 'utils/currencyFormat'
import { OfferStatuses } from 'constants/typeConstants'
import styles from './styles.module.scss'

const AuditAnalysisTable: FC<any> = ({ types, labelsOverview, auditType }) => {
  const calculateTotalOffer = (label: any) => {
    return (
      Number(label.offer.amount.flat) +
      Number(label.offer.amount.advanced) +
      Number(label.offer.amount.other)
    )
  }

  const calculateGrandTotalOffer = () => {
    return labelsOverview?.reduce((acc: any, cur: any) => {
      return acc + calculateTotalOffer(cur)
    }, 0)
  }

  const calculateTotals = (amount: string, i?: any) => {
    return labelsOverview?.reduce((acc: any, cur: any) => {
      if (i) return acc + Number(cur[amount].amount[i])
      return acc + Number(cur[amount].amount)
    }, 0)
  }

  const settlementAmtTotal = labelsOverview?.reduce((acc: any, cur: any) => {
    if (cur.offer.status.id === OfferStatuses.SETTLED)
      return acc + Number(cur.offer.amount.flat)
    return 0
  }, 0)

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th>
            {types.length === 1
              ? types[0].name
              : auditType
              ? auditType.name
              : 'Consolidated'}
          </th>
          {labelsOverview?.map((l: any) => (
            <th key={l.id}>{l.abbreviation}</th>
          ))}
          <th>Grand Total</th>
        </tr>
      </thead>
      <tbody>
        <tr className={styles.grey}>
          <td>Total Claimed:</td>
          {labelsOverview?.map((l: any) => (
            <td key={l.id}>{currencyFormat(l.claims.amount)}</td>
          ))}
          <td>
            <b>{currencyFormat(calculateTotals('claims'))}</b>
          </td>
        </tr>
        <tr>
          <td>Total Flat:</td>
          {labelsOverview?.map((l: any) => (
            <td key={l.id}>{currencyFormat(l.offer.amount.flat)}</td>
          ))}
          <td>
            <b>{currencyFormat(calculateTotals('offer', 'flat'))}</b>
          </td>
        </tr>
        <tr>
          <td>Total Advance:</td>
          {labelsOverview?.map((l: any) => (
            <td key={l.id}>{currencyFormat(l.offer.amount.advanced)}</td>
          ))}
          <td>
            <b>{currencyFormat(calculateTotals('offer', 'advanced'))}</b>
          </td>
        </tr>
        <tr>
          <td>Total Other:</td>
          {labelsOverview?.map((l: any) => (
            <td key={l.id}>{currencyFormat(l.offer.amount.other)}</td>
          ))}
          <td>
            <b>{currencyFormat(calculateTotals('offer', 'other'))}</b>
          </td>
        </tr>
        <tr className={styles.grey}>
          <td>Total Offer:</td>
          {labelsOverview?.map((l: any) => (
            <td key={l.id}>{currencyFormat(calculateTotalOffer(l))}</td>
          ))}
          <td>
            <b>{currencyFormat(calculateGrandTotalOffer())}</b>
          </td>
        </tr>
        <tr className={styles.grey}>
          <td>Total Reserve:</td>
          {labelsOverview?.map((l: any) => (
            <td key={l.id}>{currencyFormat(l.reserved.amount)}</td>
          ))}
          <td>
            <b>{currencyFormat(calculateTotals('reserved'))}</b>
          </td>
        </tr>
        <tr>
          <td>
            <small>
              <i>Less Flat:</i>
            </small>
          </td>
          {labelsOverview?.map((l: any) => (
            <td key={l.id}>$0.00</td>
          ))}
          <td>
            <small>
              <i>$0.00</i>
            </small>
          </td>
        </tr>
        <tr>
          <td>
            <small>
              <i>Settlement Amt:</i>
            </small>
          </td>
          {labelsOverview?.map((l: any) => (
            <td key={l.id}>
              {l.offer.status?.id === OfferStatuses.SETTLED
                ? currencyFormat(l.offer.amount.flat)
                : '$0.00'}
            </td>
          ))}
          <td>
            <small>
              <i>{currencyFormat(settlementAmtTotal)}</i>
            </small>
          </td>
        </tr>
        <tr>
          <td>Net Reserve Position:</td>
          {labelsOverview?.map((l: any) => (
            <td key={l.id}>{currencyFormat(l.reserved.amount)}</td>
          ))}
          <td>
            <b>{currencyFormat(calculateTotals('reserved'))}</b>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default AuditAnalysisTable
