type ColumnTransformer = (col: Record<string, unknown>) => string

type Column = {
  title: string
  quoted?: boolean
  transform: ColumnTransformer
}

type Config = {
  header: boolean
  delimiter?: string
  columns: Column[]
}

export async function CSVStringify(cols: Array<Record<string, unknown>>, { header, delimiter, columns }: Config): Promise<string> {
  if (!delimiter) delimiter = ','
  const delim = `sep=${delimiter}\n`

  const lines: string[] = []
  for (const col of cols) {
    const line: string[] = []
    for (const column of columns) {
      line.push(column.quoted ? `"${column.transform(col)}"` : column.transform(col))
    }
    lines.push(line.join(delimiter))
  }
  return `${delim}${header ? getHeaderLine(delimiter, columns) : ''}${lines.join('\n')}`
}

const getHeaderLine = (delimiter: string, columns: Column[]): string => {
  return columns.map(c => c.quoted ? `"${c.title}"` : c.title).join(delimiter) + '\n'
}
