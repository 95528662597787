import React, { FC, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'

import {
  LabelDocument,
  LabelsListDocument,
  useLabelQuery,
  useUpdateLabelMutation,
} from 'generated/graphql'
import Spinner from 'components/Spinner'
import notify from 'utils/notify'
import { Notification } from 'constants/typeConstants'
import LabelForm from '../LabelForm'

const EditLabel: FC = () => {
  const [defaultValues, setDefaultValues] = useState({})
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const [updateLabel, { loading: updating }] = useUpdateLabelMutation()

  const { data, loading } = useLabelQuery({
    variables: { id: id! },
    onCompleted() {
      setDefaultValues({
        id: data?.label?.id,
        name: data?.label?.name,
        abbreviation: data?.label?.abbreviation,
        description: data?.label?.description,
      })
    },
    onError() {
      notify(
        Notification.ERROR,
        'There is some error while loading the Label data'
      )
    },
  })

  if (loading || isEmpty(defaultValues)) {
    return <Spinner name="circle-notch" />
  }

  const updateLabelFunc = (data: any) => {
    updateLabel({
      variables: { input: { ...data, id } },
      update(cache: any, MutationResult: any) {
        cache.writeQuery({
          query: LabelDocument,
          variables: {
            id,
          },
          data: {
            label: {
              ...MutationResult.data?.updateLabel,
            },
          },
        })
      },
      refetchQueries: [
        {
          query: LabelsListDocument,
        },
      ],
    })
      .then(() => history.push('/labels'))
      .catch(() =>
        notify(
          Notification.ERROR,
          'There is some error while updating the label'
        )
      )
  }

  return (
    <div>
      <header className="page-header">
        <h2>Label: {data?.label?.name}</h2>
      </header>
      <div className="controls-container">
        <h3>Edit Label</h3>
        <hr className="divider" />
        <LabelForm
          action={'Update'}
          defaultValues={defaultValues}
          onSubmit={updateLabelFunc}
          loading={updating}
        />
      </div>
    </div>
  )
}

export default EditLabel
