import { useMemo } from 'react'

type DataWithUndefined<T> = T | undefined

type UseDataProps<T> = {
  loading: boolean
  previousData: DataWithUndefined<T>
  data: DataWithUndefined<T>
}

const useData = <T>({ loading, previousData, data }: UseDataProps<DataWithUndefined<T>>): DataWithUndefined<T> => {
  return useMemo(() => {
    return loading && previousData ? previousData : data
  }, [loading, data, previousData])
}

export default useData
