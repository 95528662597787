import { FC } from 'react'
import { Icon } from '@wmgtech/legato'
import convertDateToAuditPeriodFormat from 'utils/convertDateToAuditPeriodFormat'

interface IHeaderRowInfo {
  auditData: any
}

const HeaderRowInfo: FC<IHeaderRowInfo> = ({ auditData }) => {
  return (
    <div className="col-sm-7 row">
      <div className="col-sm-1">
        <Icon name="book" prefix="fas" />
      </div>
      <div className="col-sm-4 row">
        <div className="col-sm-4">
          <strong>Audit:</strong>
        </div>
        <div className="col-sm-8">{auditData?.audit?.reportName}</div>
      </div>
      <div className="col-sm-4 row">
        <div className="col-sm-4">
          <strong>NOI:</strong>
        </div>
        <div className="col-sm-8">{auditData?.audit?.noticeDate ?? 'N/A'}</div>
      </div>
      <div className="col-sm-4 row">
        <div className="col-sm-4">
          <strong>Period: </strong>
        </div>
        <div className="col-sm-8">
          {convertDateToAuditPeriodFormat(auditData?.audit?.startDate)} -&nbsp;
          {convertDateToAuditPeriodFormat(auditData?.audit?.endDate)}
        </div>
      </div>
    </div>
  )
}

export default HeaderRowInfo
