import { FC } from 'react'
import { Icon, Modal } from '@wmgtech/legato'

import AuditActivityForm from '../AuditActivityForm/AuditActivityForm'

interface IModalProps {
  show: boolean
  activity: any
  onClose: () => void
  onSubmit: (data: any) => void
}

const convertToDate = (date: string | null) => {
  const newDate = date ? new Date(date) : null
  return newDate
}

const EditActivityModal: FC<IModalProps> = ({
  show,
  activity,
  onClose,
  onSubmit,
}) => {
  const defaultValues = {
    actualDate: convertToDate(activity?.actualDate),
    manuallyProjectedDate: convertToDate(activity?.manuallyProjectedDate),
    typeId: activity?.type.id,
  }

  return (
    <Modal
      className="modal"
      isOpen={show}
      title={
        <div className="modal-title">
          <Icon className="modal-title-icon" name="calendar-alt" />
          <h2>Edit Activity</h2>
          <p>
            This is where you will edit an activity - either an ACTUAL date or a
            PROJECTED date
            <br />
            (actual dates should not be in the future).
          </p>
        </div>
      }
      onClose={onClose}
      shouldCloseOnOverlayClick
    >
      <AuditActivityForm
        onClose={onClose}
        onSubmit={onSubmit}
        defaultValues={defaultValues}
      ></AuditActivityForm>
    </Modal>
  )
}

export default EditActivityModal
