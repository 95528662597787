import React, { FC, ReactNode } from 'react'

import styles from './styles.module.scss'

export type AuditCardProps = {
  title: string
  badge: ReactNode
  count: number
  description: string
}

const AuditCard: FC<AuditCardProps> = ({ title, badge, count, description }) => {
  return (
    <div className="card">
      <div className="card-header">
        {title} {badge}
      </div>
      <div className="card-body">
          <span className={styles.count}>{count}</span>
          <small>{description}</small>
        </div>
    </div>
  )
}

export default AuditCard
