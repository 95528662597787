import { Notification, OTHER_REASON, ReserveReason } from 'constants/typeConstants'
import notify from './notify'
import { IReserveForm } from 'interfaces/ReserveFormInterface'

interface IDataFromInput {
  earnings: string | number
  fpp: string | number
  otherAmount: string | number
  rate: string | number
  additionalAmount?: string | number
}

interface IDataFromInputForReserve {
  earnings: string | number
  fpp: string | number
  otherAmount: string | number
  rate: string | number
  additionalAmount?: string | number
  currentQuarters?: string | number
  priorQuarters?: string | number
  priorSettlement? : string | number

}

export const reserveCalculationsForReserve = (reasonId: string, dataFromInput: IDataFromInputForReserve, previousAmount: string, auditType: string,simpleCalc: string) => {
  if (!auditType.toLowerCase().includes('union') && !simpleCalc) {
    return (
        (+dataFromInput.earnings * +dataFromInput.rate) / 100 +
        +dataFromInput.fpp +
        +dataFromInput.otherAmount
    ).toFixed(2)
  }else if(auditType.toLowerCase().includes('union') && !simpleCalc) {
    const sett_amt = dataFromInput.priorSettlement;
    const prev_qtrs = dataFromInput.priorQuarters;
    const curr_qtrs = dataFromInput.currentQuarters;

    if (prev_qtrs === 0) {
      return 0;
    }
    const per_qtr = Number((Number(sett_amt) / Number(prev_qtrs)).toFixed(2));
    const total = Number((per_qtr * Number(curr_qtrs)).toFixed(2));
    return total;
  }else {
    if (!previousAmount) {
      previousAmount = '0'
    }
    return (+previousAmount + +dataFromInput.additionalAmount!).toFixed(2)
  }

}

export const reserveCalculations = (reasonId: string, dataFromInput: IDataFromInput, previousAmount: string) => {
  switch (reasonId) {
    case ReserveReason.DEFAULT_RATE:
    case ReserveReason.CUSTOM_RATE:
      return (
          (+dataFromInput.earnings * +dataFromInput.rate) / 100 +
          +dataFromInput.fpp +
          +dataFromInput.otherAmount
      ).toFixed(2)
    case ReserveReason.TRANSFER_AMOUNT:
    case ReserveReason.CHANGE_AMOUNT:
    case ReserveReason.ADVANCE:
    case ReserveReason.SETTLEMENT:
      if (!previousAmount) {
        previousAmount = '0'
      }
      return (+previousAmount + +dataFromInput.additionalAmount!).toFixed(2)
  }
}

export const createRAmountObject = (data: IReserveForm) => {
  let ramount: {}
  if (!data.auditType.toLowerCase().includes('union') && !data.simple_calc) {
    ramount = {
      earnings: data.earnings || '0',
      rate: data.rate || '0',
      fpp: data.fpp || '0',
      otherAmount: data.otherAmount || '0',
    }
  }else if(data.auditType.toLowerCase().includes('union') && !data.simple_calc){
    ramount = {
      priorSettlement: data.priorSettlement || '0',
      priorQuarters: data.priorQuarters || '0',
      currentQuarters: data.currentQuarters || '0',
    }
  }
  else {
    ramount = {
      additionalAmount: data.additionalAmount || '0',
      previousAmount: (+data.previousAmount).toFixed(2).toString() || '0',
    }
  }
  return ramount
}

export const getCalculationDetails = (array: any[] | null | undefined, isHistoryList = false) => {
  const newList = []
  if (Array.isArray(array)) {
    for (let i = 0; i < array.length; i++) {
      try {
        let details = JSON.parse(array[i]?.calculationDetails)
        newList.push({ ...array[i], ...details, key: i })
      } catch (e) {
        notify(
          Notification.ERROR,
          'There is some error while getting reserves'
        )
      }
    }
    newList.sort((a, b) => (b?.reservedDate).localeCompare(a?.reservedDate))
    return isHistoryList ? findMismatchedReserves(newList) : newList
  }
}

const findMismatchedReserves = (list: any[]) => {
  const newResAmountList = []
  for (let i = list.length - 1; i >= 0; i--) {
    let mismatched = false
    if (OTHER_REASON.includes(list[i].reason.id)) {
      if (list[i + 1]) {
        mismatched = +list[i + 1].amount !== +list[i].previousAmount
        newResAmountList.push({ ...list[i], mismatched })
      } else {
        newResAmountList.push(list[i])
      }
    } else {
      newResAmountList.push(list[i])
    }
  }
  return newResAmountList.sort((a, b) => (b?.reservedDate).localeCompare(a?.reservedDate))
}

export const getTotalReserveAmountByAuditType = (reservesData: any) => {
  if (!reservesData?.length) return []
  const totals: any = {}

  for (const reserve of reservesData) {
    const auditTypeId = reserve?.auditSubcategory?.id as string
    if (!totals[auditTypeId]) {
      totals[auditTypeId] = {
        total: 0,
        auditLabels: [],
      }
    }
    totals[auditTypeId].total += +reserve?.amount!
    totals[auditTypeId].auditLabels.push({
      labelId: reserve?.label?.id,
      amount: reserve?.amount,
    })
  }

  const result = []
  for (const key in totals) {
    const row = {
      auditTypeId: key,
      total: totals[key].total,
      labels: totals[key].auditLabels.map((item: any) => ({
        labelId: item.labelId,
        amount: item.amount,
        part: (+item.amount === 0 || +totals[key].total === 0) ? '0.00' :
          ((item.amount / totals[key].total) * 100).toFixed(2),
      })),
    }
    result.push(row)
  }

  return result
}
