import React, { FC, ReactNode } from 'react'

import currencyFormat from 'utils/currencyFormat'
import styles from './styles.module.scss'

type BalanceSummaryItemProps = {
  icon: ReactNode
  label: string
  price: number
}

const BalanceSummaryItem: FC<BalanceSummaryItemProps> = ({
  icon,
  label,
  price,
}) => {
  return (
    <div className={styles.container}>
      <dt className={styles.icon}>{icon}</dt>
      <dt>{label}</dt>
      <dd className={styles.sum}>{currencyFormat(price)}</dd>
    </div>
  )
}

export default BalanceSummaryItem
