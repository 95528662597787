import React, { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { UserClaims } from '@okta/okta-auth-js/lib/types'
import { useWindowSize } from 'react-use'
import { Dropdown, Button, IIconProps } from '@wmgtech/legato'

import MenuItem from 'components/MainLayout/components/Sidebar/components/MenuItem'
import logo from 'assets/images/Audit_Logo_Inverse.png'
import styles from './styles.module.scss'

type SidebarProps = {
  sidebarExpanded: boolean
  userInfo: UserClaims | null
  onLogOut: () => void
}

const Sidebar: FC<SidebarProps> = ({ sidebarExpanded, userInfo, onLogOut }) => {
  const { width } = useWindowSize()
  const isMobile = width < 576
  const showText = sidebarExpanded && !isMobile

  const menuItems = [
    {
      label: 'My Dashboard',
      icon: 'chart-area',
      to: '/home/dashboard',
    },
    {
      label: 'My Audits',
      icon: 'search-plus',
      to: '/audits',
    },
    {
      label: 'Admin Controls',
      icon: 'wrench',
      to: '/home/admin_controls',
    },
    {
      label: 'Exec Dashboard',
      icon: 'chart-pie',
      to: '/super_dashboard',
    },
  ]

  return (
    <div
      className={`${styles.wrapper} ${
        sidebarExpanded ? styles['wrapper-shown'] : styles['wrapper-hidden']
      }`}
    >
      {showText && (
        <div className={styles.header}>
          <NavLink className={styles.logo} to="/">
            <img width="125px" src={logo} alt="Audit TraX" />
          </NavLink>

          {userInfo && (
            <Dropdown
              className={styles['dropdawn-list']}
              list={[{ key: '1', title: 'Log Out' }]}
              onSelect={() => onLogOut()}
            >
              <Button
                className={styles.dropdown}
                label={userInfo.name}
              ></Button>
            </Dropdown>
          )}
        </div>
      )}

      {!showText && <div className={styles['short-logo']}>WMG</div>}

      <nav className={styles.navigation}>
        {menuItems.map((item) => (
          <MenuItem
            key={item.label}
            label={item.label}
            icon={item.icon as IIconProps['name']}
            to={item.to}
            showText={showText}
          />
        ))}
      </nav>
    </div>
  )
}

export default Sidebar
