export interface IOverviewItem {
  labels: ILabel[]
}

export interface ILabel {
  id: string
  labelId?: string
  name: string
  earnings: number
  reserved: {
    amount: number
  }
  claims: {
    amount: number
  }
  offer: {
    amount: {
      flat: number
      advanced: number
      other: number
    }
    status?: string
  }
}

export const getOverviewValues = (overviewItems: any[]): ILabel[] => {
  const labelsOverview: ILabel[] = []
  if (overviewItems) {
    for (let i of overviewItems) {
      for (let label of i?.labels!) {
        const index = labelsOverview.findIndex(
          (l: ILabel) => l.name === label?.name
        )
        if (index === -1) {
          labelsOverview.push(label)
        } else {
          const claims = { amount: getSum(label, labelsOverview, index, 'claims') }
          const reserved = { amount: getSum(label, labelsOverview, index, 'reserved') }

          const offer = {
            amount: {
              flat: getSum(label, labelsOverview, index, 'offer', 'flat'),
              advanced: getSum(label, labelsOverview, index, 'offer', 'advanced'),
              other: getSum(label, labelsOverview, index, 'offer', 'other')
            },
            status: labelsOverview[index].offer.status,
          }

          const newLabel = { ...labelsOverview[index], claims, offer, reserved }
          labelsOverview[index] = newLabel
        }
      }
    }
  }
  return labelsOverview
}

const getSum = (label: any, labelsOverview: any, index: number, field: string, extra?: string) => {
  if (extra) {
    return Number(label?.[field]?.amount?.[extra]) +
      Number(labelsOverview[index][field]?.amount?.[extra])
  }
  return Number(label?.[field]?.amount) +
    Number(labelsOverview[index][field]?.amount)
}
