import { FC } from 'react'
import { InputGroup, Textarea } from '@wmgtech/legato'
import { FormProvider, useForm } from 'react-hook-form'

import FormFooter from 'components/Form/FormFooter'

interface IActivityPayload {
  defaultValues: { comment: string }
  activity: any
  onClose: () => void
  onSubmit: (data: any) => void
}

const ActivityCommentForm: FC<IActivityPayload> = ({
  defaultValues,
  onClose,
  onSubmit,
  activity,
}) => {
  const methods = useForm({ defaultValues: defaultValues })
  const { register } = methods

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
        <div className="modal-body">
          <InputGroup label="Comment">
            <Textarea
              {...register('comment')}
              name="comment"
              placeholder={`Comment on Activity: ${activity?.type?.activityNumber} | ${activity?.type?.name}`}
            />
          </InputGroup>
        </div>
        <FormFooter
          className="modal"
          cancel="Close"
          onClick={onClose}
          submit={
            defaultValues.comment
              ? 'Update Activity Comment'
              : 'Create Activity Comment'
          }
        />
      </form>
    </FormProvider>
  )
}

export default ActivityCommentForm
