import { FC, useEffect } from 'react'
import { Prompt } from 'react-router-dom'

const FormPrompt: FC<any> = ({ hasUnsavedChanges }) => {
  useEffect(() => {
    const onBeforeUnload = (e: any) => {
      if (hasUnsavedChanges) {
        e.preventDefault()
        e.returnValue = ''
      }
    }
    window.addEventListener('beforeunload', onBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload)
    }
  }, [hasUnsavedChanges])

  const onLocationChange = () => {
    return 'You have unsaved changes, are you sure you want to leave?'
  }

  return <Prompt when={hasUnsavedChanges} message={onLocationChange} />
}

export default FormPrompt
