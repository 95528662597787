import { IIconProps } from '@wmgtech/legato'

const getIconForActivity = (activityCategory: string): IIconProps['name'] => {
  switch (activityCategory) {
    case 'NOI': return 'envelope'
    case 'Fieldwork': return 'briefcase'
    default: return 'pencil'
  }
}

export default getIconForActivity
