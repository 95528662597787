const convertDateToString = (date: string | null): string | null => {
  if (date) {
    const newDate = new Date(date)
    const dateString = new Date(newDate.setMinutes(-newDate.getTimezoneOffset()))
      .toISOString()
      .split('T')[0]
    return dateString
  }
  return null
}

export default convertDateToString
