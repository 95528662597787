import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'

import notify from 'utils/notify'
import { Notification } from 'constants/typeConstants'
import { AuditDocument, useCreateAuditMutation } from 'generated/graphql'
import AuditForm from 'features/Audit/AuditForm'
import convertDateToString from 'utils/convertDateToString'

interface IAuditForm {
  title: string
  action: string
}

const AddAudit: FC<IAuditForm> = ({ title, action }) => {
  const history = useHistory()
  const [createAudit] = useCreateAuditMutation()

  const createAuditFunc = (data: any) => {
    const dataInput = {
      ...data,
      name: data.reportName.trim(),
      reportName: data.reportName.trim(),
      startDate: convertDateToString(data.startDate),
      endDate: convertDateToString(data.endDate),
      noticeDate: convertDateToString(data.noticeDate),
      suitDate: convertDateToString(data.suitDate),
      subcategoryIds: data.subcategoryIds.map((sub: any) => sub.id),
    }

    createAudit({
      variables: { input: dataInput },
      update(cache, MutationResult) {
        cache.writeQuery({
          query: AuditDocument,
          variables: {
            id: MutationResult.data?.createAudit?.id,
          },
          data: {
            audit: {
              labels: [],
              ...MutationResult.data?.createAudit,
            },
          },
        })
      },
    })
      .then((response) => {
        history.push(`/audits/${response?.data?.createAudit?.id}`)
      })
      .catch(() => {
        notify(Notification.ERROR, 'There is some error while creating audit')
      })
  }

  const defaultValues = {
    sharepointUrl: '',
    sapArtistNumber: '',
    crsNumber: '',
    wbsProjectNumber: '',
  }
  return (
    <div>
      <header className="page-header">
        <h2>{title}</h2>
        <i>General Information</i>
      </header>
      <div className="page-container">
        <h3>Add New Audit</h3>
        <div className="divider"></div>
        <AuditForm
          action={action}
          defaultValues={defaultValues}
          onSubmit={createAuditFunc}
        />
      </div>
    </div>
  )
}

export default AddAudit
