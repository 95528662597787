import React, { FC } from 'react'
import { Badge } from '@wmgtech/legato'

import { GetAuditsQuery, useGetAuditsQuery } from 'generated/graphql'
import {
  AuditStatuses,
  CLOSED_AUDITS_STATUSES,
  Notification,
} from 'constants/typeConstants'
import useData from 'hooks/useData'
import notify from 'utils/notify'
import AuditCard from 'components/AuditCard'
import styles from './styles.module.scss'

type CardsProps = {
  categories?: (string | null)[]
  labelMasters?: (string | null)[]
}

const MyDashboardCards: FC<CardsProps> = ({ categories, labelMasters }) => {
  const { loading, previousData, data } = useGetAuditsQuery({
    variables: { input: { categories, labels: labelMasters } },
    fetchPolicy: 'cache-and-network',
    onError() {
      notify(
        Notification.ERROR,
        'There is some error while loading audits data'
      )
    },
  })
  const auditsData = useData<GetAuditsQuery>({ loading, previousData, data })

  const getCardsCount = (status: string | any[]) => {
    return Array.isArray(status)
      ? auditsData?.audits?.items?.filter((a) => status.includes(a?.status.id))
          .length ?? 0
      : auditsData?.audits?.items?.filter((a) => a?.status.id === status)
          .length ?? 0
  }

  const cards = [
    {
      title: 'Current',
      badge: <Badge colorType="success" text="Current" size="sm" />,
      count: getCardsCount(AuditStatuses.CURRENT),
      description: 'Current Audit Count',
    },
    {
      title: 'Open Period',
      badge: <Badge colorType="warning" text="Open Period" size="sm" />,
      count: getCardsCount(AuditStatuses.OPEN),
      description: 'Open Periods Audit Count',
    },
    {
      title: 'Settled/Closed',
      badge: (
        <Badge colorType="primary" text="Settled" size="sm">
          Settled
        </Badge>
      ),
      count: getCardsCount(CLOSED_AUDITS_STATUSES),
      description: 'Settled/Inactive Audit Count',
    },
    {
      title: 'Total Audits',
      badge: <Badge colorType="danger" text="Total Audits" size="sm" />,
      count: auditsData?.audits?.total ?? 0,
      description: 'Total Audit Count',
    },
  ]
  return (
    <div className={styles.container}>
      {cards.map((card) => (
        <div key={card.title}>
          <AuditCard
            title={card.title}
            badge={card.badge}
            count={card.count}
            description={card.description}
          />
        </div>
      ))}
    </div>
  )
}

export default MyDashboardCards
