import React, { FC } from 'react'
import { Icon } from '@wmgtech/legato'
import { ApolloError } from '@apollo/client/errors'

import BalanceSummaryItem from './BalanceSummaryItem'
import ErrorAlert from 'components/ErrorAlert'

type BalanceSummaryProps = {
    items: { [key: string]: string | null } | null
  error?: ApolloError
  fromDate: string
  toDate: string
}

const BalanceSummary: FC<BalanceSummaryProps> = ({ items, fromDate, toDate, error }) => {
  return (
    <>
      <h4>Consolidated Balance Summary</h4>
      <p />

      {error && <ErrorAlert>There is no consolidated balance summary</ErrorAlert>}

      {items && (
        <>
          <BalanceSummaryItem
            icon={<Icon prefix="fas" name="check" />}
            label="Opening Bal:"
            price={Number(items['openingBalance'])}
          />
          <BalanceSummaryItem
            icon={<Icon prefix="fas" name="dollar" />}
            label="P&L Impact:"
            price={Number(items['plImpact'])}
          />
          <BalanceSummaryItem
            icon={<Icon prefix="fas" name="money-bill-alt" />}
            label="Payments:"
            price={Number(items['payments'])}
          />
          <hr style={{ margin: '2px' }} />
          <BalanceSummaryItem
            icon={<Icon prefix="fas" name="university" />}
            label="Closing Bal:"
            price={Number(items['closingBalance'])}
          />
        </>
      )}

      <div>
        <small>
          ** this data is date range: {fromDate} to {toDate}
        </small>
      </div>
    </>
  )
}

export default BalanceSummary
