import { FC } from 'react'
import { useWatch } from 'react-hook-form'
import { IFormCondition } from '../types'
import { isArray } from 'lodash'

const FormCondition: FC<IFormCondition> = ({
  fieldName,
  children,
  condition,
}) => {
  const value = useWatch({ name: fieldName })
  if (isArray(condition)) {
    return condition.includes(value) ? children : null
  }
  return condition === value ? children : null
}

export default FormCondition
