import { FC } from 'react'

import Breadcrumbs from 'components/Breadcrumbs'

const ReservesHeader: FC<any> = ({ breadcrumbs, auditData }) => {
  return (
    <header className="flex page-header">
      <div className="col-md-6">
        <h2>Reserves</h2>
        <Breadcrumbs links={breadcrumbs} />
      </div>
    </header>
  )
}

export default ReservesHeader
