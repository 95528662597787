import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { ColumnsType } from '@wmgtech/legato/build/components/Table/types'
import { Button, Icon, Table } from '@wmgtech/legato'
import classNames from 'classnames'

import {
  Label,
  LabelsListDocument,
  LabelsListQuery,
  useDeleteLabelMutation,
  useLabelsListQuery,
} from 'generated/graphql'
import useData from 'hooks/useData'
import notify from 'utils/notify'
import { IBreadcrumbs } from 'interfaces/BreadCrumbsInterface'
import { Notification } from 'constants/typeConstants'
import SubmissionModal from 'components/SubmissionModal/SubmissionModal'
import Breadcrumbs from 'components/Breadcrumbs'
import LoadingButton from 'components/LoadingButton'

interface ITableItem {
  id: string
  name: string
  abbreviation: string
  activeAudits: number
  ratesLink: string
}

const Labels: FC = () => {
  const history = useHistory()
  const [labelId, setLabelId] = useState('')
  const [showSubmissionModal, setShowSubmissionModal] = useState(false)
  const [deleteLabel] = useDeleteLabelMutation()
  const { loading, previousData, data } = useLabelsListQuery()
  const labelListData = useData<LabelsListQuery>({
    loading,
    previousData,
    data,
  })
  const mounted = useRef(false)

  const toggleSubmissionModal = useCallback(() => {
    setShowSubmissionModal((show) => !show)
  }, [])

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  })

  const labels: Label[] = labelListData?.labels?.items as Label[]

  const removeAuditLabel = () => {
    deleteLabel({
      variables: { id: labelId },
      update(cache: any, MutationResult: any) {
        const newLabels = labels?.filter(
          (label) => label?.id !== MutationResult.data?.deleteLabel.id
        )
        cache.writeQuery({
          query: LabelsListDocument,
          data: {
            labels: {
              total: newLabels?.length,
              items: newLabels,
            },
          },
        })
      },
    })
      .then(() => {
        mounted.current && toggleSubmissionModal()
        notify(Notification.SUCCESS, 'You have deleted a label')
      })
      .catch(() => {
        notify(
          Notification.ERROR,
          'There is some error while deleting audit label'
        )
      })
  }

  const handleAddNewLabel = () => {
    history.push('/labels/new')
  }

  const tableData: ITableItem[] =
    labels
      ?.map((label: any) => ({
        id: label?.id || '',
        name: label?.name || '',
        abbreviation: label?.abbreviation || '',
        activeAudits: label?.auditsCount || 0,
        ratesLink: 'link here',
        key: label?.id || '',
      }))
      .sort((a, b) => a.name.localeCompare(b.name)) || []

  const breadcrumbs: IBreadcrumbs[] = [
    { name: 'Home', to: '/' },
    { name: 'Admin Controls', to: '/home/admin_controls' },
    { name: 'Labels' },
  ]

  const tableColumns: ColumnsType<any>[] = [
    {
      dataIndex: 'name',
      key: 'name',
      title: 'Label Name',
      width: 250,
    },
    {
      dataIndex: 'abbreviation',
      key: 'abbreviation',
      title: 'Label Abbrev',
    },
    {
      dataIndex: 'activeAudits',
      key: 'activeAudits',
      title: 'Active Audits',
    },
    {
      dataIndex: 'ratesLink',
      key: 'ratesLink',
      title: 'Current Default Rate',
      render: (value: string, row: ITableItem) => {
        return (
          <div>
            <Link to={`/labels/${row.id}/rates`}>Rate sheets</Link>
          </div>
        )
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: 100,
      render: (value: string, row: ITableItem) => {
        return (
          <div>
            <Link to={`/labels/${row.id}/edit`}>
              <Icon name="edit" prefix="fas" />
            </Link>
            <span className="separator">|</span>
            <Button
              onClick={() => {
                setLabelId(row.id)
                toggleSubmissionModal()
              }}
              containerStyle={'link'}
              colorType={'danger'}
              size="sm"
              className="button"
            >
              <Icon name="trash" prefix="fas" />
            </Button>
          </div>
        )
      },
    },
  ]

  return (
    <div>
      <header className="page-header">
        <h2>Labels</h2>
        <div className="controls-header-container">
          <Breadcrumbs links={breadcrumbs} />
          <div className="button">
            <LoadingButton onClick={handleAddNewLabel} icon="plus">
              Add New Label
            </LoadingButton>
          </div>
        </div>
      </header>
      <SubmissionModal
        name="label"
        show={showSubmissionModal}
        onClose={toggleSubmissionModal}
        onSubmit={removeAuditLabel}
      />
      {loading ? (
        <p>loading...</p>
      ) : (
        <div className={classNames('controls-container', 'no-pagination')}>
          <Table
            condensed={true}
            pagination={{
              pageSize: labels.length,
            }}
            columns={tableColumns}
            data={tableData}
            striped="none"
          />
        </div>
      )}
    </div>
  )
}

export default Labels
