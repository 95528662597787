import { FC, useCallback, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Button, Input, InputGroup, Modal } from '@wmgtech/legato'

import FormDatepicker from 'components/Form/FormDatepicker'
import FormFooter from 'components/Form/FormFooter/FormFooter'
import OfferLabelsTable from '../OfferLabelsTable'
import AdjustmentTable from './AdjustmentTable'
import styles from './styles.module.scss'

interface IProps {
  show: boolean
  onClose: () => void
  onSubmit: (data: any) => void
  offer: any
  auditId: any
}

const OfferSettlementModal: FC<IProps> = ({
  show,
  onClose,
  onSubmit,
  offer,
    auditId
}) => {
  const methods = useForm()
  const { register } = methods

  const [showAdjustment, setAdjustment] = useState(false)

  const toggleAdjustment = useCallback(() => {
    setAdjustment((show) => !show)
  }, [])

  return (
    <Modal
      className={styles.modal}
      isOpen={show}
      title={
        <div className={styles['modal-title']}>
          <h2>Settle this Offer</h2>
          <p>
            To settle this offer means the{' '}
            <b>SETTLEMENT CHECK HAS BEEN ISSUED.</b>
          </p>
        </div>
      }
      onClose={onClose}
      shouldCloseOnOverlayClick
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
          <div className={styles.body}>
            <p>
              Review the latest offer below - click "Settle" at the bottom to
              save this offer as the settled offer!
            </p>
            <div className={styles.table}>
              <OfferLabelsTable offer={offer} />
            </div>
            <div className="divider"></div>
            <div className="row">
              <div className="col-md-4">
                <FormDatepicker
                  placeholder="Select a Date"
                  name="settDate"
                  label="Settlement CHECK ISSUED Date"
                  initialDate={null}
                  rules={{ required: true }}
                  message="Settlement date can't be blank"
                />
              </div>
              <div className="col-md-8">
                <InputGroup label="Settlement Comment">
                  <Input
                    {...register('notes')}
                    name="notes"
                    placeholder="Please leave a comment regarding this settlement"
                  />
                </InputGroup>
              </div>
            </div>
            <div className="divider"></div>
            <div className="row">
              <div className="col-md-9">
                <div>
                  Creating this settlement will also make the appropriate
                  changes to the current reserve (it will auto-create the
                  adjustments due to the settlement). Please click the button to
                  review below and make sure everything looks ok as the reserve
                  adjustments will happen once you click "Settle Offer" at the
                  bottom of this form!
                </div>
              </div>
              <div className="col-md-3">
                {' '}
                <Button
                  containerStyle={'outline'}
                  colorType={'success'}
                  onClick={toggleAdjustment}
                >
                  Show Adjustment
                </Button>
              </div>
            </div>
            {showAdjustment && <AdjustmentTable offer={offer} auditId={auditId} />}
          </div>

          <FormFooter submit="Settle Offer" cancel="Close" className="modal" />
        </form>
      </FormProvider>
    </Modal>
  )
}

export default OfferSettlementModal
