import React, { FC } from 'react'
import { UserClaims } from '@okta/okta-auth-js/lib/types'
import { Button } from '@wmgtech/legato'

import LoadingButton from '../../../LoadingButton'
import styles from './styles.module.scss'

type HeaderProps = {
  onSidebarToggle: () => void
  userInfo: UserClaims | null
  onLogout: () => void
  onEditProfile: () => void
}

const Header: FC<HeaderProps> = ({
  onSidebarToggle,
  userInfo,
  onLogout,
  onEditProfile,
}) => {
  return (
    <nav className={styles.nav}>
      <Button
        className={styles.burger}
        onClick={onSidebarToggle}
        icon="bars"
      />

      {userInfo && (
        <div>
          <LoadingButton
            className={styles.button}
            colorType={'secondary-white'}
            onClick={onEditProfile}
            icon="user"
            prefix="fas"
          >
            Edit Profile
          </LoadingButton>{' '}
          <LoadingButton
            className={styles.button}
            colorType={'secondary-white'}
            onClick={onLogout}
            icon="sign-out-alt"
            prefix="fas"
          >
            Log Out
          </LoadingButton>
        </div>
      )}
    </nav>
  )
}

export default Header
