import React, { FC, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'

import {
  AuditDocument,
  useAuditQuery,
  useUpdateAuditMutation,
} from 'generated/graphql'
import AuditForm from 'features/Audit/AuditForm'
import Spinner from 'components/Spinner'
import notify from 'utils/notify'
import { Notification } from 'constants/typeConstants'
import convertDateToString from 'utils/convertDateToString'

interface IAuditForm {
  title: string
  action: string
}

const convertToDate = (date: string | null) => {
  const newDate = date ? new Date(date) : null
  return newDate
}

const AuditFormPage: FC<IAuditForm> = ({ title, action }) => {
  const [defaultValues, setDefaultValues] = useState({})
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const [updateAudit] = useUpdateAuditMutation()

  const { data, loading } = useAuditQuery({
    variables: { input: id! },
    onCompleted() {
      setDefaultValues({
        artistId: data?.audit?.artist?.id,
        auditorId: data?.audit?.auditor?.id,
        endDate: convertToDate(data?.audit?.endDate),
        noticeDate: convertToDate(data?.audit?.noticeDate),
        parentId: data?.audit?.parent?.id,
        name: data?.audit?.name,
        reportName: data?.audit?.reportName,
        startDate: convertToDate(data?.audit?.startDate),
        statusId: data?.audit?.status.id,
        suitDate: convertToDate(data?.audit?.suitDate),
        sharepointUrl: data?.audit?.sharepointUrl,
        sapArtistNumber: data?.audit?.sapArtistNumber,
        subcategoryIds: data?.audit?.types,
        crsNumber: data?.audit?.crsNumber,
        wbsProjectNumber: data?.audit?.wbsProjectNumber,
      })
    },
    onError() {
      notify(
        Notification.ERROR,
        'There is some error while loading the Audits data'
      )
    },
  })

  const updateAuditFunc = (data: any) => {
    const { subcategoryIds, ...rest } = data
    const dataInput = {
      ...rest,
      name: data.reportName.trim(),
      reportName: data.reportName.trim(),
      startDate: convertDateToString(data.startDate),
      endDate: convertDateToString(data.endDate),
      noticeDate: convertDateToString(data.noticeDate),
      suitDate: convertDateToString(data.suitDate),
    }

    updateAudit({
      variables: { input: { ...dataInput, id } },
      update(cache: any, MutationResult: any) {
        cache.writeQuery({
          query: AuditDocument,
          variables: {
            id,
          },
          data: {
            audit: {
              ...MutationResult.data?.updateAudit,
            },
          },
        })
      },
    })
      .then((response) =>
        history.push(`/audits/${response?.data?.updateAudit?.id}`)
      )
      .catch(() => {
        notify(Notification.ERROR, 'There is some error while updating audit')
      })
  }

  return (
    <div>
      <header className="page-header">
        <h2>{title}</h2>
        <i>General Information</i>
      </header>
      <div className="page-container">
        <h3>Edit Current Audit</h3>
        <div className="divider"></div>
        {loading || isEmpty(defaultValues) ? (
          <Spinner name="circle-notch" />
        ) : (
          <AuditForm
            action={action}
            defaultValues={defaultValues}
            onSubmit={updateAuditFunc}
          />
        )}
      </div>
    </div>
  )
}

export default AuditFormPage
