interface ISubcategory {
  id: string
  name: string
}

const getAuditSubcategories = (data: any) => {
  return data?.reduce(
    (acc: any, cur: any) => {
      return acc.concat(
        cur.subcategories.map((subcategory: ISubcategory) => {
          return {
            id: subcategory.id,
            key: subcategory.id + cur.id,
            title: subcategory.name,
            parentId: cur.id,
          }
        }),
        { id: cur.id, key: cur.id, title: cur.name, parentId: '0' }
      )
    },
    []
  )
}

export default getAuditSubcategories
