import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'

import {
  GetActivityTypesDocument,
  useCreateActivityTypeMutation,
} from 'generated/graphql'
import notify from 'utils/notify'
import { Notification } from 'constants/typeConstants'
import ActivityTypeForm from '../ActivityTypeForm'

const CreateActivityType: FC = () => {
  const history = useHistory()

  const [createActivityType, { loading }] = useCreateActivityTypeMutation()

  const createActivityTypeFunc = (data: any) => {
    createActivityType({
      variables: { input: data },
      refetchQueries: [{ query: GetActivityTypesDocument }],
    })
      .then(() => history.push('/activity-types'))
      .catch(() =>
        notify(
          Notification.ERROR,
          'There is some error while creating the activity type'
        )
      )
  }

  const defaultValues = {
    name: '',
    description: '',
    category: null,
    reportCategory: null,
    responsibility: '',
    auditStatus: '',
    isStatus: true,
    isKpi: true,
    order: 1,
    activityNumber: '',
    statusNumber: '',
  }

  return (
    <div>
      <header className="page-header">
        <h2>New Activity Type</h2>
      </header>
      <div className="controls-container">
        <h3>Add New Activity Type</h3>
        <hr className="divider" />
        <ActivityTypeForm
          action={'Create'}
          defaultValues={defaultValues}
          onSubmit={createActivityTypeFunc}
          loading={loading}
        />
      </div>
    </div>
  )
}

export default CreateActivityType
