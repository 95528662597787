import { FC, useMemo } from 'react'

import Table from 'components/Table'
import currencyFormat from 'utils/currencyFormat'
import { useLastAuditReservedAmountsQuery } from 'generated/graphql';


interface IProps {
  offer: any,
  auditId: any,
}

interface OfferAmount {
  flat: string;
  advanced: string;
  other: string;
  claimAllocation: string;
}

interface Label {
  id: string;
  name: string;
  abbreviation: string;
}

interface AuditType {
  id: string;
  subcategoryId: string;
  name: string;
}

interface AuditClaim {
  amount: string;
  auditType: AuditType;
}

interface Allocation {
  amount: OfferAmount;
  label: Label;
  auditClaim: AuditClaim;
}
interface ProcessedAllocation {
  name: string,
  type: string,
  reserve: string,
  adj: string,
  adjreserve: string
}

interface Reserve {
  id: string;
  amount: string;
  auditId: string;
  label: {
    id: string;
    name: string;
  };
  auditSubcategory: {
    id: string;
    name: string;
  };
}


const AdjustmentTable: FC<IProps> = ({ offer, auditId }) => {
  const columns = [
    {
      title: 'Label',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Current Reserve',
      dataIndex: 'reserve',
      key: 'reserve',
      render: (value: string, row: any) => currencyFormat(row.reserve),
    },
    {
      title: 'Adj',
      dataIndex: 'adj',
      key: 'adj',
      render: (value: string, row: any) => currencyFormat(row.adj),
    },
    {
      title: 'Adj reserve',
      dataIndex: 'adjreserve',
      key: 'adjreserve',
      render: (value: string, row: any) => currencyFormat(row.adjreserve),
    },
  ]

  const { data: reservesData, loading: reservesLoading } = useLastAuditReservedAmountsQuery({
    variables: { auditId },
    fetchPolicy: 'no-cache',
  });

  const processAllocations = (
      allocations: Allocation[],
      reserves: Reserve[]
  ): Record<string, Record<string, ProcessedAllocation>> => {
    const acc: Record<string, Record<string, ProcessedAllocation>> = {};

    // Process reserves first
    reserves.forEach(reserve => {
      const labelName = reserve.label.name;
      const subcategoryName = reserve.auditSubcategory.name;
      const key = `${labelName}_${subcategoryName}`;

      if (!acc[labelName]) {
        acc[labelName] = {};
      }

      if (!acc[labelName][key]) {
        acc[labelName][key] = {
          name: labelName,
          type: subcategoryName,
          reserve: '0.00',
          adj: '0.00',
          adjreserve: '0.00'
        };
      }

      acc[labelName][key].reserve = (parseFloat(acc[labelName][key].reserve) + parseFloat(reserve.amount)).toFixed(2);
    });

    // Process allocations
    allocations.forEach(allocation => {
      const labelName = allocation.label.name;
      const auditTypeName = allocation.auditClaim.auditType.name;
      const claimAmount = parseFloat(allocation.auditClaim.amount);

      // Find the matching reserve entry
      const matchingReserve = reserves.find(r =>
          r.label.name === labelName &&
          auditTypeName === r.auditSubcategory.name
      );

      if (matchingReserve) {
        const subcategoryName = matchingReserve.auditSubcategory.name;
        const key = `${labelName}_${subcategoryName}`;

        if (!acc[labelName]) {
          acc[labelName] = {};
        }

        if (!acc[labelName][key]) {
          acc[labelName][key] = {
            name: labelName,
            type: subcategoryName,
            reserve: '0.00',
            adj: '0.00',
            adjreserve: '0.00'
          };
        }

        // Update adj (sum of flat, advanced, and other)

        acc[labelName][key].adj = (parseFloat(acc[labelName][key].adj) + claimAmount).toFixed(2);

        // Update adjreserve (difference between reserve and adj)
        const reserveAmount = parseFloat(acc[labelName][key].reserve);
        const adjAmountTotal = parseFloat(acc[labelName][key].adj);
        acc[labelName][key].adjreserve = (reserveAmount - adjAmountTotal).toFixed(2);
      }
    });

    return acc;
  }



  const claims = useMemo(() => {
    if (!reservesLoading && reservesData?.lastAuditReservedAmounts) {
      // Filter out null or undefined values and cast to Reserve[]
      const validReserves: Reserve[] = reservesData.lastAuditReservedAmounts.filter((reserve): reserve is Reserve =>
          reserve !== null && reserve !== undefined
      );

      return Object.values(processAllocations(offer.allocations, validReserves));
    }
    return [];
  }, [reservesLoading, reservesData, offer.allocations]);

  

  const labels = Object.values(claims).flatMap(innerObj =>
      Object.values(innerObj));
  return <Table columns={columns} data={labels || []} />
}

export default AdjustmentTable
