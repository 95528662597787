import React, { FC } from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'
import { Icon, IIconProps } from '@wmgtech/legato'

import styles from './styles.module.scss'

type MenuItemProps = {
  icon: IIconProps['name']
  to: NavLinkProps['to']
  showText: boolean
  label: string
}

const MenuItem: FC<MenuItemProps> = ({ icon, label, showText, to }) => {
  return (
    <NavLink className={styles.link} activeClassName={styles.active} to={to}>
      <Icon prefix="fas" name={icon} className={styles.icon} />
      {showText ? label : null}
    </NavLink>
  )
}

export default MenuItem
