import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, ColumnsType, Icon } from '@wmgtech/legato'

import convertDateToString from 'utils/convertDateToString'
import currencyFormat from 'utils/currencyFormat'
import notify from 'utils/notify'
import { Notification, OfferStatuses } from 'constants/typeConstants'
import {
  AuditDocument,
  useAuditClaimsTableQuery,
  useAuditOffersQuery,
  useChangeOfferStatusMutation,
  useCreateAuditOfferMutation,
  useDeleteAuditOfferMutation,
  useUpdateAuditOfferMutation,
  useCancelSettlementMutation
} from 'generated/graphql'
import OfferModal from './OfferModal'
import SubmissionModal from 'components/SubmissionModal'
import Table from 'components/Table'
import OfferStatusForm from './OfferStatusForm'
import StatusChangeModal from './StatusChangeModal'
import OfferSettlementModal from './OfferSettlementModal'
import OfferDetailsModal from './OfferDetailsModal/OfferDetailsModal'
import styles from './styles.module.scss'

const Offers: FC<any> = ({ auditData }) => {
  const mounted = useRef(false)
  const history = useHistory()

  interface IAllocation {
    amount: {
      flat: string
      advanced: string
      other: string
      claimAllocation: string
    }
  }
  interface ITableRow {
    id: string
    offerDate: string
    status: {
      id: string
      name: string
    }
    allocations: IAllocation[]
  }

  interface ICreateOfferInput {
    offerDate: string
    notes: string
    flatAmount: string
    advancedAmount: string
    otherAmount: string
  }

  interface IChangeAuditOfferStatusInput {
    id: string
    statusId: string
  }

  const tableColumns: ColumnsType<ITableRow>[] = [
    {
      title: 'Offer Date',
      dataIndex: 'offerDate',
      key: 'offerDate',
    },
    {
      title: 'Flat Amt',
      dataIndex: 'flat',
      key: 'flat',
      render: (value: string, row: ITableRow) =>
        currencyFormat(calculateAmt(row, 'flat')),
    },
    {
      title: 'Advance Amt',
      dataIndex: 'advanced',
      key: 'advanced',
      render: (value: string, row: ITableRow) =>
        currencyFormat(calculateAmt(row, 'advanced')),
    },
    {
      title: 'Other Amt',
      dataIndex: 'other',
      key: 'other',
      render: (value: string, row: ITableRow) =>
        currencyFormat(calculateAmt(row, 'other')),
    },
    {
      title: 'Total Offer',
      dataIndex: 'total',
      key: 'total',
      render: (value: string, row: ITableRow) =>
        currencyFormat(calculateTotalAmt(row)),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (value: string, row: ITableRow) => {
        return (
          <>
            {row.status.id === OfferStatuses.SETTLED ? (
              <Button
                label="Settlement Saved!"
                onClick={() => {
                  setAuditOffer(row)
                  toggleDetailsModal()
                }}
                size="sm"
              />
            ) : (
              <OfferStatusForm
                onChangeStatus={onChangeStatus}
                defaultValues={{ statusId: row.status.id, id: row.id }}
                onClick={() => {
                  setAuditOffer(row)
                  setAuditOfferId(row.id)
                }}
              />
            )}
          </>
        )
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (value: string, row: ITableRow) => {
        return (
          <>
            {row.status.id !== OfferStatuses.SETTLED ? (
              <>
                <Link to={`/audits/${auditData.id}/offers/${row.id}`}>
                  <Icon name="edit" prefix="fas" />
                </Link>
                <span className={styles.separator}>|</span>
                <Button
                  onClick={() => {
                    setAuditOfferId(row.id)
                    toggleSubmissionModal()
                  }}
                  containerStyle={'link'}
                  colorType={'danger'}
                  size="sm"
                  className={styles.button}
                >
                  <Icon name="trash" prefix="fas" />
                </Button>{' '}
              </>
            ) : (
              <i>
                <small>Locked</small>
              </i>
            )}
          </>
        )
      },
    },
  ]

  const { data: claimsData } = useAuditClaimsTableQuery({
    variables: { auditId: auditData.id },
  })

  const claims = claimsData?.auditClaimsTable.items.length

  const calculateAmt = (row: ITableRow, type: string) => {
    return row.allocations.reduce(
      (acc: number, cur: any) => acc + +cur.amount[type],
      0
    )
  }

  const calculateTotalAmt = (row: ITableRow) => {
    return (
      calculateAmt(row, 'flat') +
      calculateAmt(row, 'advanced') +
      calculateAmt(row, 'other')
    )
  }

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  })

  const { data: offersData, refetch } = useAuditOffersQuery({
    variables: { auditId: auditData.id },
  })

  const [showModal, setShowModal] = useState(false)
  const [showStatusModal, setShowStatusModal] = useState(false)
  const [showSubmissionModal, setShowSubmissionModal] = useState(false)
  const [showSettlementModal, setSettlementModal] = useState(false)
  const [showDetailsModal, setDetailsModal] = useState(false)
  const [auditOfferId, setAuditOfferId] = useState('')
  const [auditOffer, setAuditOffer] = useState<any>({})
  const [statusPayload, setStatusPayload] =
    useState<IChangeAuditOfferStatusInput>()
  const [createOffer] = useCreateAuditOfferMutation()
  const [updateOffer] = useUpdateAuditOfferMutation()
  const [removeAuditOffer] = useDeleteAuditOfferMutation()
  const [changeStatus] = useChangeOfferStatusMutation()
  const [cancelSettlement] = useCancelSettlementMutation()

  const toggleModal = useCallback(() => {
    setShowModal((show) => !show)
  }, [])

  const toggleSubmissionModal = useCallback(() => {
    setShowSubmissionModal((show) => !show)
  }, [])

  const toggleSettlementModal = useCallback(() => {
    setSettlementModal((show) => !show)
  }, [])

  const toggleDetailsModal = useCallback(() => {
    setDetailsModal((show) => !show)
  }, [])

  const onChangeStatus = (statusId: string, offerId: string) => {
    if (statusId === OfferStatuses.SETTLED) {
      setSettlementModal((show) => !show)
    } else toggleStatusModal({ id: offerId, statusId })
  }

  const toggleStatusModal = useCallback(
    (data?) => {
      if (!showStatusModal) {
        setStatusPayload(data)
      }
      setShowStatusModal((show) => !show)
    },
    [showStatusModal]
  )

  const createOfferFunc = (data: ICreateOfferInput) => {
    const input = {
      auditId: auditData.id,
      offerDate: convertDateToString(data.offerDate),
      notes: data.notes,
      flatAmount: data.flatAmount,
      advancedAmount: data.advancedAmount,
      otherAmount: data.otherAmount
    }

    createOffer({
      variables: { input },
      refetchQueries: [
        {
          query: AuditDocument,
          variables: { input: auditData.id },
        },
      ],
      onCompleted(response) {
        refetch()
        mounted.current && toggleModal()
        history.push(
          `/audits/${auditData.id}/offers/${response.createAuditOffer.id}`
        )
        notify(Notification.SUCCESS, 'Your offer was created!')
      },
      onError() {
        notify(Notification.ERROR, 'There is some error while creating offer')
      },
    })
  }

  const removeOffer = () => {
    removeAuditOffer({
      variables: { input: { id: auditOfferId } },
      onCompleted() {
        refetch()
        mounted.current && toggleSubmissionModal()
        notify(Notification.SUCCESS, 'Your offer was deleted!')
      },
      onError() {
        notify(Notification.ERROR, 'There is some error while deleting offer')
      },
    })
  }

  const settleOffer = (data: { notes: string; settDate: string }) => {
    const allocations = []

    for (let alloc of auditOffer.allocations) {
        const allocation = {
          flatAmount: alloc.amount.flat,
          advancedAmount: alloc.amount.advanced,
          otherAmount: alloc.amount.other,
          notes: alloc.notes || '',
          labelId: alloc.label.id,
          auditClaimId: alloc.auditClaim.id, 
      }
      allocations.push(allocation)
    }

    const input = {
      id: auditOffer.id,
      offerDate: convertDateToString(auditOffer.offerDate),
      notes: auditOffer.notes,
      allocations,
      settDate: convertDateToString(data.settDate),
      settNote: data.notes,
      statusId: OfferStatuses.SETTLED,
      flatAmount: auditOffer.flatAmount,
      advancedAmount: auditOffer.advancedAmount,
      otherAmount: auditOffer.otherAmount,
    }

    updateOffer({
      variables: { input },
      onCompleted() {
        refetch()
        mounted.current && toggleSettlementModal()
        notify(Notification.SUCCESS, 'Your offer was settled!')
      },
      onError() {
        notify(Notification.ERROR, 'There is some error while settling offer')
      },
    })
  }

  const deleteOffer = () => {
    console.log('Offer was deleted')
    cancelSettlement({
      variables: { input: { auditId: auditData.id, offerId: auditOffer.id } },
      onCompleted() {
        refetch()
        toggleDetailsModal()
        notify(Notification.SUCCESS, 'Settlement was canceled!')
      },
      onError() {
        notify(Notification.ERROR, 'There is some error while canceling settlement')
      },
    })
    //TODO find out if logic should be different than for removal
  }

  const changeOfferStatus = () => {
    changeStatus({
      variables: { input: statusPayload! },
      onCompleted() {
        refetch()
        mounted.current && toggleStatusModal()
        notify(Notification.SUCCESS, 'Status was changed!')
      },
      onError() {
        notify(Notification.ERROR, 'There is some error while changing status')
      },
    })
  }



  const types = auditData?.types.map((type: any) => type.subcategoryId)
  const isUnion = types.includes('1' || '8')

  return (
    <div className={styles.container}>
      <StatusChangeModal
        show={showStatusModal}
        onClose={toggleStatusModal}
        onSubmit={changeOfferStatus}
        statusPayload={statusPayload}
      />
      <OfferModal
        onSubmit={createOfferFunc}
        onClose={toggleModal}
        show={showModal}
      />
      <SubmissionModal
        name="offer"
        show={showSubmissionModal}
        onClose={toggleSubmissionModal}
        onSubmit={removeOffer}
      ></SubmissionModal>
      <OfferSettlementModal
        onClose={toggleSettlementModal}
        show={showSettlementModal}
        onSubmit={settleOffer}
        offer={auditOffer}
        auditId={auditData.id}
      />
      <OfferDetailsModal
        show={showDetailsModal}
        onClose={toggleDetailsModal}
        onSubmit={deleteOffer}
        offer={auditOffer}
      />
      <header className={styles.header}>
        <div>
          <h4>All Offers</h4>
          <p>
            Below are all the current label offers you have added to this audit
          </p>
        </div>
        <div className={styles['button-wrapper']}>
          {!claims && !isUnion && (
            <Button
              disabled
              secondaryIcon="exclamation"
              label="Add Claims to Audit"
            />
          )}
          {(!!claims || isUnion) && (
            <Button
              onClick={toggleModal}
              secondaryIcon="plus"
              label="Add Offer"
            />
          )}
        </div>
      </header>
      <Table columns={tableColumns} data={offersData?.auditOffers ?? []} />
    </div>
  )
}

export default Offers
