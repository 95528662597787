import { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { Icon } from '@wmgtech/legato'

import {
  MutationCreateAuditArgs,
  MutationUpdateAuditArgs,
} from 'generated/graphql'
import FormFooter from 'components/Form/FormFooter'
import FormInputValidated from 'components/Form/FormInputValidated'

interface IArtistForm {
  defaultValues: any
  action: string
  onSubmit: (data: MutationCreateAuditArgs | MutationUpdateAuditArgs) => void
  loading: boolean
}

const ArtistForm: FC<IArtistForm> = ({
  action,
  defaultValues,
  onSubmit,
  loading,
}) => {
  const history = useHistory()
  const methods = useForm({
    defaultValues: defaultValues,
  })

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
        <div className="form-group form-group-three">
          <FormInputValidated
            defaultValue={defaultValues?.name}
            name="name"
            label="Artist Name"
            placeholder="Name must be unique"
            message="Artist Name can't be blank"
            rules={{ required: true }}
          ></FormInputValidated>
        </div>
        <footer>
          <FormFooter
            cancel={
              <>
                <Icon name="less-than" /> Back
              </>
            }
            onClick={() => history.push('/artists')}
            submit={action}
            loading={loading}
          />
        </footer>
      </form>
    </FormProvider>
  )
}

export default ArtistForm
