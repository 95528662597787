import React, { FC, Suspense } from 'react'
import { Accordion } from '@wmgtech/legato'

import Spinner from 'components/Spinner'
import styles from './styles.module.scss'

type AuditAccordionProps = {
  title: string
  defaultShowTable?: boolean
  id: number
  expand?: boolean
}

const AuditAccordion: FC<AuditAccordionProps> = ({ children, title, id, expand = false }) => {
  return (
    <Accordion className={styles.accordion}
      list={[
        {
          title: `${title}`,
          content: (
            <Suspense fallback={<Spinner name="circle-notch"/>}>{children}</Suspense>
          ),
          id: `${id}`,
          expand: expand,
        },
      ]} />
  )
}

export default AuditAccordion
