import { FC } from 'react'
import { Input, InputGroup } from '@wmgtech/legato'
import { useController } from 'react-hook-form'
import { TValidationType } from '@wmgtech/legato/build/components/FormControls/shared/hooks'

interface IProps {
  label: string
  name: string
  placeholder?: string
  message?: string
  defaultValue?: string
  rules: Object
  type?: string
}

const FormInputValidated: FC<IProps> = ({ label, name, ...props }) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name: name,
    rules: props.rules,
  })
  const { onChange, value } = field

  const checkForErrors = (): TValidationType | undefined => {
    if (error) {
      return 'error'
    }
  }

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!(event.target as HTMLInputElement).value && event.code === 'Space') {
      event.preventDefault()
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value)
  }

  return (
    <InputGroup
      label={label}
      validationIconPlacement="input"
      validation={{
        required: props.rules.hasOwnProperty('required'),
        message: props.message,
        type: checkForErrors(),
      }}
    >
      <Input
        value={value || ''}
        placeholder={props.placeholder}
        onChange={handleChange}
        inputRef={field.ref}
        onKeyDown={onKeyDown}
        type={props.type}
      />
    </InputGroup>
  )
}

export default FormInputValidated
