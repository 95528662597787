import { FC, useState } from 'react'
import {
  INestedSelectProps,
  InputGroup,
  NestedSelect,
  Tag,
} from '@wmgtech/legato'

import styles from './styles.module.scss'
import { useController } from 'react-hook-form'

interface IListItem {
  id: string
  title: string
  key: string
  labelSelect?: string
}

const getParentID = (item: any) => {
  return item.parentId
}

const getKeyFromItem = (item: IListItem) => {
  return item.key
}

const getTitleFromItem = (item: IListItem) => {
  return item.title
}

type IProps = Omit<INestedSelectProps<IListItem>, 'getParentId'> & {
  selectLabel: string
}

const NestedSelectWithTag: FC<IProps> = ({
  getTitle = getTitleFromItem,
  getKey = getKeyFromItem,
  name,
  list,
  selectLabel,
  placeholder,
  ...props
}) => {
  const [value, setValue] = useState<ReadonlyArray<IListItem>>(
    props.value ? props.value : []
  )

  const {
    field,
    fieldState: { error },
  } = useController({
    name: name,
    rules: { required: true },
    defaultValue: props.value,
  })

  const { onChange } = field

  const checkForErrors = () => {
    if (error) {
      return 'error'
    }
  }

  return (
    <>
      <InputGroup
        label={selectLabel}
        validationIconPlacement="input"
        validation={{ ...props.validation, type: checkForErrors() }}
      >
        <NestedSelect
          name={name}
          list={list}
          placeholder={placeholder}
          getTitle={getTitleFromItem}
          getKey={getKeyFromItem}
          getParentId={getParentID}
          validation={props.validation}
          {...props}
          value={value}
          onSelect={(v) => {
            onChange(v)
            setValue(v)
          }}
        />
        {value.map((e: any) => (
          <Tag
            className={styles.tag}
            key={getKey(e)}
            text={getTitle(e)}
            onClickIcon={() => {
              setValue((s: any) =>
                s.filter((f: any) => getKey(f) !== getKey(e))
              )
            }}
          />
        ))}
      </InputGroup>
    </>
  )
}

export default NestedSelectWithTag
