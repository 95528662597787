import { FC, useCallback, useState } from 'react'
import { Button, Icon } from '@wmgtech/legato'
import { Link, useParams } from 'react-router-dom'

import {
  AuditDocument,
  AuditQuery,
  useAddAuditLabelMutation,
  useAuditQuery,
  useRemoveAuditLabelMutation,
} from 'generated/graphql'
import Table from 'components/Table'
import AddAuditLabelModal from './AddAuditLabelModal'
import notify from 'utils/notify'
import { Notification } from 'constants/typeConstants'
import useData from 'hooks/useData'
import currencyFormat from 'utils/currencyFormat'
import {
  ILabel,
  IOverviewItem,
  getOverviewValues,
} from 'utils/getOverviewValues'
import SubmissionModal from 'components/SubmissionModal'
import styles from './styles.module.scss'

interface ITableItem {
  id: string
  labelId?: string
  name: string
  earnings: number
  reserve: number
  claims: number
  offers: number
}

interface IOverview {
  overview: {
    auditOverview: {
      subcategories: IOverviewItem[]
    }
  }
}

const AuditLabels: FC<IOverview> = ({ overview }) => {
  const { id } = useParams<{ id?: string }>()

  const columns = [
    {
      title: 'Label Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Artist Earnings',
      dataIndex: 'earnings',
      key: 'earnings',
      render: (value: string, row: ITableItem) => currencyFormat(row.earnings),
    },
    {
      title: 'Current Reserve',
      dataIndex: 'reserve',
      key: 'reserve',
      render: (value: string, row: ITableItem) => currencyFormat(row.reserve),
    },
    {
      title: 'Current Claims',
      dataIndex: 'claims',
      key: 'claims',
      render: (value: string, row: ITableItem) => currencyFormat(row.claims),
    },
    {
      title: 'Current Offers',
      dataIndex: 'offers',
      key: 'offers',
      render: (value: string, row: ITableItem) => currencyFormat(row.offers),
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: 'editable',
      render: (value: string, row: ITableItem, index: number) => {
        if (extendedLabels.length - 1 === index) return
        return (
          <div>
            <Link to={`/audits/${id}/labels/${row.labelId}/edit`}>
              <Icon name="edit" prefix="fas" />
            </Link>
            <span className="separator">|</span>
            <Button
              onClick={() => {
                setAuditLabel(row.id)
                toggleSubmissionModal()
              }}
              containerStyle={'link'}
              colorType={'danger'}
              size="sm"
              className={styles.button}
            >
              <Icon name="trash" prefix="fas" />
            </Button>
          </div>
        )
      },
    },
  ]

  const [showChangePeriodModal, setShowChangePeriodModal] = useState(false)
  const [showSubmissionModal, setShowSubmissionModal] = useState(false)
  const [addLabel] = useAddAuditLabelMutation()
  const [removeLabel] = useRemoveAuditLabelMutation()
  const [auditLabelId, setAuditLabel] = useState('')

  const overviewItems = overview?.auditOverview?.subcategories
  const labelsOverview = getOverviewValues(overviewItems)

  const toggleCreationModal = useCallback(() => {
    setShowChangePeriodModal((show) => !show)
  }, [])

  const toggleSubmissionModal = useCallback(() => {
    setShowSubmissionModal((show) => !show)
  }, [])

  const removeAuditLabel = () => {
    removeLabel({
      variables: { id: auditLabelId },
      refetchQueries: [
        {
          query: AuditDocument,
          variables: { input: id },
        },
      ],
    })
      .then(() => {
        toggleSubmissionModal()
        notify(Notification.SUCCESS, 'You have deleted a label')
      })
      .catch(() => {
        notify(
          Notification.ERROR,
          'There is some error while deleting audit label'
        )
      })
  }

  const AddNewLabel = (data: any) => {
    addLabel({
      variables: { input: { ...data, auditId: id } },
      refetchQueries: [
        {
          query: AuditDocument,
          variables: { input: id },
        },
      ],
    })
      .then(() => {
        toggleCreationModal()
        notify(Notification.SUCCESS, 'Your label was added!')
      })
      .catch(() => {
        notify(
          Notification.ERROR,
          'There is some error while creating audit label'
        )
      })
  }

  const getAuditLabelsData = useAuditQuery({ variables: { input: id! } })

  const auditLabels = useData<AuditQuery>({
    loading: getAuditLabelsData.loading,
    previousData: getAuditLabelsData.previousData,
    data: getAuditLabelsData.data,
  })

  const labels = [...(auditLabels?.audit?.labels as any[])]

  const extendedLabels: ITableItem[] = []
  for (let label of labels) {
    const newLabel = { ...label }
    const overviewLabel = labelsOverview.find(
      (l: ILabel) => label.labelId === l.id
    )
    newLabel.claims = overviewLabel?.claims.amount || 0
    newLabel.reserve = overviewLabel?.reserved.amount || 0
    newLabel.offers = overviewLabel?.offer
      ? Object.values(overviewLabel?.offer?.amount)
          .splice(2, 2)
          .reduce((a: any, c: any) => +a + +c)
          ?.toString()
      : 0
    extendedLabels.push(newLabel)
  }
  const getTotal = (field: string) => {
    return (
      extendedLabels
        ?.map((l: any) => l?.[field])
        .reduce((acc: number, cur: any) => {
          return acc + parseFloat(cur)
        }, 0) || 0
    )
  }

  extendedLabels?.push({
    name: 'Totals:',
    id: 'totals',
    earnings: getTotal('earnings'),
    reserve: getTotal('reserve'),
    claims: getTotal('claims'),
    offers: getTotal('offers'),
  })

  return (
    <>
      <SubmissionModal
        name="label"
        show={showSubmissionModal}
        onClose={toggleSubmissionModal}
        onSubmit={removeAuditLabel}
      ></SubmissionModal>
      <AddAuditLabelModal
        show={showChangePeriodModal}
        onClose={toggleCreationModal}
        onSubmit={AddNewLabel}
        auditId={id}
      ></AddAuditLabelModal>
      <div className={styles.container}>
        <header className={styles.header}>
          <div>
            <h4>Quick View of Labels</h4>
            <p>
              Below are all the labels you have added to this audit - the artist
              earnings are for the audit period.
            </p>
          </div>
          <div className={styles['button-wrapper']}>
            <Button
              onClick={toggleCreationModal}
              secondaryIcon="plus"
              label="Add Label"
            />
          </div>
        </header>
        <div>
          <Table
            responsive
            fixedHeader
            columns={columns}
            data={extendedLabels || []}
            totals
          />
        </div>
      </div>
    </>
  )
}

export default AuditLabels
