import React, { FC } from 'react'

import logo from 'assets/images/Audit_Logo.png'
import styles from './styles.module.scss'

const CenteredLogo: FC = () => {
  return (
      <div className={styles.container}>
        <img width="300px" src={logo} alt="Audit TraX" />
      </div>
  )
}

export default CenteredLogo
