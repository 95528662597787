import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, Icon, Table } from '@wmgtech/legato'
import { ColumnsType } from '@wmgtech/legato/build/components/Table/types'

import {
  AuditorsListDocument,
  AuditorsListQuery,
  useAuditorsListQuery,
  useDeleteAuditorMutation,
} from 'generated/graphql'
import useData from 'hooks/useData'
import SubmissionModal from 'components/SubmissionModal/SubmissionModal'
import notify from 'utils/notify'
import { Notification } from 'constants/typeConstants'
import Breadcrumbs from 'components/Breadcrumbs'
import { IBreadcrumbs } from 'interfaces/BreadCrumbsInterface'
import LoadingButton from 'components/LoadingButton'

interface ITableItem {
  id: string
  name: string
  activeAudits: number
}

const Auditors: FC = () => {
  const history = useHistory()
  const [auditorId, setAuditorId] = useState('')
  const [showSubmissionModal, setShowSubmissionModal] = useState(false)
  const [deleteAuditor] = useDeleteAuditorMutation()
  const { loading, previousData, data } = useAuditorsListQuery()
  const auditorListData = useData<AuditorsListQuery>({
    loading,
    previousData,
    data,
  })
  const mounted = useRef(false)

  const toggleSubmissionModal = useCallback(() => {
    setShowSubmissionModal((show) => !show)
  }, [])

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  })

  const auditors = auditorListData?.auditors?.items

  const removeAuditor = () => {
    deleteAuditor({
      variables: { id: auditorId },
      update(cache: any, MutationResult: any) {
        const newAuditors = auditors?.filter(
          (auditor) => auditor?.id !== MutationResult.data?.deleteAuditor.id
        )
        cache.writeQuery({
          query: AuditorsListDocument,
          data: {
            auditors: {
              total: newAuditors?.length,
              items: newAuditors,
            },
          },
        })
      },
    })
      .then(() => {
        mounted.current && toggleSubmissionModal()
        notify(Notification.SUCCESS, 'You have deleted an auditor')
      })
      .catch(() => {
        notify(Notification.ERROR, 'There is some error while deleting auditor')
      })
  }

  const tableColumns: ColumnsType<any>[] = [
    {
      dataIndex: 'name',
      key: 'name',
      title: 'Auditor Name',
    },
    {
      dataIndex: 'activeAudits',
      key: 'activeAudits',
      title: 'Active Audits',
      width: 150,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: 100,
      render: (value: string, row: ITableItem) => {
        return (
          <div>
            <Link to={`/auditors/${row.id}/edit`}>
              <Icon name="edit" prefix="fas" />
            </Link>
            <span className="separator">|</span>
            <Button
              onClick={() => {
                setAuditorId(row.id)
                toggleSubmissionModal()
              }}
              containerStyle={'link'}
              colorType={'danger'}
              size="sm"
              className="button"
            >
              <Icon name="trash" prefix="fas" />
            </Button>
          </div>
        )
      },
    },
  ]

  const tableData: ITableItem[] =
    auditors
      ?.map((auditor: any) => ({
        id: auditor?.id || '',
        name: auditor?.name || '',
        activeAudits: auditor?.auditsCount || 0,
        key: auditor?.id || '',
      }))
      .sort((a, b) => a.name.localeCompare(b.name)) || []

  const breadcrumbs: IBreadcrumbs[] = [
    { name: 'Home', to: '/' },
    { name: 'Admin Controls', to: '/home/admin_controls' },
    { name: 'Auditors' },
  ]

  const handleAddNewAuditor = () => {
    history.push('/auditors/new')
  }

  return (
    <div>
      <header className="page-header">
        <h2>Auditors</h2>
        <div className="controls-header-container">
          <Breadcrumbs links={breadcrumbs} />
          <div className="button">
            <LoadingButton onClick={handleAddNewAuditor} icon="plus">
              Add New Auditor
            </LoadingButton>
          </div>
        </div>
      </header>
      <SubmissionModal
        name="auditor"
        show={showSubmissionModal}
        onClose={toggleSubmissionModal}
        onSubmit={removeAuditor}
      />
      {loading ? (
        <p>loading...</p>
      ) : (
        <div className="controls-container">
          <Table
            condensed={true}
            pagination={{
              position: ['bottomCenter'],
            }}
            columns={tableColumns}
            data={tableData}
            striped="none"
          />
        </div>
      )}
    </div>
  )
}

export default Auditors
