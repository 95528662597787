import { FC } from 'react'
import classNames from 'classnames'

import LoadingButton from 'components/LoadingButton'
import styles from './styles.module.scss'

type IFormFooter = {
  cancel: string | JSX.Element
  submit: string
  loading?: boolean
  className?: string
  onClick?: () => void
}

const FormFooter: FC<IFormFooter> = ({
  cancel,
  submit,
  loading,
  className,
  onClick,
}) => {
  return (
    <div
      className={classNames(
        styles.container,
        className ? styles[className] : ''
      )}
    >
      {cancel && (
        <LoadingButton
          containerStyle={'outline'}
          colorType={'secondary-black'}
          loading={loading}
          onClick={onClick}
        >
          {cancel}
        </LoadingButton>
      )}
      <LoadingButton
        type="submit"
        containerStyle={'outline'}
        colorType={'primary'}
        loading={loading}
      >
        {submit}
      </LoadingButton>
    </div>
  )
}

export default FormFooter
