import { FC, Suspense, useCallback, useState } from 'react'
import { Accordion } from '@wmgtech/legato'
import { isEmpty } from 'lodash'

import { IClaim } from 'interfaces/ClaimsInterfaces'
import Spinner from 'components/Spinner'
import RevisionForm from '../../RevisionForm'
import ClaimModal from '../ClaimModal'
import AllocationsForm from '../../AllocationsForm/AllocationsForm'
import EditClaimModal from '../EditClaimModal/EditClaimModal'
import {
  AuditClaimAllocationSaveInput,
  AuditClaimSaveInput,
  AuditClaimSaveRevisionInput,
  AuditClaimsRevision,
  AuditDocument,
  useSaveAuditClaimsRevisionMutation,
} from 'generated/graphql'
import {
  calculateAllocations,
  calculateUnionAllocations,
} from 'utils/claimsCalculations'
import { getTotalReserveAmountByAuditType } from 'utils/reserveCalculations'
import notify from 'utils/notify'
import { Notification, UNION_AUDITS } from 'constants/typeConstants'
import SubmissionModal from 'components/SubmissionModal'
import FormPrompt from 'components/Form/FormPrompt/FormPrompt'
import styles from './styles.module.scss'

const convertToDate = (date: string | null) => {
  const newDate = date ? new Date(date) : null
  return newDate
}

const Revision: FC<any> = ({
  revisionId,
  revisions,
  updateRevision,
  reserves,
  auditData,
}) => {
  const activeRevision = revisions.find(
    (revision: AuditClaimsRevision) => revision.id === revisionId
  )

  const labels = auditData?.audit?.labels

  const types = auditData?.audit?.types.map((type: any) => type.subcategoryId)
  const isUnion = UNION_AUDITS.some((union) => types?.includes(union))

  // TODO remove updatedReserves when audit type is fixed
  const updatedReserves = []
  for (let i of reserves?.lastAuditReservedAmounts) {
    const subCategory = auditData?.audit?.types.find(
      (type: any) => type.subcategoryId === i.auditSubcategory.id
    )

    const newO = { ...i, auditSubcategory: subCategory }
    updatedReserves.push(newO)
  }

  const reservesTotal = isUnion
    ? null
    : getTotalReserveAmountByAuditType(updatedReserves)

  const activeRevisionClaims =
    [...activeRevision?.claims].sort((a, b) =>
      a.number?.localeCompare(b.number, undefined, {
        numeric: true,
        sensitivity: 'base',
      })
    ) ?? []

  const [claims, setClaims] = useState<IClaim[]>(activeRevisionClaims)
  const [unsavedChanges, setChanges] = useState(false)
  const [showSubmissionModal, setShowSubmissionModal] = useState(false)
  const toggleSubmissionModal = useCallback(() => {
    setShowSubmissionModal((show) => !show)
  }, [])

  const initialTotalAllocation = (claims: IClaim[]) => {
    let total = 0
    if (claims?.length) {
      for (let claim of claims) {
        for (let allocations of claim.allocations) {
          total += +allocations.amount
        }
      }
    }
    return total
  }

  const onChangeAllocations = (data: number) => {
    setChanges(true)
    setInitialTotalAllocations(data)
  }

  const [initialTotalAllocationAmt, setInitialTotalAllocations] = useState(
    initialTotalAllocation(claims)
  )

  const defaultValues = {
    revisionDate: convertToDate(activeRevision.revisionDate),
    notes: activeRevision.notes,
    id: activeRevision.id,
  }

  const [showClaimModal, setShowClaimModal] = useState(false)
  const toggleClaimModal = useCallback(() => {
    setShowClaimModal((show) => !show)
  }, [])

  const [showEditClaimModal, setShowEditClaimModal] = useState(false)
  const toggleEditClaimModal = useCallback(() => {
    setShowEditClaimModal((show) => !show)
  }, [])

  const setClaimForUpdate = (index: number) => {
    setActiveClaim(claims[index])
    toggleEditClaimModal()
  }

  const setClaimForDeletion = (index: number) => {
    setActiveClaim(claims[index])
    toggleSubmissionModal()
  }

  const [activeClaim, setActiveClaim] = useState<IClaim | null>(null)
  const [saveAuditRevision] = useSaveAuditClaimsRevisionMutation()

  const [claimsTotalAmount, setTotalClaimsAmount] = useState<number>(
    activeRevision.claims
      ?.map((d: IClaim) => d.amount)
      .reduce((acc: string, cur: string) => +acc + +cur, 0)
  )

  const calculateTotalClaim = (data: IClaim[]) => {
    const amount = data
      .map((d) => d.amount)
      .reduce((acc, cur) => +acc + +cur, 0)
    setTotalClaimsAmount(amount)
  }

  const changeClaims = (claims: IClaim[]) => {
    claims.sort((a, b) =>
      a.number?.localeCompare(b.number, undefined, {
        numeric: true,
        sensitivity: 'base',
      })
    )
    setClaims(claims)
    calculateTotalClaim(claims)
    const newAllocations = initialTotalAllocation(claims)
    setInitialTotalAllocations(newAllocations)
    setChanges(true)
  }

  const createClaim = (data: IClaim) => {
    data.allocations = isUnion
      ? calculateUnionAllocations(labels)
      : calculateAllocations(labels, reservesTotal, data)
    const newClaims = claims.concat(data)
    toggleClaimModal()
    changeClaims(newClaims)
  }

  const updateClaim = (claim: IClaim) => {
    if (activeClaim?.id) claim.id = activeClaim.id
    else {
      claim.key = activeClaim?.key
    }

    claim.allocations = isUnion
      ? calculateUnionAllocations(labels, activeClaim?.allocations)
      : calculateAllocations(labels, reservesTotal, claim)

    const newClaims = [
      ...(claims as IClaim[]).filter((c) => {
        return activeClaim?.id
          ? c.id !== activeClaim.id
          : c.key !== activeClaim?.key
      }),
      claim,
    ]
    toggleEditClaimModal()
    changeClaims(newClaims)
  }

  const deleteClaim = () => {
    const newClaims = [
      ...(claims as IClaim[]).filter((c) => {
        return activeClaim?.id
          ? c.id !== activeClaim.id
          : c.key !== activeClaim?.key
      }),
    ]
    toggleSubmissionModal()
    changeClaims(newClaims)
    if (!newClaims.length) {
      saveRevision([])
    }
  }

  const onChangeAllocation = (claim: IClaim) => {
    const newClaims = claims.filter((cl: IClaim) => {
      return claim.id ? claim.id !== cl.id : claim.key !== cl.key
    })

    newClaims.push(claim)
    changeClaims(newClaims)
  }

  const saveRevision = (allocationsData: any) => {
    let claimsPayload: AuditClaimSaveInput[] = []

    if (!isEmpty(allocationsData)) {
      for (let i = 0; i < claims.length; i++) {
        const allocations: AuditClaimAllocationSaveInput[] = []
        for (let alloc of allocationsData.claims[i].allocations) {
          const { amount, label, notes } = alloc
          allocations.push({
            amount: amount.toString(),
            notes,
            labelId: label?.id || labels[0].labelId,
          })
        }
        const {
          action,
          type,
          subcategory,
          key,
          auditType,
          __typename,
          id,
          ...rest
        } = claims[i]
        const claimPayload = {
          ...rest,
          auditTypeId: claims[i].auditType?.id ?? claims[i].auditTypeId, //TODO replace id with subcategoryId when audit type is fixed
          allocations,
          actionId: action?.key || action?.id || undefined,
          typeId: type?.key || type?.id || undefined,
          subcategoryId: subcategory?.key || subcategory?.id || undefined,
          id: claims[i].id || '',
        }
        claimsPayload.push(claimPayload)
      }
    }

    const payload: AuditClaimSaveRevisionInput = {
      revisionId: activeRevision.id,
      claims: claimsPayload,
    }

    saveAuditRevision({
      variables: {
        input: payload,
      },
      refetchQueries: [
        {
          query: AuditDocument,
          variables: { input: auditData?.audit?.id },
        },
      ],
      onCompleted() {
        setChanges(false)
        notify(Notification.SUCCESS, 'You have saved revision')
      },
      onError() {
        notify(Notification.ERROR, 'There is some error while saving revision')
      },
    })
  }

  return (
    <>
      <FormPrompt hasUnsavedChanges={unsavedChanges} />
      <ClaimModal
        onSubmit={createClaim}
        onClose={toggleClaimModal}
        show={showClaimModal}
        claims={claims || []}
      />
      <EditClaimModal
        onSubmit={updateClaim}
        onClose={toggleEditClaimModal}
        show={showEditClaimModal}
        claims={claims || []}
        claim={activeClaim}
      />
      <SubmissionModal
        name="claim"
        show={showSubmissionModal}
        onClose={toggleSubmissionModal}
        onSubmit={deleteClaim}
      />
      <Accordion
        className={styles.accordion}
        list={[
          {
            title: 'Total Revision Details',
            content: (
              <Suspense fallback={<Spinner name="circle-notch" />}>
                <RevisionForm
                  onSubmit={updateRevision}
                  defaultValues={defaultValues}
                  revisionId={revisionId}
                />
              </Suspense>
            ),
            id: 0,
            expand: false,
          },
        ]}
      />
      <div className="divider"></div>

      <AllocationsForm
        reserves={reservesTotal?.length ? reservesTotal : null}
        data={claims}
        onSubmit={saveRevision}
        onCreateBtnClick={toggleClaimModal}
        onEditClaimClick={setClaimForUpdate}
        onDeleteClaimClick={setClaimForDeletion}
        claimsTotalAmount={claimsTotalAmount}
        allocationsTotalAmt={initialTotalAllocationAmt}
        onChangeAllocations={onChangeAllocations}
        onChangeAllocation={onChangeAllocation}
      ></AllocationsForm>
    </>
  )
}

export default Revision
