import { filter, findIndex, uniq } from 'lodash'

const makeActiveLabels = (arr: (string | null)[], id: string) => {
  const index = findIndex(arr, (labelId: string | null) => labelId === id)

  if (index === -1) {
    return uniq([...arr, id])
  } else {
    return filter(arr, (labelId: string | null) => labelId !== id)
  }
}

export default makeActiveLabels
