import { FC } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import AuditLabelForm from '../AuditLabelForm'
import {
  AuditDocument,
  useAuditQuery,
  useUpdateAuditLabelMutation,
} from 'generated/graphql'
import notify from 'utils/notify'
import { Notification } from 'constants/typeConstants'
import HeaderRowInfo from 'components/HeaderRowInfo'
import Breadcrumbs from 'components/Breadcrumbs'
import { IBreadcrumbs } from 'interfaces/BreadCrumbsInterface'

const UpdateAuditLabel: FC = () => {
  const history = useHistory()
  const { auditId, labelId } = useParams<{
    auditId: string
    labelId: string
  }>()

  const { data } = useAuditQuery({
    variables: { input: auditId! },
  })

  const breadcrumbs: IBreadcrumbs[] = [
    { name: 'Home', to: '/' },
    { name: 'Audits', to: '/audits' },
    { name: 'Audit', to: `/audits/${auditId}` },
    { name: 'Edit Label' },
  ]

  const [updateLabel] = useUpdateAuditLabelMutation()

  const updateAuditLabel = (data: any) => {
    updateLabel({
      variables: { input: { ...data } },
      refetchQueries: [
        {
          query: AuditDocument,
          variables: { input: auditId },
        },
      ],
      onCompleted() {
        history.push(`/audits/${auditId}`)
        notify(Notification.SUCCESS, 'You have updated the label.')
      },
      onError() {
        notify(
          Notification.ERROR,
          'There is some error while updating audit label'
        )
      },
    })
  }

  return (
    <div>
      <header className="flex page-header">
        <div className="col-md-6">
          <h2>Audit Labels</h2>
          <Breadcrumbs links={breadcrumbs} />
        </div>
        <HeaderRowInfo auditData={data} />
      </header>
      <div className="page-container">
        <h3>Edit Label on {data?.audit?.reportName}</h3>
        <hr className="divider" />
        <AuditLabelForm
          onSubmit={updateAuditLabel}
          auditId={auditId}
          labelId={labelId}
        />
      </div>
    </div>
  )
}

export default UpdateAuditLabel
