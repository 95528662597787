import { FC, ReactNode, useState } from 'react'
import { Tabs } from '@wmgtech/legato'

import Revision from '../Revision'

interface ITab {
  id: string
  title: string | any
  element?: ReactNode
}

const RevisionTabs: FC<any> = ({
  revisions,
  onChangeTab,
  onUpdateRevision,
  auditData,
  reserves,
}) => {
  const tabList = revisions.map((tab: any) => {
    return {
      id: tab.id,
      title: tab.revisionDate,
      element: (
        <Revision
          key={tab.id}
          revisionId={tab.id}
          revisions={revisions}
          updateRevision={onUpdateRevision}
          reserves={reserves}
          auditData={auditData}
        />
      ),
    }
  })

  const [tabContent, setTabContent] = useState(tabList[0]?.element)

  const TabSelect = (item: ITab) => {
    setTabContent(item.element)
    onChangeTab(item.id)
  }

  const renderTab = () => <div>{tabContent}</div>

  return (
    <>
      <Tabs
        list={tabList}
        mode="in-page"
        initialTabId={tabList[0]?.id}
        onClick={TabSelect}
      />
      {renderTab()}
    </>
  )
}

export default RevisionTabs
