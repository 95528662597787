import { FC } from 'react'
import { Modal } from '@wmgtech/legato'

import styles from './styles.module.scss'
import AddRateForm from '../AddRateForm/AddRateForm'

interface IAddLabelProps {
  show: boolean
  onClose: () => void
  onSubmit: (data: any) => void
}

const AddNewRateModal: FC<IAddLabelProps> = ({ show, onClose, onSubmit }) => {
  return (
    <Modal
      className={styles.modal}
      isOpen={show}
      title={
        <div className={styles['modal-title']}>
          <h2>Add New Default Rate</h2>
        </div>
      }
      onClose={onClose}
      shouldCloseOnOverlayClick
    >
      <AddRateForm onClose={onClose} onSubmit={onSubmit}></AddRateForm>
    </Modal>
  )
}

export default AddNewRateModal
