import { FC } from 'react'
import Select from 'react-select'
import { useController } from 'react-hook-form'

interface IProps {
  name: string
  options: Array<any>
  placeholder?: string
  value?: string | null
  defaultValue?: string
  onChange?: any
}

const FormNestedList: FC<IProps> = ({ children, name, options, ...props }) => {
  const { field } = useController({ name: name })
  const { onChange, value } = field

  const handleChahge = (data: any) => {
    props.onChange?.(data.value)
    onChange(data.value)
  }

  const findDefaultValue = () => {
    for (let opt of options) {
      for (let innerOpt of opt.options) {
        if (innerOpt.value === value) return innerOpt
      }
    }
  }

  return (
    <Select
      onChange={handleChahge}
      options={options}
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary25: '#f4f4f4',
          primary: 'lightgrey',
        },
      })}
      defaultValue={findDefaultValue}
      placeholder={props.placeholder}
    />
  )
}

export default FormNestedList
