import { FC } from 'react'
import { Modal } from '@wmgtech/legato'

import styles from './styles.module.scss'
import AuditLabelForm from '../AuditLabelForm'

interface IAddLabelProps {
  show: boolean
  auditId: string | undefined | null
  onClose: () => void
  onSubmit: (data: any) => void
}

const AddAuditLabelModal: FC<IAddLabelProps> = ({
  show,
  auditId,
  onClose,
  onSubmit,
}) => {
  return (
    <Modal
      className={styles.modal}
      isOpen={show}
      title={
        <div className={styles['modal-title']}>
          <h2>Add New Label</h2>
        </div>
      }
      onClose={onClose}
      shouldCloseOnOverlayClick
    >
      <AuditLabelForm onClose={onClose} onSubmit={onSubmit} auditId={auditId} />
    </Modal>
  )
}

export default AddAuditLabelModal
