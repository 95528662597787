const downloadCsv = (link: string, name: string): void => {
  const blob = new Blob([link], { type: 'text/csv' })
  const element = window.document.createElement('a')
  element.href = window.URL.createObjectURL(blob)
  element.download = name
  element.setAttribute('style', 'display:none')
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}

export default downloadCsv
