import React, { FC } from 'react'
import { Button, Icon, IIconProps, IButtonProps } from '@wmgtech/legato'

import styles from './styles.module.scss'

type LoadingButtonProps = Omit<IButtonProps, 'size'> & {
  loading?: boolean
  icon?: IIconProps['name']
  size?: IButtonProps['size'] | 'xs'
  prefix?: IIconProps['prefix']
  type?: string
}

const LoadingButton: FC<LoadingButtonProps> = ({ children, loading, style = {}, size, icon, disabled, prefix, ...props }) => {
  let newStyles = {
    ...style
  }

  if (size === 'xs') {
    newStyles = {
      ...newStyles,
      fontSize: '0.7rem',
      padding: '0.2rem 0.4rem'
    }
  }

  return (
    <Button
      {...props}
      style={newStyles}
      size={size && ['sm', 'lg'].includes(size) ? (size as IButtonProps['size']) : undefined}
      disabled={loading || disabled}
    >
      {!loading && icon && <span className={styles.icon}>
        <Icon name={icon} prefix={prefix} />
        </span>}
      {loading ? null : children}
    </Button>
  )
}

export default LoadingButton
