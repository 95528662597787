import { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import FormSelect from 'components/Form/FormSelect'
import { OfferStatusesQuery, useOfferStatusesQuery } from 'generated/graphql'
import useData from 'hooks/useData'
import convertToList from 'utils/convertToList'
import styles from './styles.module.scss'

interface IProps {
  defaultValues: {
    id: string
    statusId: string
  }
  onChangeStatus: (statusId: string, offerId: string) => void
  onClick: () => void
}

const OfferStatusForm: FC<IProps> = ({
  onChangeStatus,
  defaultValues,
  onClick,
}) => {
  const { data, loading, previousData } = useOfferStatusesQuery()
  const statusesList = useData<OfferStatusesQuery>({
    loading,
    previousData,
    data,
  })
  const statuses = convertToList(statusesList?.offerStatuses)

  const methods = useForm({ defaultValues: defaultValues })

  return (
    <FormProvider {...methods}>
      <form onClick={onClick} autoComplete="off" className={styles.container}>
        <FormSelect
          name="statusId"
          list={statuses || []}
          onChange={(e: string) => onChangeStatus(e, defaultValues.id)}
        />
      </form>
    </FormProvider>
  )
}

export default OfferStatusForm
