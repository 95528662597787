import { FC } from 'react'
import { isEmpty } from 'lodash'
import { useHistory } from 'react-router-dom'

import ActivityRow from 'components/ActivityRow/ActivityRow'

const ActivitiesTimeline: FC<any> = ({
  activities,
  auditId,
  setSelectedActivity,
  toggleCommentCreationModal,
  toggleEditModal,
  toggleSubmissionModal,
}) => {
  const history = useHistory()

  const lastActivity = [...activities]
    .filter((a) => a.actualDate)
    .sort((a, b) => (b?.actualDate).localeCompare(a?.actualDate))[0]

  const activitiesSorted = [...activities].sort((a, b) =>
    (b.actualDate ?? b.manuallyProjectedDate).localeCompare(
      a.actualDate ?? a.manuallyProjectedDate
    )
  )

  const openCommentModal = (activity: any) => {
    toggleCommentCreationModal()
    setSelectedActivity(activity)
  }

  const goToActivityCommentsPage = (activity: any) => {
    history.push(
      `/audits/${auditId}/activities/${activity.id}/activity_comments`
    )
  }
  const getSelectedActivity = (activity: any) => {
    setSelectedActivity(activity)
    toggleEditModal()
  }

  const handleDelete = (activity: any) => {
    toggleSubmissionModal()
    setSelectedActivity(activity)
  }

  return (
    <>
      {isEmpty(activities) ? (
        <p>No Activities Listed</p>
      ) : (
        activitiesSorted.map((activity: any) => (
          <div key={activity?.id}>
            <ActivityRow
              activity={activity}
              last={lastActivity?.id === activity?.id}
              updateActivity={() => getSelectedActivity(activity)}
              deleteActivity={() => handleDelete(activity)}
              goToCommentsPage={() => goToActivityCommentsPage(activity)}
              createNewComment={() => openCommentModal(activity)}
              expanded={true}
            ></ActivityRow>
          </div>
        ))
      )}
    </>
  )
}

export default ActivitiesTimeline
