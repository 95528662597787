import React, { FC, useState } from 'react'
import { Doughnut } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Legend, Tooltip } from 'chart.js'
import { isEmpty } from 'lodash'

import { GetAuditsQuery, useGetAuditsQuery } from 'generated/graphql'
import useData from 'hooks/useData'
import notify from 'utils/notify'
import {
  AuditStatuses,
  Categories,
  Notification,
} from 'constants/typeConstants'

ChartJS.register(ArcElement, Tooltip, Legend)

type CountByAuditProps = {
  labelMasters: (string | null)[]
  categories: (string | null)[]
  auditCategories: any[]
}

const CountByAudit: FC<CountByAuditProps> = ({
  labelMasters,
  categories,
  auditCategories,
}) => {
  const [labels, setLabels] = useState(auditCategories.map((a) => a.name))
  const [chartData, setChartData] = useState<any>()
  const statuses = [AuditStatuses.CURRENT]

  const { data, previousData, loading } = useGetAuditsQuery({
    variables: { input: { statuses, categories, labels: labelMasters } },
    onCompleted() {
      refreshChart()
    },
    onError() {
      notify(
        Notification.ERROR,
        'There is some error while loading count by audit type data'
      )
    },
  })
  const newData = useData<GetAuditsQuery>({ loading, previousData, data })

  const refreshChart = () => {
    if (categories.includes(Categories.UNION_AUDIT)) {
      const newLabels = [
        auditCategories.find((a) => a.id === Categories.UNION_AUDIT).name,
      ]
      setLabels(newLabels)
      setChartData(getChartData(newLabels))
    } else if (
      !isEmpty(categories) &&
      !categories.includes(Categories.UNION_AUDIT)
    ) {
      const newLabels = auditCategories
        .filter((a) => a.id !== Categories.UNION_AUDIT)
        .map((a: any) => a.name)
      setLabels(newLabels)
      setChartData(getChartData(newLabels))
    } else {
      const newLabels = auditCategories.map((a) => a.name)
      setLabels(newLabels)
      setChartData(getChartData(newLabels))
    }
  }

  const getChartData = (newLabels: string[]) => {
    const chartData = Array(newLabels.length).fill(0)
    for (let i = 0; i < newLabels.length; i++) {
      for (let item of newData?.audits?.items ?? []) {
        if (item?.types?.find((t) => t?.category?.name === newLabels[i])) {
          chartData[i]++
        }
      }
    }
    return chartData
  }

  return (
    <div className="card">
      <div className="card-header">Count by Audit Type (Current only)</div>
      <div className="card-body">
        {!newData?.audits?.total && <div>There is no data for the chart</div>}
        {!!newData?.audits?.total && (
          <Doughnut
            height={140}
            data={{
              labels,
              datasets: [
                {
                  data: chartData,
                  backgroundColor: [
                    '#D7FDEC',
                    '#3B413C',
                    '#9DB5B2',
                    '#DAF0EE',
                    '#94D1BE',
                  ],
                  borderColor: [
                    '#D7FDEC',
                    '#3B413C',
                    '#9DB5B2',
                    '#DAF0EE',
                    '#94D1BE',
                  ],
                },
              ],
            }}
            options={{
              responsive: true,
              aspectRatio: 2,
            }}
          />
        )}
      </div>
    </div>
  )
}

export default CountByAudit
