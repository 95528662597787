import { FC } from 'react'
import { Select } from '@wmgtech/legato'
import { useController } from 'react-hook-form'

export interface IListItem {
  title: string
  key: string
}

interface IProps {
  name: string
  list: Array<IListItem>
  placeholder?: string
  isFilterable?: boolean
  value?: string | null
  disabled?: boolean
  defaultValue?: string
  onChange?: any
  isobject?: string
}

const FormSelect: FC<IProps> = ({ children, name, list, ...props }) => {
  const { field } = useController({ name: name })
  const { onChange, value } = field

  const handleChahge = (data: IListItem) => {
    let listData = props.isobject ? data : data.key
    props.onChange?.(listData)
    onChange(listData)
  }

  const handleFilter = (data: IListItem[], term: string) => {
    return data.filter((d: IListItem) => {
      return d.title.toLowerCase().includes(term.toLowerCase())
    })
  }

  return (
    <Select
      onFilter={handleFilter}
      onSelect={handleChahge}
      name={name}
      value={value}
      list={list}
      {...props}
    />
  )
}

export default FormSelect
