import React, { FC } from 'react'

import MyDashboardCards from 'features/MyDashboardCards'
import CenteredLogo from 'components/CenteredLogo'

const MyDashboardPage: FC = () => {
  return (
    <div className="page-container-transparent">
      <CenteredLogo />
      <br />
      <br />
      <MyDashboardCards />
    </div>
  )
}

export default MyDashboardPage
