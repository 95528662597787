import { growl } from '@wmgtech/legato'
import { Notification } from '../constants/typeConstants'

const notify = (kind: Notification, msg: string) => {
  return growl[kind](
    { message: msg }, { autoClose: 5000 }
  )
}

export default notify
