import { FC } from 'react'
import { NavLink } from 'react-router-dom'

interface ILink {
  name: string
  to?: string
}

interface IBreadcrumbs {
  links: ILink[]
}

const Breadcrumbs: FC<IBreadcrumbs> = ({ links }) => {
  const lastLink = links[links.length - 1]
  links = links.slice(0, -1)

  return (
    <ul className="breadcrumbs">
      {links.map((link: ILink) => {
        return (
          <li key={link.name}>
            <NavLink className="breadcrumbs-item" to={link.to!}>
              {link.name}
            </NavLink>
          </li>
        )
      })}
      <li>
        <strong>{lastLink.name}</strong>
      </li>
    </ul>
  )
}

export default Breadcrumbs
