import React, { FC } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { Modal, Icon, InputGroup } from '@wmgtech/legato'

import PeriodFormData from '../../types/PeriodFormData'
import FormSelect from 'components/Form/FormSelect/FormSelect'
import LoadingButton from 'components/LoadingButton'
import FormCondition from 'components/Form/FormCondition'
import styles from './styles.module.scss'

const qtrs = [
  { title: 'Q1', key: '1' },
  { title: 'Q2', key: '2' },
  { title: 'Q3', key: '3' },
  { title: 'Q4', key: '4' },
]

const mthSels = [
  { key: '1', title: 'Fis Mth 1 (Oct)' },
  { key: '2', title: 'Fis Mth 2 (Nov)' },
  { key: '3', title: 'Fis Mth 3 (Dec)' },
  { key: '4', title: 'Fis Mth 4 (Jan)' },
  { key: '5', title: 'Fis Mth 5 (Feb)' },
  { key: '6', title: 'Fis Mth 6 (Mar)' },
  { key: '7', title: 'Fis Mth 7 (Apr)' },
  { key: '8', title: 'Fis Mth 8 (May)' },
  { key: '9', title: 'Fis Mth 9 (Jun)' },
  { key: '10', title: 'Fis Mth 10 (Jul)' },
  { key: '11', title: 'Fis Mth 11 (Aug)' },
  { key: '12', title: 'Fis Mth 12 (Sep)' },
]

type ChangeDatePeriodModalProps = {
  show: boolean
  onClose: () => void
  onSubmit: (data: PeriodFormData) => void
  defaultValues: PeriodFormData
  years: string[]
}

const ChangeDatePeriodModal: FC<ChangeDatePeriodModalProps> = ({
  show,
  onClose,
  years,
  onSubmit,
  defaultValues,
}) => {
  const yearsList = years.map((year) => {
    return { title: year, key: year }
  })

  const methods = useForm({
    defaultValues: {
      perType: defaultValues.perType || 'qtr',
      sel: defaultValues.sel || qtrs[0].key,
      yrSel: defaultValues.yrSel || years[0],
    },
  })

  return (
    <Modal
      className={styles.modal}
      isOpen={show}
      title={
        <div className={styles['modal-title']}>
          <Icon className={styles['modal-title-icon']} name="calendar-alt" />
          <h1>Change Date Period</h1>
        </div>
      }
      onClose={onClose}
      shouldCloseOnOverlayClick
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className={styles['modal-body']}>
            <InputGroup label="Period: ">
              <FormSelect
                name="perType"
                list={[
                  { title: 'Quarter', key: 'qtr' },
                  { title: 'Single Mth', key: 'mth' },
                ]}
              />
            </InputGroup>
            <FormCondition fieldName="perType" condition="qtr">
              <InputGroup label="Qtr: ">
                <FormSelect list={qtrs} name="sel"></FormSelect>
              </InputGroup>
            </FormCondition>
            <FormCondition fieldName="perType" condition="mth">
              <InputGroup label="Fis Mth: ">
                <FormSelect list={mthSels} name="sel" />
              </InputGroup>
            </FormCondition>
            <InputGroup label="Fiskal Year: ">
              <FormSelect list={yearsList} name="yrSel" />
            </InputGroup>
          </div>

          <div className={styles['modal-footer']}>
            <LoadingButton
              containerStyle={'outline'}
              colorType={'secondary-black'}
              onClick={onClose}
            >
              Close
            </LoadingButton>
            <LoadingButton
              type="submit"
              containerStyle={'outline'}
              colorType={'primary'}
            >
              Submit
            </LoadingButton>
          </div>
        </form>
      </FormProvider>
    </Modal>
  )
}

export default ChangeDatePeriodModal
